// @ts-check

import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Icon, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { Badge, Tooltip } from '@mui/material';
import * as actions from 'ROOT/apps/manager/src/services/ui/actions';

import { screenSize } from 'Lib/utilities/screenSize';

import { DRAWER_CONTENT_TYPES } from '../../SideDrawer';
import { NotificationsContext } from '../context';
import { useBadgeCount } from '../data/useNotifications';
import { withChildrenWrapperStyles } from '../StyledComponents';

export default function MenuButton(props) {
    const { state, dispatch } = useContext(NotificationsContext);
    const badgeCount = useBadgeCount();
    const isDrawerOpen = useSelector(state => state.ui.isDrawerOpen);

    const reduxDispatch = useDispatch();

    const { mobile: isMobileScreenSize, tablet: isTabletScreenSize } = screenSize();
    const isMobile = isTabletScreenSize || isMobileScreenSize;

    useEffect(() => {
        if (state.view === 'side_drawer') {
            reduxDispatch(actions.toggleDrawer(DRAWER_CONTENT_TYPES.NOTIFICATIONS));
            reduxDispatch(actions.setDrawerOpen(true));
        }
        if (state.view !== 'side_drawer' && isDrawerOpen) {
            reduxDispatch(actions.setDrawerOpen(false));
        }
    }, [state.view]);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {props.children ? (
                !isMobile ? (
                    props.children({
                        onClick: e => {
                            dispatch({ type: 'SET_ANCHOR_EL', payload: e.currentTarget });
                            dispatch({ type: 'SET_VIEW', payload: 'popover' });
                        }
                    })
                ) : (
                    props.children({
                        onClick: () => {
                            dispatch({ type: 'SET_VIEW', payload: 'modal' });
                        }
                    })
                )
            ) : (
                <Box sx={withChildrenWrapperStyles}>
                    <Tooltip arrow title={<Typography sx={theme => ({ fontSize: theme.typography.pxToRem(14), color: 'common.white' })}>Notifications</Typography>} placement="top">
                        <ListItemButton
                            onClick={e => {
                                dispatch({ type: 'SET_VIEW', payload: 'popover' });
                                dispatch({ type: 'SET_ANCHOR_EL', payload: e.currentTarget });
                            }}
                        >
                            <Badge color="info" badgeContent={badgeCount}>
                                <ListItemIcon variant="xlarge">
                                    <Icon>notifications</Icon>
                                </ListItemIcon>
                            </Badge>
                        </ListItemButton>
                    </Tooltip>
                </Box>
            )}
        </>
    );
}
