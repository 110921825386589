// @ts-check
import  { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardActions, CardContent, ClickAwayListener, Divider, Icon, Popper } from '@mui/material';
import * as actions from 'ROOT/apps/manager/src/services/ui/actions';

import isEmpty from 'Lib/utilities/isEmpty';

import { DRAWER_CONTENT_TYPES } from '../../SideDrawer';
import NotificationsTitle from '../components/PopperTitle';
import { NotificationsContext } from '../context';

export default function NotificationPopperContainer({ children }) {
    const { state, dispatch: contextDispatch } = useContext(NotificationsContext);
    const dispatch = useDispatch();

    return (
        <Popper open={state.view === 'popover'} anchorEl={state.anchorEl} placement="right-start" sx={{ zIndex: 9999, paddingLeft: 1, width: '434px' }}>
            <ClickAwayListener
                onClickAway={() => {
                    contextDispatch({ type: 'CLOSE_VIEW' });
                }}
            >
                <Card
                    sx={{
                        borderRadius: 4,
                        bgcolor: 'background.paper',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                    }}
                >
                    <NotificationsTitle />
                    <CardContent sx={{ p: 4 }}>{children}</CardContent>
                    <Divider sx={{ mt: 2 }} />
                    <CardActions sx={{ p: 4, justifyContent: 'flex-end' }}>
                        {!isEmpty(state.selectedNotification) ? (
                            <Button
                                startIcon={<Icon>push_pin</Icon>}
                                onClick={() => {
                                    dispatch(actions.pinDrawer(DRAWER_CONTENT_TYPES.NOTIFICATIONS));
                                    contextDispatch({ type: 'PIN_NOTIFICATION', payload: state.selectedNotification });
                                    contextDispatch({ type: 'SET_VIEW', payload: 'pinned' });
                                }}
                                size="small"
                                variant="primary"
                            >
                                Pin Notification
                            </Button>
                        ) : (
                            <Button
                                startIcon={<Icon>visibility</Icon>}
                                onClick={() => contextDispatch({ type: 'SET_VIEW', payload: 'side_drawer' })}
                                size="small"
                                variant="primary"
                            >
                                View All Notifications
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </ClickAwayListener>
        </Popper>
    );
}
