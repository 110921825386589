import toPath from 'lodash.topath';

export const getIn = (obj, key, defaultValue, index = 0) => {
    const path = toPath(key);

    while (obj && index < path.length) {
        obj = obj[path[index++]];
    }

    return obj === undefined ? defaultValue : obj;
};

export default getIn;
