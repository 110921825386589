import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    ClickAwayListener,
    Fade,
    Icon,
    iconClasses,
    ListItemButton,
    listItemButtonClasses,
    ListItemIcon,
    Paper,
    Popper,
    styled,
    Tooltip,
    Typography,
    typographyClasses,
    useMediaQuery
} from '@mui/material';
import { Box } from '@mui/system';

import PortalTypes from 'Lib/constants/app/portalTypes';
import { KEYCODES, MODIFIERS } from 'Lib/constants/keyboard';
import useKeyboardShortcut from 'Lib/hooks/useKeyboardShortcut';
import Dialog from 'Lib/muiComponents/dialog/Dialog';
import { getRecentlyVisited } from 'Lib/utilities/recentlyVisited';

import ShortcutTooltip from '../ShortcutTooltip';

import { HistoryItem, HistoryItemContent, HistoryItemTitle } from './HistoryItems';

export const RecentlyVisited = ({ anchorEl, open, placement = 'right-end', toggle }) => {
    const { account, user } = useSelector(state => state.app);
    const renderDialog = useMediaQuery('(max-width: 475px');

    const historyKey = `${PortalTypes.Manager}_${account.accountID}_${user.userID}`;
    const recentlyViewed = getRecentlyVisited(historyKey);

    const Content = () => (
        <Box>
            <PopperTitle>
                <Icon>schedule</Icon>
                <Typography>Recently Viewed</Typography>
            </PopperTitle>
            <PopperSubtitle>Last {recentlyViewed.length} recently viewed</PopperSubtitle>
            {recentlyViewed.map((item, index) => (
                <HistoryItem key={index}>
                    <HistoryItemTitle historyTypeID={item.historyTypeID} />
                    <HistoryContentWrapper>
                        <HistoryItemContent path={item.path} onClick={toggle}>
                            {item.label}
                        </HistoryItemContent>
                        <HistoryItemContent sx={item.contentSx} path={item.path} onClick={toggle}>
                            {item.content}
                        </HistoryItemContent>
                    </HistoryContentWrapper>
                </HistoryItem>
            ))}
        </Box>
    );

    return renderDialog ? (
        <Dialog open={open} toggle={toggle}>
            <Content />
        </Dialog>
    ) : (
        <RecentlyVisitedPopper transition open={open} placement={placement} anchorEl={anchorEl}>
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={toggle}>
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ p: 4 }}>
                            <Content />
                        </Paper>
                    </Fade>
                </ClickAwayListener>
            )}
        </RecentlyVisitedPopper>
    );
};

export default function History(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [ref, setRef] = useState(null);

    const open = Boolean(anchorEl);

    const togglePopover = () => {
        if (open) {
            setAnchorEl(null);
        } else {
            setAnchorEl(ref);
        }
    };

    useKeyboardShortcut(MODIFIERS.ALT, KEYCODES.H, togglePopover);

    return (
        <>
            {props.children ? (
                props.children({ onClick: togglePopover, setRef })
            ) : (
                <Box
                    sx={theme => ({
                        [`& .${listItemButtonClasses.root}`]: {
                            display: 'flex',
                            flexDirection: 'column',
                            height: 40,
                            width: 64,
                            borderRadius: '12px',
                            boxShadow: '0px 1px 2px #B6C7D6',
                            bgcolor: open ? '#D2EBF6' : 'common.white'
                        },
                        [`& .${iconClasses.root}`]: {
                            color: 'success.main',
                            fontSize: 24
                        },
                        [`& .${typographyClasses.root}`]: {
                            letterSpacing: '0.4px',
                            fontSize: theme.typography.pxToRem(12),
                            lineHeight: '16px'
                        }
                    })}
                >
                    <Tooltip arrow title={<ShortcutTooltip title="Recently Viewed Shortcut" meta="alt" shortcut="H" />} placement="top" sx={{ height: '32px' }}>
                        <ListItemButton onClick={togglePopover} ref={setRef}>
                            <ListItemIcon variant="xlarge">
                                <Icon>schedule</Icon>
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                </Box>
            )}
            <RecentlyVisited open={open} anchorEl={anchorEl} toggle={() => setAnchorEl(null)} />
        </>
    );
}

const RecentlyVisitedPopper = styled(Popper)(({ theme }) => ({
    bgcolor: theme.palette.common.white,
    zIndex: 2000,
    width: 375,
    marginLeft: `${theme.spacing(4)} !important`
}));

const PopperTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    [`& .${typographyClasses.root}`]: {
        marginLeft: theme.spacing(2),
        fontSize: theme.typography.pxToRem(24)
    }
}));

const PopperSubtitle = styled('div')(({ theme }) => ({
    color: theme.palette.common.gray,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(8),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: '16px'
}));

export const HistoryContentWrapper = styled('div')(() => ({
    display: 'flex',
    flexWrap: 'wrap'
}));
