import { Icon, styled, Typography, typographyClasses } from '@mui/material';

import Link from 'Lib/components/common/Link';

import { HistoryTypeInterface } from './historyTypes';

export const HistoryItemTitle = ({ historyTypeID }) => {
    const { icon, title } = HistoryTypeInterface[historyTypeID] || {};

    return (
        <StyledTitle>
            <Icon>{icon}</Icon>
            <Typography>{title}</Typography>
        </StyledTitle>
    );
};

export const HistoryItemContent = ({ children, path, onClick, sx }) => {
    return (
        <StyledContent sx={sx}>
            <Link to={path} onClick={onClick}>
                <Typography>{children}</Typography>
            </Link>
        </StyledContent>
    );
};

export const HistoryItem = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    ':not(:last-of-type)': {
        marginBottom: theme.spacing(4)
    }
}));

export const StyledTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    [`& .${typographyClasses.root}`]: {
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: '20px',
        letterSpacing: '0.25px',
        marginLeft: theme.spacing(2)
    }
}));

const StyledContent = styled('div')(({ theme }) => ({
    display: 'flex',
    overflow: 'hidden',
    marginLeft: theme.spacing(2),
    ':not(:last-of-type)': {
        marginLeft: theme.spacing(8)
    },
    '& .MuiTypography-root': {
        color: theme.palette.link.main
    }
}));
