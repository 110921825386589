import isNil from './isNil';

const precise = (value, decimalScale = 2) => {
    if (isNil(value)) return 0;

    let input = value;

    if (typeof input == 'string') {
        input = value.replace(/,/g, '');
    }

    return Number((input * 1).toFixed(decimalScale));
};

export default precise;
