import Enumeration from 'Lib/utilities/Enumeration';

const Months = new Enumeration([
    { id: 1, name: 'January', abbreviation: 'Jan' },
    { id: 2, name: 'February', abbreviation: 'Feb' },
    { id: 3, name: 'March', abbreviation: 'Mar' },
    { id: 4, name: 'April', abbreviation: 'Apr' },
    { id: 5, name: 'May', abbreviation: 'May' },
    { id: 6, name: 'June', abbreviation: 'Jun' },
    { id: 7, name: 'July', abbreviation: 'Jul' },
    { id: 8, name: 'August', abbreviation: 'Aug' },
    { id: 9, name: 'September', abbreviation: 'Sep' },
    { id: 10, name: 'October', abbreviation: 'Oct' },
    { id: 11, name: 'November', abbreviation: 'Nov' },
    { id: 12, name: 'December', abbreviation: 'Dec' }
]);

export default Months;
