import { useHistory } from 'react-router';
import { List, ListItemIcon } from '@mui/material';

import Dialog from 'Lib/muiComponents/dialog/Dialog';

import { SelectableListItemText, UserOptionButton } from './StyledComponents';

export default function SelectPortfolio({ portal, portfolio: currentPortfolio, open, onChangePortfolio, togglePortfoliosModal }) {
    const history = useHistory();

    return (
        <Dialog toggle={togglePortfoliosModal} open={open} title="Switch Portfolios">
            <List sx={{ pt: 0 }}>
                {portal?.portfolios?.map((portfolio, index) => {
                    const selectedPortfolio = portfolio.portfolioID == currentPortfolio?.portfolioID;

                    return (
                        <UserOptionButton
                            key={index}
                            onClick={() => {
                                onChangePortfolio(portfolio);
                                history.go(0);
                            }}
                            sx={{ borderRadius: 2, bgcolor: 'common.background', mb: 1 }}
                        >
                            {selectedPortfolio && (
                                <ListItemIcon sx={{ color: 'success.main' }}>
                                    <span className="material-symbols-rounded">task_alt</span>
                                </ListItemIcon>
                            )}
                            <SelectableListItemText primary={portfolio.name} selected={selectedPortfolio} />
                        </UserOptionButton>
                    );
                })}
            </List>
        </Dialog>
    );
}
