// @ts-check
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import GlobalSearchTrigger from 'ROOT/apps/manager/src/components/globalSearch/components/trigger';

import useConfig from 'Lib/hooks/api/useConfig';
import Logo from 'Lib/muiComponents/common/Logo';
import PrimaryActions from 'Lib/muiComponents/common/PrimaryActions';
import { screenSize } from 'Lib/utilities/screenSize';

export default function Header(props) {
    const { mobile: isMobileScreenSize, tablet: isTabletScreenSize } = screenSize();
    const mobileSize = isTabletScreenSize || isMobileScreenSize;

    const {
        portal: { isManager }
    } = useConfig();
    if (!isManager) return;

    // TODO: Once a decision has been made on handling custom background colors for the header, this value can be reset

    return (
        <AppBar position={mobileSize ? 'fixed' : 'static'} sx={{ background: 'white', boxShadow: !mobileSize && 'none', zIndex: '999' }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', flex: '1', lineHeight: '0', height: isMobileScreenSize ? '26px' : '20px' }}>
                    {mobileSize && (
                        <IconButton aria-label="drawer" id="mobile-menu" sx={{ mr: 2 }} onClick={props.toggleDrawer}>
                            menu
                        </IconButton>
                    )}
                    <Logo />
                </Box>

                <Box sx={{ width: '50%', maxWidth: mobileSize ? '130px' : '500px' }}>
                    <GlobalSearchTrigger />
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: '1' }}>
                    <PrimaryActions />
                </Box>
            </Toolbar>
        </AppBar>
    );
}
