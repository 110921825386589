// @ts-check
import useParam from 'Lib/hooks/useParam';

export function useGSFilter() {
    return useParam('_gsFilter');
}

export function useGSSearch() {
    return useParam('_gsSearch');
}

export function useGSIncludeInactive() {
    return useParam('_gsIncludeInactive');
}
