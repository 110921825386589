// @ts-check

import { Box, Icon, Skeleton, Stack } from '@mui/material';

import { CollectionLabel } from '../../../StyledComponents';

export default function SkeletonTitle() {
    return (
        <CollectionLabel>
            <Stack direction="row" alignItems="center" gap={2} justifyContent="start">
                <Icon>
                    <Skeleton width="100" variant="rounded" animation="wave" />
                </Icon>
                <Box sx={{ fontSize: '0.9rem', width: '150px' }}>
                    <Skeleton variant="rectangular" height="80" width="150" animation="wave" sx={{ flexGrow: '1' }} />
                </Box>
            </Stack>
            <Box sx={{ fontSize: theme => theme.typography.pxToRem(10), width: '150px', letterSpacing: 'auto', fontWeight: theme => theme.typography.fontWeightRegular }}>
                <Skeleton variant="rectangular" animation="wave" height="80" width="150" />
            </Box>
        </CollectionLabel>
    );
}
