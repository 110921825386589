import { ResultContainer, ResultFooter, ResultTitle } from './Result';

export default function OwnerResult(props) {
    const path = `/contacts/owners/${props.objectID}`;

    return (
        <ResultContainer disableExpand {...props}>
            <ResultTitle label={props.title} icon="location_away" indexTypeID={props.indexTypeID} to={path} />
            <ResultFooter
                to={path}
                actions={[
                    { icon: 'mail', label: 'Email', to: `${path}/?tab=messages&composeEmail=true` },
                    { icon: '3p', label: 'Text', to: `${path}/?tab=messages` }
                ]}
            />
        </ResultContainer>
    );
}
