// @ts-check

import { Fragment } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';

import PrimaryLeaseStatuses from 'Lib/constants/leases/primaryLeaseStatuses';
import useLeaseStatuses from 'Lib/hooks/data/useLeaseStatuses';
import stripHtml from 'Lib/utilities/stripHtml';

import Card, { Body, DeepLink, ObjectLink, Subtitle } from '../CardComponents';

/** @param {{result: LeaseResult}} param0 */
export default function Lease({ result }) {
    const cardType = 'Lease';

    const { data, additional, xray, highlights } = result;

    const {
        'tenants.name': highlightedNames,
        'tenants.name.shingled': highlightedNamesShingled,
        'tenants.phone': highlightedPhones,
        'unit.address.full.shingled': highlightedAddressShingled,
        'unit.address.streetNumber': highlightedStreetNumber,
        'unit.address.streetName': highlightedStreetName,
        'unit.address.address2': highlightedAddress2,
        'unit.address.city': highlightedCity,
        'unit.address.state': highlightedState,
        'unit.address.postalCode': highlightedPostalCode
    } = highlights;

    const highlightedAddress =
        highlightedAddressShingled?.[0][0] ??
        [
            highlightedStreetNumber?.[0][0] || data.unit.address.streetNumber,
            highlightedStreetName?.[0][0] || data.unit.address.streetName,
            highlightedAddress2?.[0][0] || data.unit.address.address2,
            highlightedCity?.[0][0] || data.unit.address.city,
            highlightedState?.[0][0] || data.unit.address.state,
            highlightedPostalCode?.[0][0] || data.unit.address.postalCode
        ]
            .filter(Boolean)
            .join(' ');

    const tenantNames = data.tenants.map(tenant => tenant.name).join(', ');

    const strippedHighlightedNames = highlightedNames?.[0].map(highlightedName => stripHtml(highlightedName)) ?? [];
    const strippedHighlightedNamesShingled = highlightedNamesShingled?.[0].map(highlightedNameShingled => stripHtml(highlightedNameShingled)) ?? [];

    const highlightedTenantNames = data.tenants
        .map(a => a.name)
        .filter(name => !strippedHighlightedNames.includes(name) && !strippedHighlightedNamesShingled.includes(name))
        .concat(highlightedNames?.[0], highlightedNamesShingled?.[0])
        .filter(Boolean)
        .join(', ');

    const { data: leaseStatuses } = useLeaseStatuses();
    const basePath = `/leases/${data.leaseID}`;
    const propertyPath = data.unit.isMultiUnit == '1' ? `/properties/${data.unit.propertyID}/units/${data.unit.unitID}` : `/properties/${data.unit.propertyID}`;

    const {
        leaseStatus: { name = '', primaryLeaseStatusID }
        // @ts-ignore
    } = leaseStatuses?.find(({ leaseStatus }) => leaseStatus.leaseStatusID == data.leaseStatusID) ?? { leaseStatus: {} };
    const status = name.includes(PrimaryLeaseStatuses.getName(primaryLeaseStatusID)) ? name : `${PrimaryLeaseStatuses.getName(primaryLeaseStatusID)} - ${name}`;

    return (
        <Card.Parent path={basePath} cardType={cardType}>
            <Card.Status status={status} />
            <Card.Content>
                <Card.Header title={tenantNames} highlight={highlightedTenantNames} cardType={cardType} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Icon path={propertyPath} icon="other_houses" />
                        <Subtitle.Item value={data.unit.address.full} highlight={highlightedAddress} />
                    </Subtitle.Parent>

                    <DeepLink.Parent>
                        <DeepLink.Item path={`/leases/${data.leaseID}?tab=transactions`} value={additional.balance ? `Balance: $${additional.balance}` : '$0.00'} />
                        <DeepLink.Item path={`/accounting/receivables/tenant/charge/add?leaseID=${data.leaseID}`} value="Add Charge" />
                        <DeepLink.Item path={`/accounting/receivables/tenant/receipt/add?leaseID=${data.leaseID}`} value="Record Receipt" />

                        <DeepLink.Xrays xrays={xray} highlights={highlights} />
                    </DeepLink.Parent>

                    <ObjectLink.Parent columns={2}>
                        <ObjectLink.Title value="Tenants" />
                        <ObjectLink.Title value="Phone" />

                        {data.tenants.map((tenant, i) => {
                            const tenantNameHighlight =
                                highlightedNames?.[0][strippedHighlightedNames.indexOf(tenant.name)] ||
                                highlightedNamesShingled?.[0][strippedHighlightedNamesShingled.indexOf(tenant.name)];

                            const phoneHighlight = tenant.phone === stripHtml(highlightedPhones?.[0]?.[0]) ? highlightedPhones?.[0]?.[0] : '';

                            return (
                                <Fragment key={i}>
                                    <ObjectLink.Item path={`/contacts/tenants/${tenant.contactID}`} value={tenant.name} highlight={tenantNameHighlight} />
                                    <ObjectLink.Item value={tenant.phone ? `${formatPhoneNumber(tenant.phone)}` : '--'} highlight={phoneHighlight} forceHighlight />
                                </Fragment>
                            );
                        })}
                    </ObjectLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
