// @ts-check
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import useAgent from 'Lib/hooks/api/useAgent';

import { useGSFilter, useGSSearch, useGSIncludeInactive } from '../components/search/Params';

import IndexTypes, { searchableIndexes } from './indexTypes';

const key = 'search/query';
const aiKey = 'search/assistant';

export const useGenAISearch = () => {
    // get the search term from the URL
    const search = useGSSearch();
    const prompt = search.value.substring(1);
    const isAIToggled = search.value.startsWith('/');
    const isQueryEnabled = search.value.length > 1 && isAIToggled;

    return useQuery({
        queryKey: [aiKey, { input: prompt }],
        enabled: isQueryEnabled,
        staleTime: 0
    });
};

export default function useInfiniteSearch(select) {
    const { get } = useAgent();

    // get the search term from the URL
    const search = useGSSearch();
    const filter = useGSFilter();
    const includeInactive = useGSIncludeInactive();

    const params = {
        ...(search.value && { search: search.value }),
        ...(filter.value && { indexType: filter.value }),
        ...(includeInactive.value && { includeInactive: includeInactive.value })
    };

    const isAIToggled = search.value.startsWith('/');
    const isQueryEnabled = search.value.length > 1 && !isAIToggled;

    return useInfiniteQuery({
        queryKey: [key, params],
        queryFn: async ({ queryKey, pageParam }) => {
            // const [_, __, searchQuery] = queryKey;
            // if (searchQuery === '') return undefined;
            const [key, params] = queryKey;
            const updatedParams = { ...params, page: pageParam };
            const res = await get(key, updatedParams);

            /** @type {Collection[] } collections */
            const collections = res.data.collections;

            // Transforming the data in the queryFn. It's not the most efficient place to do it according to: https://tkdodo.eu/blog/react-query-data-transformations#1-in-the-queryfn.
            // But, I want to have the transformed data in the cache, so easier to see and implement. If it causes performance issues, we can move transformations to the select functions later.
            return {
                collections: [
                    ...collections.map(collection => {
                        return { ...collection, results: collection.results };
                    })
                ],
                chips: [
                    ...collections.map(collection => {
                        return { value: collection.indexType, label: IndexTypes.getName(IndexTypes[collection.indexType]), resultCount: collection.totalResults };
                    })
                ],
                count: collections.reduce((acc, collection) => acc + collection.totalResults, 0)
            };
        },
        select,
        enabled: isQueryEnabled,
        staleTime: 0,
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (!lastPage) return undefined;
            if (!lastPage.collections) return undefined;
            if (lastPage.collections[0].results.length < 20) return undefined;

            return lastPageParam + 1;
        }
    });
}

export function useSearchResults() {
    const results = res => {
        return {
            results: res.pages
                .reduce((acc, page) => {
                    return [...acc, ...page.collections];
                }, [])
                .reduce((acc, collection) => {
                    return [...acc, ...collection.results];
                }, [])
                .flat(),
            collection: res.pages[0].collections[0],
            collectionLabel: searchableIndexes.filter(item => item.key === res.pages[0].collections[0].indexType)[0],
            totalResults: res.pages[0].count
        };
    };

    return useInfiniteSearch(results);
}
