import queryString from 'query-string';

export const setTitle = (path, object = null, rawValue = null) => {

	const { tab } = queryString.parse(path.search);
	let createPathname = path.pathname;
	let title = "Rentvine"

	String.prototype.capitalize = function() {
		return this.charAt(0).toUpperCase() + this.slice(1);
	}

	if (path.pathname != "/" && createPathname) {
		createPathname = path?.pathname.split('/').filter(path => isNaN(parseInt(path)) && path != "").map( path => {
			return path.capitalize()
		});

		title = (createPathname.length > 0 ? (createPathname.join(' - ')) : "");
	}

	if (object) {
		if (object.address) {
			title = title + ' - ' + object.address;
		} else {
			title = title + ' - ' + object.name
		}
	}

	if (tab) {
		title = title + ' - ' + tab.capitalize();
	}

	if (rawValue) {
		title = title + ' - ' + rawValue;
	}

	return title;
}