// @ts-check
import { useContext } from 'react';
import { Stack } from '@mui/material';

import isEmpty from 'Lib/utilities/isEmpty';

import { NotificationsContext } from '../../context';
import EmptyNotifications from '../Empty';
import Header from '../Header';

import NotificationListItem from './ListItem';

/** @param {{notifications: NotificationReturn[]}} notifications */
export default function NotificationList({ notifications = [] }) {
    const { state } = useContext(NotificationsContext);

    if (isEmpty(notifications)) return <EmptyNotifications message={`You have no ${state.detailView} notifications.`} header="Well Done" />;

    return (
        <>
            <Header showingCount={notifications?.length || 0} />

            <Stack direction="column" gap={2}>
                {notifications.map(({ notification }) => (
                    <NotificationListItem key={notification.userSystemNotificationID} notification={notification} />
                ))}
            </Stack>
        </>
    );
}
