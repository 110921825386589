import { Avatar, Badge, Box, Container, Icon, Typography } from '@mui/material';

export default function EmptyNotifications(props) {
    return (
        <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Box display="flex" justifyContent="center" width="100%" sx={[...(Array.isArray(props.avatarBoxSX) ? props.avatarBoxSX : [props.avatarBoxSX])]}>
                <Avatar sx={{ bgcolor: 'success.secondary', height: 96, width: 96 }}>
                    <Badge overlap="circular" badgeContent={<Icon sx={{ color: 'success.main' }}>auto_awesome</Icon>}>
                        <Icon sx={{ color: 'success.main', fontSize: 56 }}>notifications</Icon>
                    </Badge>
                </Avatar>
            </Box>
            {props.header && (
                <Typography
                    sx={{
                        fontWeight: '600',
                        mt: 4
                    }}
                >
                    {props.header}
                </Typography>
            )}
            <Typography paragraph sx={[...(Array.isArray(props.sx) ? props.sx : [props.sx])]}>
                {props.message}
            </Typography>
        </Container>
    );
}
