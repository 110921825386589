export const openExternalLink = href => {
    const link = document.createElement('a');
    link.href = href;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default openExternalLink;
