import Enumeration from 'Lib/utilities/Enumeration';

const TransactionTypes = new Enumeration([
    { id: 1, name: 'Lease Charge', key: 'LeaseCharge' },
    { id: 2, name: 'Tenant Receipt', key: 'LeasePayment' }, // /accounting/receivables/tenant/receipt
    { id: 3, name: 'Lease Credit', key: 'LeaseCredit' }, // /accounting/receivables/tenant/credit
    { id: 4, name: 'Returned Tenant Payment', key: 'LeasePaymentReturn' },
    { id: 5, name: 'Owner Receipt', key: 'OwnerPayment' },
    { id: 6, name: 'Returned Owner Contribution', key: 'OwnerPaymentReturn' },
    { id: 7, name: 'Bill Charge', key: 'BillCharge' },
    { id: 8, name: 'Bill Payment Charge', key: 'BillPayoutCharge' },
    { id: 9, name: 'Bank Fee', key: 'BankFee' },
    { id: 10, name: 'Bank Transfer', key: 'BankTransfer' },
    { id: 11, name: 'Journal Entry', key: 'JournalEntry' },
    { id: 12, name: 'Other Receipt', key: 'OtherPayment' },
    { id: 13, name: 'Returned Other Payment', key: 'OtherPaymentReturn' },
    { id: 14, name: 'Management Fee', key: 'ManagementFee' },
    { id: 15, name: 'Ledger Transfer', key: 'LedgerTransfer' },
    { id: 16, name: 'Ledger Payout', key: 'LedgerPayout' },
    { id: 17, name: 'Ledger Payout Return', key: 'LedgerPayoutReturn' },
    { id: 18, name: 'Bill Payout Charge Return', key: 'BillPayoutChargeReturn' },
    { id: 19, name: 'Lease Deposit Release Credit', key: 'LeaseDepositReleaseCredit' }, // /accounting/receivables/tenant/deposit-release
    { id: 20, name: 'Lease Refund', key: 'LeasePayout' },
    { id: 21, name: 'Lease Refund Return', key: 'LeasePayoutReturn' },
    { id: 22, name: 'Bill Credit', key: 'BillCredit' },
    { id: 23, name: 'Bill Credit Allocation', key: 'BillPayoutCredit' },
    { id: 24, name: 'Bill Credit Allocation Return', key: 'BillPayoutCreditReturn' },
    { id: 25, name: 'Lease Refunded Return', key: 'LeaseRefundedReturn' }
]);

export const DepositTransactionTypes = new Enumeration([
    { id: TransactionTypes.LeasePayment, name: TransactionTypes.getName(TransactionTypes.LeasePayment) },
    { id: TransactionTypes.OwnerPayment, name: TransactionTypes.getName(TransactionTypes.OwnerPayment) },
    { id: TransactionTypes.OtherPayment, name: TransactionTypes.getName(TransactionTypes.OtherPayment) }
]);

export default TransactionTypes;
