import Enumeration from 'Lib/utilities/Enumeration';

const NavigationCategories = new Enumeration([
    { id: 1, name: 'Portfolio' },
    { id: 2, name: 'Property' },
    { id: 3, name: 'Screening' },
    { id: 4, name: 'Leases' },
    { id: 5, name: 'Rentsign' },
    { id: 6, name: 'Accounting' },
    { id: 7, name: 'Communications' },
    { id: 8, name: 'Maintenance' },
    { id: 9, name: 'Reports' },
    { id: 10, name: 'Settings' },
    { id: 11, name: 'Dashboards' }
]);

export default NavigationCategories;
