// @ts-check

import { Badge, badgeClasses, Box, iconClasses, ListItemButton, listItemButtonClasses, styled, ToggleButton, typographyClasses } from '@mui/material';

export const withChildrenWrapperStyles = {
    [`& .${listItemButtonClasses.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        height: 40,
        width: 64,
        bgcolor: 'common.white',
        borderRadius: '12px',
        boxShadow: '0px 1px 2px #B6C7D6'
    },
    [`& .${iconClasses.root}`]: {
        color: 'success.main',
        fontSize: 24
    },
    [`& .${typographyClasses.root}`]: {
        letterSpacing: '0.4px',
        fontSize: theme => theme.typography.pxToRem(12),
        lineHeight: '16px'
    },
    [`& .${badgeClasses.badge}`]: {
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        border: '1px solid #FFFFFF',
        fontSize: theme => theme.typography.pxToRem(12),
        lineHeight: '16px',
        fontFamily: theme => theme.typography.fontFamily,
        fontWeight: theme => theme.typography.fontWeightRegular,
        color: theme => `${theme.palette.common.white} !important`,
        overlap: 'circular',
        right: 0,
        top: 5
    }
};

export const StyledFooter = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    my: -3,
    textAlign: 'center',
    py: 3,
    fontSize: theme.typography.pxToRem(14)
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'none'
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular
    // left: '-6px',
    // top: '-2px'
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    backgroundColor: theme.palette.common.lightblue,
    border: `1px solid ${theme.palette.common.lightgray}`,
    padding: '16px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%'
}));
