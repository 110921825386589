// @ts-check

import PrimaryWorkOrderStatuses from 'Lib/constants/maintenance/primaryWorkOrderStatuses';
import useWorkOrderStatuses from 'Lib/hooks/data/useWorkOrderStatuses';

import Card, { Body, DeepLink, Description, ObjectLink, Subtitle } from '../CardComponents';
/** @param {{result: WorkOrderResult}} param0 */
export default function WorkOrder({ result }) {
    const cardType = 'Work Order';

    const { data, xray, highlights } = result;

    const { data: workOrderStatuses } = useWorkOrderStatuses();

    const {
        'address.full.shingled': highlightedAddressShingled,
        'address.streetNumber': highlightedStreetNumber,
        'address.streetName': highlightedStreetName,
        'address.address2': highlightedAddress2,
        'address.city': highlightedCity,
        'address.state': highlightedState,
        'address.postalCode': highlightedPostalCode
    } = highlights;

    const highlightedAddress =
        highlightedAddressShingled?.[0][0] ??
        [
            highlightedStreetNumber?.[0][0] || data.address.streetNumber,
            highlightedStreetName?.[0][0] || data.address.streetName,
            highlightedAddress2?.[0][0] || data.address.address2,
            highlightedCity?.[0][0] || data.address.city,
            highlightedState?.[0][0] || data.address.state,
            highlightedPostalCode?.[0][0] || data.address.postalCode
        ]
            .filter(Boolean)
            .join(' ');

    const highlightedWorkOrderNumber = highlights.workOrderNumber?.[0][0];
    const highlightedVendorName = highlights['vendor.name']?.[0][0];

    const {
        workOrderStatus: { name = '', primaryWorkOrderStatusID }
    } = workOrderStatuses?.find(({ workOrderStatus }) => workOrderStatus.workOrderStatusID == data.workOrderStatusID) ?? { workOrderStatus: {} };
    const status = name.includes(PrimaryWorkOrderStatuses.getName(primaryWorkOrderStatusID)) ? name : `${PrimaryWorkOrderStatuses.getName(primaryWorkOrderStatusID)} - ${name}`;

    return (
        <Card.Parent path={`/maintenance/work-orders/${data.workOrderID}`} cardType={cardType}>
            <Card.Status status={status} />
            <Card.Content>
                <Card.Header
                    title={data.workOrderNumber}
                    highlight={highlightedWorkOrderNumber}
                    cardType={cardType}
                    subtype={data.workOrderProject.workOrderProjectNumber ? `Project: ${data.workOrderProject.workOrderProjectNumber}` : ''}
                />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item value={data.address.full} highlight={highlightedAddress} />
                    </Subtitle.Parent>

                    <Description>{data.description}</Description>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} highlights={highlights} />
                    </DeepLink.Parent>

                    <ObjectLink.Parent>
                        {data.vendor.vendorContactID && <ObjectLink.Title value="Vendor" />}

                        <ObjectLink.Item path={`/maintenance/vendors/${data.vendor.vendorContactID}`} highlight={highlightedVendorName} value={data.vendor.name} />
                    </ObjectLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
