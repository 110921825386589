import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import { styled } from '@mui/system';

import DrawerContent from './DrawerContent';

const Container = styled(Paper, { shouldForwardProp: prop => prop !== 'fullscreen' && prop !== 'large' })(({ theme, fullscreen }) => ({
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
    borderRadius: '20px',
    overflow: 'auto',
    top: 0,
    right: 0,
    width: '396px',
    height: `calc(100vh - ${fullscreen ? '84px' : '48px'})`,
    marginTop: fullscreen ? theme.spacing(18) : theme.spacing(6),
    marginRight: theme.spacing(4),
    '& .MuiTypography-root': {
        fontSize: theme.typography.pxToRem(14)
    },
    '& .MuiTypography-h2': {
        fontSize: theme.typography.pxToRem(24),
        lineHeight: '32px'
    },
    '& input::placeholder': {
        fontSize: theme.typography.pxToRem(14)
    }
}));

export default function PinnedContainer(props) {
    const { pinnedContentTypeID } = useSelector(state => state.ui);

    return (
        pinnedContentTypeID === props.id &&
        createPortal(
            <Container sx={{ overflowX: 'hidden' }}>{props.title || props.footer ? <DrawerContent {...props}>{props.children}</DrawerContent> : props.children}</Container>,
            document.getElementById('pinned-container')
        )
    );
}
