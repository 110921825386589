import { request } from '~/agent';

export const getInit = () => {
    return request.get('/init');
};

export const getMe = () => {
    return request.get('/me');
};

export const dismissAlert = data => {
    return request.post('system/alerts', data);
};
