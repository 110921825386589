import * as actionTypes from './actionTypes';

export const request = (name, promise, getId) => ({
    type: actionTypes.Request,
    name,
    promise,
    getId
});

export const refresh = (name, promise, getId) => ({
    type: actionTypes.Refresh,
    name,
    promise,
    getId
});

export const remove = name => ({
    type: actionTypes.Remove,
    name
});
