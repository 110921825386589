// @ts-check

import { useRef } from 'react';
import { Box, Icon, Skeleton, Stack, Typography } from '@mui/material';

import useNavigateKeyboard from 'Lib/hooks/useKeyboardNavigation';
import Modal from 'Lib/muiComponents/modal';
import ModalClose from 'Lib/muiComponents/modal/ModalClose';
import ModalHeader from 'Lib/muiComponents/modal/ModalHeader';
import { screenSize } from 'Lib/utilities/screenSize';

import useGlobalSearch, { useGenAISearch, useSearchResults } from '../../data/useGlobalSearch';
import Results from '../results/ResultsList';
import Filters from '../search/Filters';
import Input from '../search/Input';
import { useGSFilter, useGSSearch } from '../search/Params';

export default function SearchModal() {
    return (
        <ModalContainer>
            <Box sx={{ px: 8, pt: 8, pb: 2, flex: '0 0' }}>
                {/** Top Section */}
                <ModalHeader
                    sx={{
                        bgcolor: ['common.white', null, 'transparent'],
                        justifyContent: 'flex-end',
                        display: ['flex', 'flex', 'flex', 'none'],
                        padding: '0 !important'
                    }}
                >
                    <ModalClose sx={{ color: 'common.black', padding: '0 !important' }} />
                </ModalHeader>
                {/** Search */}
                <Input />
                {/** Filters */}
                <Filters />
            </Box>
            {/** Search Results */}
            <ResultsContainer>
                <Results />
            </ResultsContainer>
            <ModalFooter />
        </ModalContainer>
    );
}

function ModalFooter() {
    const search = useGSSearch();
    const { mobile: isMobileScreenSize, tablet: isTabletScreenSize } = screenSize();
    const { data, isLoading } = useSearchResults();
    const aiSearch = search.value.startsWith('/');

    const isDesktop = !isTabletScreenSize && !isMobileScreenSize;

    return (
        <Box sx={{ px: 8, py: 4 }}>
            <Stack direction="row" justifyContent="center" alignItems="center" gap={4}>
                {isDesktop && (
                    <Stack direction="row" justifyContent={'start'} alignItems="center" gap={4} sx={{ flexGrow: 1 }}>
                        {/* <Typography component="h3" sx={{ fontWeight: '700', fontSize: '12px' }}>
                            Shortcuts:
                        </Typography> */}
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Icon variant="keyboard">north</Icon>
                            <Icon variant="keyboard">south</Icon>
                            <Box sx={{ fontSize: '12px' }}>to navigate</Box>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Icon variant="keyboard">keyboard_return</Icon>
                            <Box sx={{ fontSize: '12px' }}>to select</Box>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Icon variant="keyboard" sx={{ width: 'unset' }}>
                                <Box sx={{ fontSize: '12px', fontFamily: 'sans-serif', lineHeight: '17px', px: 1 }}>esc</Box>
                            </Icon>
                            <Box sx={{ fontSize: '12px' }}>to close</Box>
                        </Stack>
                    </Stack>
                )}

                {!aiSearch && (
                    <Stack direction="row" justifyContent={isDesktop ? 'end' : 'center'} alignItems="center" gap={4} sx={{ flexGrow: 1 }}>
                        {isLoading ? (
                            <Skeleton variant="rectangular" width="120" height="80" sx={{ flexGrow: 1 }} />
                        ) : (
                            <Typography component="span" sx={{ fontWeight: '700', fontSize: '12px', color: 'common.blue' }}>
                                {search.isSet ? (
                                    <>
                                        Showing {data?.results?.length} results of {data?.totalResults}
                                    </>
                                ) : (
                                    <>{data?.totalResults && data?.totalResults !== 0 && <>{data?.totalResults} Results</>}</>
                                )}
                            </Typography>
                        )}
                    </Stack>
                )}
            </Stack>
        </Box>
    );
}

const focusInput = parentRef => {
    const input = parentRef?.current.querySelector('.global-search_search-input').querySelector('input');
    if (input) {
        input.focus();
    }
};

function ModalContainer({ children }) {
    // get the search term from the URL
    const search = useGSSearch();
    const filter = useGSFilter();

    const handleClose = () => {
        search.removeValue();
        filter.removeValue();
    };

    return (
        <Modal
            name="Global Search"
            maxWidth="sm"
            modalSX={{ justifyContent: 'flex-start' }}
            containerSX={{ height: [3 / 4, 3 / 4, 'auto'], minHeight: 3 / 4, maxHeight: 1 }}
            paperSX={{ borderRadius: [0, 0, 6] }}
            onClose={handleClose}
        >
            <KeyboardNavContainer>{children}</KeyboardNavContainer>
        </Modal>
    );
}

function KeyboardNavContainer({ children }) {
    const { data } = useGlobalSearch();
    const { data: genAIData } = useGenAISearch();
    const parentRef = useRef(null);

    useNavigateKeyboard(parentRef, '.global-search_primary-link', [data, genAIData]);

    // add event listener to parentRef. If Cmd/Ctrl + K is pressed, focus on the input field
    if (parentRef.current) {
        parentRef.current.addEventListener('keydown', e => {
            if (e.metaKey && e.code === 'KeyK') {
                e.stopPropagation();
                e.preventDefault();
                focusInput(parentRef);
            }
        });
    }

    return (
        <Stack direction="column" justifyContent="stretch" alignItems="stretch" sx={{ flex: '1', maxHeight: '100%' }} ref={parentRef}>
            {children}
        </Stack>
    );
}

export function ResultsContainer({ children }) {
    return (
        <Stack direction="column" gap={3} alignItems="stretch" sx={{ flex: '1 0', minHeight: '0px', overflow: 'auto' }}>
            {children}
        </Stack>
    );
}
