import { Component } from 'react';
import { default as ReactDatePicker } from 'react-datepicker';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';

import { isDate } from 'Lib/utilities/datetime';
import isEmpty from 'Lib/utilities/isEmpty';

import 'react-datepicker/dist/react-datepicker.css';

class DatePicker extends Component {
    state = {
        value: ''
    };
    calendar = null;
    cursor = null;

    componentDidMount() {
        if (this.props.value) {
            this.setState({
                value: this.props.value
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
            this.setState(
                {
                    value: this.props.value
                },
                () => {
                    if (this.props.setValue) {
                        this.tryParseValue(this.props.value);
                    }
                }
            );
        }
    }

    setRef = calendar => {
        if (calendar) {
            this.calendar = calendar;
        }
    };

    toggleOpen = () => {
        if (this.calendar) {
            this.calendar.setOpen(!this.calendar.state.open, true);

            if (!this.calendar.state.open) {
                if (this.calendar.input?.ref) {
                    this.calendar.input.ref.focus();
                }
            }
        }
    };

    onChange = date => {
        this.setState({
            value: date
        });

        let value = null;

        if (!date || !isDate(date)) {
            value = null;
        } else {
            value = moment(date).format('YYYY-MM-DD');
        }

        if (this.props.onChange) {
            this.props.onChange(value, this.calendar.state.open);
        }
    };

    onBlur = event => {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    onBlurRaw = event => {
        const date = this.tryParseValue(event.target.value);

        if (this.props.onBlurRaw) {
            this.props.onBlurRaw(date);
        }
    };

    tryParseValue = value => {
        let possibleDate = null;

        if (!value) {
            this.calendar.setSelected(null);

            return null;
        }

        if (!/\/|-/.test(value) && value.length == 4) {
            value = `${value.substring(0, 2)}/${value.substring(2, 4)}/${moment().year()}`;

            possibleDate = moment(value, ['MM/DD/YYYY', 'MM/DD/YY'], true);

            if (possibleDate.isValid()) {
                this.calendar.setSelected(possibleDate.toDate());

                return possibleDate;
            }
        } else if (!/\/|-/.test(value) && (value.length == 6 || value.length == 8)) {
            value = `${value.substring(0, 2)}/${value.substring(2, 4)}/${value.substring(4)}`;

            possibleDate = moment(value, ['MM/DD/YYYY', 'MM/DD/YY'], true);

            if (possibleDate.isValid()) {
                this.calendar.setSelected(possibleDate.toDate());

                return possibleDate;
            }
        } else {
            possibleDate = moment(value, ['M/D/YY', 'MM/D/YY', 'M/DD/YY', 'MM/D/YYYY', 'M/DD/YYYY', 'M/D/YYYY', 'MM/DD/YYYY', 'MM/DD/YY'], true);

            if (possibleDate.isValid()) {
                this.calendar.setSelected(possibleDate.toDate());

                return possibleDate;
            } else {
                this.calendar.setSelected(null);

                return null;
            }
        }
    };

    render() {
        const { id, buttonStyle, className, dateFormat = 'MM/dd/yyyy', readOnly, styleOverride, value, ...rest } = this.props;
        let selected = null;

        if (!isEmpty(value)) {
            if (!isDate(value)) {
                const possibleDate = moment(value);

                if (possibleDate.isValid()) {
                    selected = moment(value).toDate();
                }
            }
        }

        const attrs = {
            ...rest,
            id,
            dateFormat,
            selected
        };

        if (dateFormat == 'MM/yyyy') {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative'
                    }}
                >
                    <div
                        style={{
                            flexGrow: '1',
                            paddingRight: '8px',
                            ...styleOverride
                        }}
                    >
                        <ReactDatePicker
                            {...attrs}
                            id={id}
                            ref={this.setRef}
                            autoComplete="off"
                            className={classNames('rv-datepicker', 'input', className, { 'read-only': readOnly })}
                            readOnly={readOnly}
                            dropdownMode="select"
                            preventOpenOnFocus={true}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            customInput={<InternalInput value={this.state.value} onBlurRaw={this.onBlurRaw} />}
                            selectsStart
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                        />
                    </div>
                    <Button style={{ ...buttonStyle, position: 'absolute', right: '0', margin: '0' }} onClick={this.toggleOpen} tabIndex="-1" outline color="link">
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                        <span className="sr-only">Show Calendar</span>
                    </Button>
                </div>
            );
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        flexGrow: '1',
                        ...styleOverride
                    }}
                >
                    <ReactDatePicker
                        {...attrs}
                        id={id}
                        ref={this.setRef}
                        autoComplete="off"
                        className={classNames('rv-datepicker', 'input', className, { 'read-only': readOnly })}
                        readOnly={readOnly}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        preventOpenOnFocus={true}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        customInput={<InternalInput onBlurRaw={this.onBlurRaw} />}
                    />
                </div>
                <Button style={{ ...buttonStyle, position: 'absolute', right: '0', margin: '0' }} onClick={this.toggleOpen} tabIndex="-1" outline color="link">
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                    <span className="sr-only">Show Calendar</span>
                </Button>
            </div>
        );
    }
}

class InternalInput extends Component {
    state = {
        value: ''
    };
    ref = null;

    componentDidMount() {
        this.setState({
            value: this.props.value
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
            this.setState({
                value: this.props.value.replace(/[a-z,A-Z]/g, '')
            });
        }
    }

    onKeyDown = e => {
        if (['t', 'm', 'l', 'y', 'p'].indexOf(e.key) !== -1) {
            return e.preventDefault();
        } else {
            return true;
        }
    };

    render() {
        const attrs = { ...this.props };
        const { onBlurRaw } = this.props;

        delete attrs.onBlurRaw;
        delete attrs.onClick;

        return <input {...attrs} onBlur={onBlurRaw} onKeyPress={this.onKeyDown} ref={element => (this.ref = element)} />;
    }
}

export default DatePicker;
