import * as actionTypes from './actionTypes';

export const cache = store => next => action => {
    if (action.type == actionTypes.Request) {
        const state = store.getState();
        let sendRetrieve = true;

        if (state.dataCache[action.name]) {
            const cacheContainer = state.dataCache[action.name];

            if (cacheContainer.timestamp) {
                const timeout = cacheContainer.timeout >= 0 ? cacheContainer.timeout : 300000; // Default to 5 minutes
                const now = new Date();

                if (timeout === 0) {
                    sendRetrieve = false;
                }

                if (now.getTime() - cacheContainer.timestamp.getTime() <= timeout) {
                    sendRetrieve = false;
                }
            }
        }

        if (sendRetrieve) {
            store.dispatch({
                ...action,
                type: actionTypes.Retrieve
            });
        } else {
            return false;
        }
    } else if (action.type == actionTypes.Refresh) {
        store.dispatch({
            ...action,
            type: actionTypes.Retrieve
        });
    } else if (action.type == actionTypes.Retrieve) {
        action
            .promise()
            .then(response => {
                next({
                    type: actionTypes.Update,
                    data: response.data,
                    getId: action.getId,
                    name: action.name,
                    timeout: action.timeout
                });
            })
            .catch(error => {
                next({
                    type: actionTypes.Error,
                    message: error,
                    name: action.name
                });
            });
    }

    next(action);
};

export default cache;
