// @ts-check

import SideDrawer, { DRAWER_CONTENT_TYPES } from '../../SideDrawer';
import NotificationFooter from '../components/Footer';
import SideBarTitle from '../components/SideBarTitle';

export default function SidebarWrapper({ children }) {
    return (
        <SideDrawer
            pinnable
            titleIcon="notifications"
            drawerContentTypeID={DRAWER_CONTENT_TYPES.NOTIFICATIONS}
            sx={{ '& .MuiPaper-root:first-of-type': { pb: 0 }, '& .MuiPaper-root:nth-of-type(2)': { p: 0, '& .MuiBox-root': { height: '100%' } } }}
            footer={<NotificationFooter />}
            title={<SideBarTitle />}
        >
            {children}
        </SideDrawer>
    );
}
