import { Button } from 'reactstrap';

export const SavingButton = props => {
    const { color = 'primary', text = 'Saving', ...rest } = props;

    return (
        <Button type="button" color={color} {...rest} disabled>
            <i className="fal fa-circle-notch fa-spin"></i>
            {'  '}
            {text}
        </Button>
    );
};

export default SavingButton;
