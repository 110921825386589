import Enumeration from 'Lib/utilities/Enumeration';

const PayoutTypes = new Enumeration([
    { id: 1, key: 'Other', name: 'Other' },
    { id: 2, key: 'Check', name: 'Check' },
    { id: 3, key: 'ECheck', name: 'eCheck (ACH)' },
    { id: 4, key: 'ACH', name: 'NACHA (ACH)' }
]);

export const OfflinePayoutTypes = new Enumeration([
    { id: 1, name: 'Other' },
    { id: 2, name: 'Check' }
]);

export const ElectronicPayoutTypes = new Enumeration([
    { id: 3, key: 'ECheck', name: 'eCheck (ACH)' },
    { id: 4, key: 'ACH', name: 'NACHA (ACH)' }
]);

export const calculateAvailablePayoutTypeOptions = (allowECheck, allowACH, payoutTypeID) => {
    const availablePayoutTypes = [{ label: 'Check', value: PayoutTypes.Check }];

    if (allowECheck && (payoutTypeID == PayoutTypes.ACH || payoutTypeID == PayoutTypes.ECheck)) {
        availablePayoutTypes.push({ label: 'eCheck (ACH)', value: PayoutTypes.ECheck });
    }

    if (allowACH && (payoutTypeID == PayoutTypes.ACH || payoutTypeID == PayoutTypes.ECheck)) {
        availablePayoutTypes.push({ label: 'NACHA (ACH)', value: PayoutTypes.ACH });
    }

    availablePayoutTypes.push({ label: 'Other', value: PayoutTypes.Other });

    return availablePayoutTypes;
};

export default PayoutTypes;
