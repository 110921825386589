// @ts-check
import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';

export function ExampleKeyboardWrapper({ children, className }) {
    const { data } = useQueryOrSomethingStateful();
    const parentRef = useRef(null);

    useNavigateKeyboard(parentRef, className, [data]);

    // add event listener to parentRef. If Cmd/Ctrl + K is pressed, focus on the input field (within the parentRef)
    if (parentRef.current) {
        parentRef.current.addEventListener('keydown', e => {
            if (e.metaKey && e.code === 'KeyK') {
                e.preventDefault();
                const input = parentRef?.current.querySelector(className).querySelector('input');
                if (input) {
                    input.focus();
                }
            }
        });
    }

    return <Box ref={parentRef}>{children}</Box>;
}

function useQueryOrSomethingStateful() {
    return {
        data: 'data'
    };
}

/**
 * @param {React.MutableRefObject<HTMLElement> | React.MutableRefObject<null>} parentRef - The parent element that contains the items you want to navigate.
 * This keeps a stable referenc to the parent element so it can be rendered in a portal. It also keeps the event listeners from polluting the document.
 * @param {string} className - Give each item you want to be keyboard navigable the same classname. '.global-search_primary-link' for example.
 * @param {[]} statefulValues - a value that when changed will trigger the focusItems to be updated. If you're using tanstackQuery, just use data.
 */
export default function useNavigateKeyboard(parentRef, className, statefulValues) {
    const [focusItems, setFocusItems] = useState([]);
    const [focusedIndex, setFocusedIndex] = useState(0);

    useEffect(() => {
        const nodeList = parentRef.current.querySelectorAll(className);
        let listItems = Array.from(nodeList);

        // if any item in teh list contains an input, replace the item with the input
        listItems = listItems.map(item => {
            if (item.querySelector('input') !== null) {
                return item.querySelector('input');
            }
            return item;
        });

        setFocusItems(listItems);
        setFocusedIndex(0);
    }, [...statefulValues]);

    useEffect(() => {
        focusItems[focusedIndex]?.focus();
    }, [focusedIndex, focusItems]);

    useEffect(() => {
        const ref = parentRef.current;
        if (focusItems.length > 0) {
            const handleKeyDown = e => {
                if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    setFocusedIndex(prevIndex => {
                        if (prevIndex === focusItems.length - 1) {
                            return 0;
                        } else {
                            return prevIndex + 1;
                        }
                    });
                } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    setFocusedIndex(prevIndex => {
                        if (prevIndex === 0) {
                            return focusItems.length - 1;
                        } else {
                            return prevIndex - 1;
                        }
                    });
                }
            };

            ref.addEventListener('keydown', handleKeyDown);

            return () => {
                ref.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [focusItems, ...statefulValues]);
}
