// @ts-check
import HtmlReactParser from 'html-react-parser';

import Date from 'Lib/components/common/Date';
import PayoutTypes from 'Lib/constants/accounting/payoutTypes';
import isEmpty from 'Lib/utilities/isEmpty';
import stripHtml from 'Lib/utilities/stripHtml';

import Card, { Body, DeepLink, Description, Subtitle } from '../CardComponents';

import Account from '~/components/accounting/Account';

/** @param {{result: TransactionPaymentResult}} param0 */
export default function TransactionPayment({ result }) {
    const cardType = 'Payment';

    const { data, xray, highlights } = result;

    const highlightedAmount = highlights['amount.keyword']?.[0][0];

    const dataReference = data.bankAccountGatewayTransactionID ?? data.checkNumber ?? data.memo;
    const highlightReferences = [
        highlights.bankAccountGatewayTransactionID?.[0]?.[0],
        highlights['bankAccountGatewayTransactionID.alphanumeric']?.[0]?.[0],
        highlights.checkNumber?.[0]?.[0],
        highlights['checkNumber.alphanumeric']?.[0]?.[0],
        highlights.memo?.[0]?.[0],
        highlights['memo.alphanumeric']?.[0]?.[0]
    ];
    const highlightedReference = highlightReferences.find(reference => stripHtml(reference) === dataReference) ?? '';

    const parsedReference = HtmlReactParser(highlightedReference || '');
    const reference = !isEmpty(parsedReference) ? parsedReference : dataReference;

    const dataPayeeName = data.checkPayeeName ?? data.contact.name;
    const highlightPayeeName = highlights.checkPayeeName?.[0]?.[0] ?? highlights['contact.name']?.[0]?.[0];
    const highlightedPayeeName = stripHtml(highlightPayeeName) === dataPayeeName ? highlightPayeeName : '';

    const parsedPayeeName = HtmlReactParser(highlightedPayeeName);
    const payeeName = !isEmpty(parsedPayeeName) ? parsedPayeeName : dataPayeeName;

    let statusText = '';
    let statusVariant = '';

    if (data.isVoided) {
        statusText = 'Voided';
        statusVariant = 'danger';
    } else {
        if (!isEmpty(data.reconciliationID)) {
            statusText = 'Reconciled';
        } else if (!isEmpty(data.settlementID)) {
            statusText = 'Settled';
        }

        if (!isEmpty(data.payoutReturnID)) {
            statusText += ': Returned';
            statusVariant = 'danger';
        }
    }

    return (
        <Card.Parent path={`/accounting/payables/payouts/${data.payoutID}`} cardType={cardType}>
            {!isEmpty(statusText) && <Card.Status status={statusText} variant={statusVariant} />}

            <Card.Content>
                <Card.Header title={`$${data.amount.amount}`} highlight={highlightedAmount} forceHighlight cardType={cardType} subtype={PayoutTypes.getName(data.payoutTypeID)} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item>
                            <Date value={data.datePosted} />
                            {!isEmpty(reference) && (
                                <>
                                    , Reference #:&nbsp;
                                    {reference}
                                </>
                            )}
                        </Subtitle.Item>
                    </Subtitle.Parent>

                    <Description>
                        {!isEmpty(payeeName) && <>Payee: {payeeName},&nbsp;</>}
                        Bank: <Account id={data.bankAccountID} />
                    </Description>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} highlights={highlights} forceHighlight />
                    </DeepLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
