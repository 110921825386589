import { createAgent, getApiRoot, root_domain } from 'Lib/portalAgent';

import * as actions from './services/session';

import store from '~/store';

const agent = createAgent(actions, store);
const portalType = store.getState().app.portalTypeID;

export const request = agent.request;
export const API_ROOT = getApiRoot(portalType);
export { root_domain };
export default agent;
