import { Component } from 'react';
import ReactSelect from 'react-select';

import { groupedSelectStyles } from 'Lib/theme';
import isEmpty from 'Lib/utilities/isEmpty';
import { isNil } from 'Lib/utilities/isNil';

/**
 * Use Select2 if you want multi select. Eventually we will need
 * to do some clean up, and change all select to select2.
 * I believe select2 will do everything select will do, but
 * will need more time to go over anywhere select is used
 * - Pete
 */
class Select extends Component {
    handleChange = option => {
        if (this.props.onChange) {
            this.props.onChange(option);
        }
    };

    handleBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    getSelectedValue = (value, options) => {
        if (isNil(value)) return null;

        let selectedValue = null;
        let i = 0;
        let option;

        for (i = 0; i < options.length; i++) {
            option = options[i];

            if (option.options) {
                selectedValue = this.getSelectedValue(value, option.options);

                if (selectedValue) return selectedValue;
            } else {
                if (option.value == value) return option;
            }
        }

        return null;
    };

    getNoOptionsMessage = e => {
        const { emptyMessage, noOptionsMessage } = this.props;

        if (isEmpty(e.inputValue)) {
            return emptyMessage ? emptyMessage : 'Type to search for options';
        }

        return noOptionsMessage ? noOptionsMessage : `No options found matching ${e.inputValue}`;
    };

    filterOptions = (option, searchText) => {
        const textToSearch = option.data?.searchableText || option.data?.label;

        return textToSearch ? String(textToSearch).toLowerCase().includes(searchText.toLowerCase()) : false;
    };

    render() {
        const { options, value, className = '', ...rest } = this.props;

        if (rest.isClearable !== false) {
            rest.isClearable = true;
        }

        return (
            <ReactSelect
                {...rest}
                styles={{
                    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    ...groupedSelectStyles
                }}
                filterOption={this.filterOptions}
                className={className}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                options={options}
                value={this.getSelectedValue(value, options)}
                noOptionsMessage={this.getNoOptionsMessage}
            />
        );
    }
}

export default Select;
