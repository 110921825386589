// @ts-check
import { Grid } from '@mui/material';
import HtmlReactParser from 'html-react-parser';

import Date from 'Lib/components/common/Date';
import formatAmountString from 'Lib/utilities/formatAmountString';
import isEmpty from 'Lib/utilities/isEmpty';

import Card, { Body, DeepLink, Description, Subtitle } from '../CardComponents';

import Account from '~/components/accounting/Account';

/** @param {{result: TransactionBankTransferResult}} param0 */
export default function TransactionBankTransfer({ result }) {
    const cardType = 'Bank Transfer';

    const { data, xray, highlights } = result;

    const highlightedAmount = highlights['amount.keyword']?.[0][0];

    const parsedReference = HtmlReactParser(highlights.reference?.[0]?.[0] || highlights['reference.alphanumeric']?.[0]?.[0] || '');
    const reference = !isEmpty(parsedReference) ? parsedReference : data.reference;

    return (
        <Card.Parent path={`/accounting/banking/transfers/${data.bankTransferID}`} cardType={cardType}>
            {data.isVoided && <Card.Status status={'Voided'} variant="danger" />}
            <Card.Content>
                <Card.Header title={`$${formatAmountString(data.amount.amount)}`} highlight={highlightedAmount} forceHighlight cardType={cardType} />

                <Body>
                    <Subtitle.Parent>
                        <Grid item>
                            <Account id={data.sourceBankAccountID} showNumber={false} /> &gt; <Account id={data.destinationBankAccountID} showNumber={false} />
                        </Grid>
                    </Subtitle.Parent>

                    <Description>
                        <Date value={data.datePosted} />
                        {!isEmpty(reference) && (
                            <>
                                , Reference:&nbsp;
                                {reference}
                            </>
                        )}
                    </Description>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} highlights={highlights} forceHighlight />
                    </DeepLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
