// @ts-check
import Card, { Body, DeepLink, Subtitle } from '../CardComponents';
/** @param {{result: BankAccountResult}} param0 */
export default function BankAccount({ result }) {
    const cardType = 'Bank Account';

    const { data, xray, highlights } = result;
    const { bankName: highlightedBankName, 'bankName.shingled': highlightedBankNameShingled, bankAccountNumberTruncated: highlightedBankAccountNumberTruncated } = highlights;

    const highlightedName = highlights.name?.[0][0] || highlights['name.shingled']?.[0][0];

    const bankAccount = `${data.bankName ? data.bankName + ' ' : ''}(xxx-${data.bankAccountNumberTruncated})`;
    const highlightedBankAccount = () => {
        const bankName = highlightedBankName || highlightedBankNameShingled || data.bankName;
        const accountNumber = highlightedBankAccountNumberTruncated || data.bankAccountNumberTruncated;

        return `${bankName ? bankName + ' ' : ''}(xxx-${accountNumber})`;
    };

    return (
        <Card.Parent path={`/accounting/banking/bank-accounts/${data.accountID}`} cardType={cardType}>
            <Card.Status status={data.isActive ? 'Active' : 'Deactivated'} variant={!data.isActive ? 'danger' : 'default'} />
            <Card.Content>
                <Card.Header title={data.name} highlight={highlightedName} cardType={cardType} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item value={bankAccount} highlight={highlightedBankAccount()} />
                    </Subtitle.Parent>

                    <DeepLink.Parent>
                        <DeepLink.Item path={`/accounting/banking/reconciliations/add?bankAccountID=${data.accountID}`} value={'Reconcile'} />
                        <DeepLink.Item path={`/accounting/banking/deposits/add?bankAccountID=${data.accountID}`} value={'Create Deposit'} />
                        <DeepLink.Xrays xrays={xray} highlights={highlights} />
                    </DeepLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
