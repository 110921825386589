import { Fragment } from 'react';

import Email from 'Lib/components/common/Email';

import { ResultItem } from '../Result';

export default function Emails({ value, max = 2 }) {
    return value ? (
        <ResultItem icon="mail">
            {value.slice(0, max).map(({ email }, index) => (
                <Fragment key={index}>
                    <Email email={email} />
                    {value.length === index + 1 ? null : ', '}
                </Fragment>
            ))}
            {value.length > max ? ' ...' : null}
        </ResultItem>
    ) : null;
}
