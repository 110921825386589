import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/maintenance/work-orders/:id([0-9]+)',
        component: Loader(() => import('~/containers/maintenance/work-orders/View'))
    },
    {
        path: '/maintenance/work-orders/add',
        component: Loader(() => import('~/containers/maintenance/work-orders/Add'))
    },
    {
        path: '/maintenance/work-orders',
        component: Loader(() => import('~/containers/maintenance/work-orders/List'))
    },
    {
        path: '/maintenance/work-orders/recurring',
        component: Loader(() => import('~/containers/maintenance/work-orders/recurring-work-orders/List'))
    },
    {
        path: '/maintenance/work-orders/recurring/add',
        component: Loader(() => import('~/containers/maintenance/work-orders/recurring-work-orders/Add'))
    },
    {
        path: '/maintenance/work-orders/recurring/:id([0-9]+)',
        component: Loader(() => import('~/containers/maintenance/work-orders/recurring-work-orders/View'))
    },
    {
        path: '/maintenance/work-orders/templates',
        component: Loader(() => import('~/containers/maintenance/work-orders/templates/List'))
    },
    {
        path: '/maintenance/work-orders/templates/:id([0-9]+)',
        component: Loader(() => import('~/containers/maintenance/work-orders/templates/View'))
    },
    {
        path: '/maintenance/work-orders/templates/add',
        component: Loader(() => import('~/containers/maintenance/work-orders/templates/Add'))
    },
    {
        path: '/maintenance/projects',
        component: Loader(() => import('~/containers/maintenance/projects/List'))
    },
    {
        path: '/maintenance/projects/add',
        component: Loader(() => import('~/containers/maintenance/projects/Add'))
    },
    {
        path: '/maintenance/projects/:id([0-9]+)',
        component: Loader(() => import('~/containers/maintenance/projects/View'))
    },
    {
        path: '/maintenance/projects/templates',
        component: Loader(() => import('~/containers/maintenance/projects/project-templates/List'))
    },
    {
        path: '/maintenance/projects/templates/:id([0-9]+)',
        component: Loader(() => import('~/containers/maintenance/projects/project-templates/View'))
    },
    {
        path: '/maintenance/projects/templates/add',
        component: Loader(() => import('~/containers/maintenance/projects/project-templates/Add'))
    },
    {
        path: '/maintenance/inspections',
        component: Loader(() => import('~/containers/maintenance/inspections/List'))
    },
    {
        path: '/maintenance/inspections/add',
        component: Loader(() => import('~/containers/maintenance/inspections/Add'))
    },
    {
        path: '/maintenance/inspections/:id([0-9]+)',
        component: Loader(() => import('~/containers/maintenance/inspections/View'))
    },
    {
        path: '/maintenance/vendors/:id([0-9]+)',
        component: Loader(() => import('~/containers/maintenance/vendors/View'))
    },
    {
        path: '/maintenance/vendors/add',
        component: Loader(() => import('~/containers/maintenance/vendors/Add'))
    },
    {
        path: '/maintenance/vendors',
        component: Loader(() => import('~/containers/maintenance/vendors/List'))
    },
    {
        path: '/maintenance/vendors/mass-message',
        component: Loader(() => import('~/containers/maintenance/vendors/Message'))
    },
    {
        path: '/maintenance/vendors/mass-letters',
        component: Loader(() => import('~/containers/maintenance/vendors/Letters'))
    },
    {
        path: '/settings/maintenance/work-order-statuses',
        component: Loader(() => import('~/containers/settings/maintenance/workOrderStatuses/controllers/WorkOrderStatusView'))
    }
];

export default routes;
