import { Component } from 'react';
import ReactMoment from 'react-moment';
import moment from 'moment';

class DateTime extends Component {
    render() {
        const { value, fromNow = false, calendarDate, defaultValue = null, format = 'MM-DD-YYYY h:mm A', localTimeZone, timeZone, ...rest } = this.props;

        if (!value) {
            return defaultValue;
        }

        if (calendarDate) {
            return moment.utc(value).local().calendar();
        }

        if (fromNow) {
            return moment.utc(value).local().fromNow();
        }

        if (localTimeZone) {
            const localTime = moment.utc(value).tz(moment.tz.guess());

            return localTime.format('h:mm A z');
        }

        if (timeZone) {
            const localTime = moment.tz(value, timeZone).tz(moment.tz.guess());

            return localTime.format('MM-DD-YYYY h:mm A');
        }

        return (
            <ReactMoment format={format} {...rest}>
                {moment.utc(value).local()}
            </ReactMoment>
        );
    }
}

export default DateTime;
