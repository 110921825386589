// @ts-check

import { useContext } from 'react';
import { Button, Icon, Stack, Typography } from '@mui/material';

import TimeStamp from '../components/TimeStamp';
import { NotificationsContext } from '../context';
import { useUpdateNotifications } from '../data/useNotifications';

/** @param {{notification: NotificationItem}} param0 */
export default function NotificationDetail({ notification }) {
    const { markAsUnread } = useUpdateNotifications();
    const { dispatch } = useContext(NotificationsContext);

    return (
        <Stack direction="column" gap={2} alignItems="stretch" justifyContent="space-between">
            <TimeStamp notification={notification} />

            <Typography sx={{ fontWeight: '600', color: 'common.black' }}>{notification.subject}</Typography>
            {notification.body && <Typography sx={{ fontWeight: '400', color: 'common.black' }}>{notification.body}</Typography>}

            <Stack direction="row" gap={4} alignItems="center" justifyContent="flex-start">
                <Button
                    startIcon={<Icon>mark_email_unread</Icon>}
                    size="small"
                    variant="alternate"
                    onClick={() => {
                        dispatch({ type: 'CLEAR_SELECTED_NOTIFICATION' });
                        markAsUnread(notification.userSystemNotificationID);
                    }}
                >
                    Mark as Unread
                </Button>
                {notification.link && (
                    <Button size="small" startIcon={<Icon>link</Icon>} variant="alternate" onClick={() => window.open(notification.link, '_blank')}>
                        View Update
                    </Button>
                )}
            </Stack>
        </Stack>
    );
}
