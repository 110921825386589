import * as actionTypes from './actionTypes';

export const initialState = {
    isAuthenticated: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE:
            return {
                ...action.session
            };
        default:
            return state;
    }
};

export default reducer;
