import Enumeration from 'Lib/utilities/Enumeration';

const CoreIndexes = new Enumeration([
    // ACTIONS
    { id: 1, name: 'Record Tenant Payment', url: '/accounting/receivables/tenant/receipt/add' },
    { id: 2, name: 'New Bank Deposit', url: '/accounting/banking/deposits/add' },
    { id: 3, name: 'New Property', url: '/properties/add' },
    { id: 4, name: 'New Move-In', url: '/leases/move-in' },
    { id: 5, name: 'Record Owner Payment', url: '/accounting/receivables/owner/receipt/add' },
    { id: 6, name: 'Post Late Fees', url: '/accounting/receivables/other/lateFee/add' },
    { id: 7, name: 'New Bill', url: '/accounting/payables/bills/add' },
    { id: 8, name: 'Pay Bills', url: '/accounting/payables/bills/pay' },
    { id: 11, name: 'New Vendor', url: '/maintenance/vendors/add' },
    { id: 13, name: 'New Work Order', url: '/maintenance/work-orders/add' },
    { id: 14, name: 'View Owner Statement', url: '/reports/owners' },
    { id: 15, name: 'View Tenant Ledger', url: '/reports/tenant-ledger' },
    { id: 16, name: 'New Tenant Charge', url: '/accounting/receivables/tenant/charge/add' },
    { id: 17, name: 'New Tenant Credit', url: '/accounting/receivables/tenant/credit/add' },
    { id: 18, name: 'New Reconcile', url: '/accounting/banking/reconciliations/add' },
    { id: 19, name: 'New Portfolio', url: '/portfolios/add' },
    { id: 20, name: 'Post Management Fees', url: '/accounting/manager/management-fees/post' },
    { id: 21, name: 'Pay Owners', url: '/accounting/payables/pay-owners' },
    { id: 22, name: 'Charge Management Fee', url: '/accounting/manager/management-fees/add' },
    { id: 23, name: 'New Bank Adjustment', url: '/accounting/banking/adjustments/add' },
    { id: 24, name: 'Create Owner Statements', url: '/portfolios/batches/add' },
    { id: 25, name: 'New Bank Fee', url: '/accounting/banking/fees/add' },
    { id: 26, name: 'New Bank Transfer', url: '/accounting/banking/transfers/add' },
    { id: 27, name: 'New Inspection', url: '/maintenance/inspections/add' },
    { id: 28, name: 'New Other Payment', url: '/accounting/receivables/other/receipt/add' },
    { id: 29, name: 'New Journal Entry', url: '/accounting/transactions/journal-entries/new' },
    { id: 30, name: 'New Ledger Transfer', url: '/accounting/transactions/ledger-transfers/add' },
    { id: 31, name: 'Settings', url: '/settings' },
    { id: 32, name: 'File Library', url: '/files' },
    { id: 33, name: 'Diagnostics', url: '/accounting/diagnostics' },
    // REPORTS
    { id: 100, name: 'Aged Payables', url: '/reports/aged-payables' },
    { id: 101, name: 'Aged Receivables', url: '/reports/aged-receivables' },
    { id: 102, name: 'Balance Sheet', url: '/reports/balance-sheet' },
    { id: 103, name: 'Bank Balance Breakdown', url: '/reports/bank-balance-breakdown' },
    { id: 104, name: 'Bank Ledger', url: '/reports/bank-ledger' },
    { id: 105, name: 'Budget', url: '/reports/budget' },
    { id: 106, name: 'Cash Flow', url: '/reports/cash-flow' },
    { id: 107, name: 'Cash Flow Annual', url: '/reports/cash-flow-annual' },
    { id: 108, name: 'Cash Flow Detail', url: '/reports/cash-flow-detail' },
    { id: 109, name: 'Cash Flow Property Comparison', url: '/reports/cash-flow-property-comparison' },
    { id: 110, name: 'Chart of Accounts', url: '/reports/account' },
    { id: 111, name: 'Check Register', url: '/reports/check-register' },
    { id: 112, name: 'Deposit Register', url: '/reports/deposit-register' },
    { id: 113, name: 'Deposits', url: '/reports/deposits' },
    { id: 114, name: 'Expense Distribution', url: '/reports/expense-distribution' },
    { id: 115, name: 'General Ledger', url: '/reports/general-ledger' },
    { id: 116, name: 'Income Statement', url: '/reports/income-statement' },
    { id: 117, name: 'Income Statement Annual', url: '/reports/income-statement-annual' },
    { id: 118, name: 'Income Statement Property Comparison', url: '/reports/income-statement-property-comparison' },
    { id: 119, name: 'Ledger', url: '/reports/ledger' },
    { id: 120, name: 'Ledger Summary', url: '/reports/ledger-summary' },
    { id: 121, name: 'Payables', url: '/reports/payables' },
    { id: 122, name: 'Recurring Bills', url: '/reports/recurring-bills' },
    { id: 123, name: 'Recurring Journal Entries', url: '/reports/recurring-journal-entries' },
    { id: 124, name: 'Recurring Ledger Transfers', url: '/reports/recurring-ledger-transfers' },
    { id: 125, name: 'Recurring Management Fees', url: '/reports/recurring-management-fees' },
    { id: 126, name: 'Sales Tax', url: '/reports/sales-tax' },
    { id: 127, name: 'Settlements', url: '/reports/settlements' },
    { id: 128, name: 'Trial Balance', url: '/reports/trial-balance' },
    { id: 129, name: 'Unit Management Fees', url: '/reports/unit-management-fees' },
    { id: 130, name: 'Lease Balances', url: '/reports/lease-balances' },
    { id: 131, name: 'Lease Charges', url: '/reports/lease-charges' },
    { id: 132, name: 'Lease Payments', url: '/reports/lease-payments' },
    { id: 133, name: 'Lease Recurring Charges', url: '/reports/lease-recurring-charges' },
    { id: 134, name: 'Lease Recurring Credits', url: '/reports/lease-recurring-credits' },
    { id: 135, name: 'Leases', url: '/reports/leases' },
    { id: 136, name: 'Prepayments', url: '/reports/lease-prepayment-balances' },
    { id: 137, name: 'Rent Roll', url: '/reports/rent-roll' },
    { id: 138, name: 'Security Deposits', url: '/reports/lease-security-deposit-balances' },
    { id: 139, name: 'Inspections', url: '/reports/inspections' },
    { id: 140, name: 'Work Orders', url: '/reports/work-orders' },
    { id: 141, name: 'Appliances', url: '/reports/appliances' },
    { id: 142, name: 'Associations', url: '/reports/associations' },
    { id: 143, name: 'Listings', url: '/reports/listings' },
    { id: 144, name: 'Properties', url: '/reports/properties' },
    { id: 145, name: 'Property Groups', url: '/reports/property-group' },
    { id: 146, name: 'Units', url: '/reports/units' },
    { id: 147, name: 'Vacancy', url: '/reports/vacancy' },
    { id: 148, name: 'Owner Units', url: '/reports/owner-unit' },
    { id: 149, name: 'Owners', url: '/reports/owners' },
    { id: 150, name: 'Portfolio Balances', url: '/reports/portfolio-balances' },
    { id: 151, name: 'Portfolios', url: '/reports/portfolios' },
    { id: 152, name: 'Tenant Ledger', url: '/reports/tenant-ledger' },
    { id: 153, name: 'Tenants', url: '/reports/tenants' },
    { id: 154, name: 'Vendor Ledger', url: '/reports/vendor-ledger' },
    { id: 155, name: 'Vendor Payments', url: '/reports/vendor-payment' },
    { id: 156, name: 'Vendors', url: '/reports/vendors' },
    { id: 157, name: 'Applicants', url: '/reports/applicants' },
    { id: 158, name: 'Applications', url: '/reports/applications' },
    { id: 159, name: 'Screening Payments', url: '/reports/screening-payments' },
    { id: 160, name: 'Screening Payouts', url: '/reports/screening-payouts' },
    { id: 161, name: 'Screening Refunds', url: '/reports/screening-refunds' },
    { id: 162, name: 'Emails', url: '/reports/emails' },
    { id: 163, name: 'Notes', url: '/reports/notes' },
    { id: 164, name: 'Income Statement Detail', url: '/reports/income-statement-detail' },
    { id: 165, name: 'Income Statement Detail (per property)', url: '/reports/income-statement-detail'}
]);

export default CoreIndexes;
