import { Component } from 'react';

import isEmpty from 'Lib/utilities/isEmpty';
import { isNil } from 'Lib/utilities/isNil';

import withCacheComponent from '~/components/hoc/withCache';
import { AccountsCacheKey, requestAccounts } from '~/services/cache/actions/accounting';

class Account extends Component {
    render() {
        const { accounts, cache } = this.props;

        if (cache.isLoading) {
            return <span />;
        }

        let { id, value, showNumber = true, showBankAccountNumber } = this.props;
        let suffix = '';

        if (id) {
            const row = accounts.data?.getById(id);

            if (row) {
                value = row.account;

                if (showBankAccountNumber) {
                    suffix = `xxxx-${row.account.bankAccountNumberTruncated}`;
                }
            }
        }

        return (
            !isNil(value) &&
            !isEmpty(value.number) && (
                <span>
                    {showNumber ? `${value.number}:` : ''} {value.name} {suffix}
                </span>
            )
        );
    }
}

export default withCacheComponent(Account, {
    accounts: {
        key: AccountsCacheKey,
        action: requestAccounts
    }
});
