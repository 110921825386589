// @ts-check
import { useContext } from 'react';
import { IconButton, Stack } from '@mui/material';

import isEmpty from 'Lib/utilities/isEmpty';

import { NotificationsContext } from '../context';

export default function SideBarTitle() {
    const { state, dispatch } = useContext(NotificationsContext);

    return !isEmpty(state.selectedNotification) || !isEmpty(state.pinnedNotification) ? (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
            <IconButton
                onClick={() => {
                    dispatch({ type: 'CLEAR_SELECTED_NOTIFICATION' });
                    dispatch({ type: 'CLEAR_PINNED_NOTIFICATION' });
                }}
            >
                arrow_back
            </IconButton>
            All Notifications
        </Stack>
    ) : (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
            <IconButton disableRipple>notifications</IconButton>
            Notifications
        </Stack>
    );
}
