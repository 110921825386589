// @ts-check
import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material';

import { ResultCard } from '../../../StyledComponents';
import { Body, DeepLink, ObjectLink } from '../CardComponents';

export default function CardExample() {
    const theme = useTheme();

    return (
        <ResultCard>
            <Box
                sx={{
                    position: 'absolute',

                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px',
                    border: `1px solid ${theme.palette.common.white}`,
                    backgroundColor: `${theme.palette.common.white}`,
                    transition: 'all 0.3s ease',
                    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 3px 8px 2px rgba(0, 0, 0, .1)'
                }}
            />
            <Grid container direction="row" columnSpacing={2} wrap="nowrap" sx={{ position: 'relative', pointerEvents: 'none' }}>
                <Box sx={{ position: 'absolute', top: -6, right: -4, width: '80px' }}>
                    <Skeleton sx={{ flexGrow: 1 }} variant="rectangular" width="80" height="60" />
                </Box>
                <Grid item xs>
                    <Grid container direction="column" columnSpacing={3} wrap="nowrap">
                        <Grid item xs sx={{ mt: 0 }}>
                            <Grid container direction="column" wrap="nowrap">
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontSize: theme => theme.typography.pxToRem(10),
                                            lineHeight: '1.4',
                                            textTransform: 'uppercase',
                                            letterSpacing: 2,
                                            position: 'relative',
                                            bottom: -2,
                                            width: '100px'
                                        }}
                                    >
                                        <Skeleton sx={{ width: '120px' }} variant="rectangular" width="100" height="80" />
                                    </Typography>
                                </Grid>
                                <Grid item xs sx={{ py: 1 }}>
                                    <Grid container direction="row" wrap="nowrap" gap={2} alignItems="center" sx={{ zIndex: 1 }}>
                                        <Grid sx={{ width: '75%', mt: 1 }} item>
                                            <Typography component="h2" sx={{ fontSize: theme => theme.typography.pxToRem(18), fontWeight: 'bold' }}>
                                                <Skeleton sx={{ flexGrow: 1 }} variant="rectangular" width="300" height="150" />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Body>
                            <Grid sx={{ width: '300px ', mt: 2 }} item>
                                <Typography variant="body2" component="div" sx={{ fontSize: theme => theme.typography.pxToRem(14) }}>
                                    <Skeleton sx={{ flexGrow: 1 }} variant="rectangular" width="120" height="130" />
                                </Typography>
                            </Grid>

                            <DeepLink.Parent>
                                <Grid item>
                                    <Skeleton sx={{ width: '200px ' }} variant="rectangular" width="120" height="100" />
                                </Grid>
                            </DeepLink.Parent>

                            <DeepLink.Parent>
                                <Grid item>
                                    <Skeleton sx={{ width: '120px ' }} variant="rectangular" width="120" height="80" />
                                </Grid>
                                <Grid item>
                                    <Skeleton sx={{ width: '120px ' }} variant="rectangular" width="120" height="80" />
                                </Grid>
                            </DeepLink.Parent>

                            <ObjectLink.Parent columns={2}>
                                <Grid item alignItems="center" sx={{ display: 'flex', width: '100px', fontSize: theme => theme.typography.pxToRem(11) }}>
                                    <Skeleton sx={{ flexGrow: 1 }} variant="rectangular" width="120" height="80" />
                                </Grid>
                                <Grid item alignItems="center" sx={{ display: 'flex', width: '100px', fontSize: theme => theme.typography.pxToRem(11) }}>
                                    <Skeleton sx={{ flexGrow: 1 }} variant="rectangular" width="120" height="80" />
                                </Grid>

                                <Grid
                                    item
                                    alignItems="center"
                                    justifyContent="start"
                                    sx={theme => ({
                                        display: 'flex',
                                        width: '100px',
                                        borderTop: `1px solid ${theme.palette.common.lightgray}`,
                                        mt: 1,
                                        fontSize: theme.typography.pxToRem(11)
                                    })}
                                >
                                    <Skeleton sx={{ flexGrow: 1 }} variant="rectangular" width="120" height="80" />
                                </Grid>
                                <Grid
                                    item
                                    alignItems="center"
                                    justifyContent="start"
                                    sx={theme => ({
                                        display: 'flex',
                                        width: '100px',
                                        mt: 1,
                                        borderTop: `1px solid ${theme.palette.common.lightgray}`,
                                        fontSize: theme.typography.pxToRem(11)
                                    })}
                                >
                                    <Skeleton sx={{ flexGrow: 1 }} variant="rectangular" width="120" height="80" />
                                </Grid>
                            </ObjectLink.Parent>
                        </Body>
                    </Grid>
                </Grid>
            </Grid>
        </ResultCard>
    );
}
