import { Container, Icon, Typography } from '@mui/material';

export default function Title(props) {
    return props.children ? (
        props.children
    ) : props.titleIcon ? (
        <Container sx={{ display: 'flex', alignItems: 'center', paddingBottom: '1rem' }}>
            <Icon sx={{ mr: 4 }}>{props.titleIcon}</Icon>
            <Typography variant="h2">{props.titleHeader}</Typography>
        </Container>
    ) : (
        <Typography variant="h2">{props.titleHeader}</Typography>
    );
}
