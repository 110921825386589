import Enumeration from 'Lib/utilities/Enumeration';

const PaymentTypes = new Enumeration([
    { id: 1, key: 'ECheck', name: 'eCheck (ACH)' },
    { id: 2, key: 'CreditCard', name: 'Credit or Debit Card' },
    { id: 3, key: 'Check', name: 'Check' },
    { id: 4, key: 'MoneyOrder', name: 'Money Order' },
    { id: 5, key: 'Cash', name: 'Cash' },
    { id: 6, key: 'Other', name: 'Other' },
    { id: 7, key: 'CashPay', name: 'Cash Pay' },
    { id: 8, key: 'CashiersCheck', name: "Cashier's Check" }
]);

export const OfflinePaymentTypes = new Enumeration([
    { id: 3, name: 'Check' },
    { id: 4, name: 'Money Order' },
    { id: 5, name: 'Cash' },
    { id: 6, name: 'Other' },
    { id: 8, key: 'CashiersCheck', name: "Cashier's Check" }
]);

export const ElectronicPaymentTypes = new Enumeration([
    { id: 1, name: 'ACH' },
    { id: 2, name: 'Credit Card' },
    { id: 7, name: 'Cash Pay' }
]);

export const isElectronicPaymentType = paymentTypeID => {
    const keys = Object.keys(ElectronicPaymentTypes.getMap());

    for (let i = 0; i < keys.length; i++) {
        if (keys[i] == paymentTypeID) {
            return true;
        }
    }

    return false;
};

export default PaymentTypes;
