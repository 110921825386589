// @ts-check
import { Fragment } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';

import stripHtml from 'Lib/utilities/stripHtml';

import Card, { Body, DeepLink, ObjectLink } from '../CardComponents';

/** @param {{result: PortfolioResult}} param0 */
export default function Portfolio({ result }) {
    const cardType = 'Portfolio';

    const { data, xray, highlights } = result;
    const { 'owners.name': highlightedOwnerNames, 'owners.name.shingled': highlightedOwnerNamesShingled, 'owners.phone': highlightedOwnerPhones } = highlights;

    const highlightedName = highlights.name?.[0][0] || highlights['name.shingled']?.[0][0];

    const strippedHighlightedOwnerNames = highlightedOwnerNames?.[0].map(highlightedOwnerName => stripHtml(highlightedOwnerName)) ?? [];
    const strippedHighlightedOwnerNamesShingled = highlightedOwnerNamesShingled?.[0].map(highlightedOwnerNameShingled => stripHtml(highlightedOwnerNameShingled)) ?? [];

    return (
        <Card.Parent path={`/portfolios/${data.portfolioID}`} cardType={cardType}>
            <Card.Status status={data.isActive ? 'Active' : 'Inactive'} />
            <Card.Content>
                <Card.Header title={data.name} highlight={highlightedName} cardType={cardType} />

                <Body>
                    <DeepLink.Parent>
                        <DeepLink.Item
                            path={`/portfolios/${data.portfolioID}?isActive=1&page=1&pageSize=15&portfolioIDs=${data.portfolioID}&tab=properties`}
                            value={`${data.propertyCount} Units`}
                        />
                        <DeepLink.Item path={`/portfolios/${data.portfolioID}?portfolioIDs=${data.portfolioID}&tab=ledger`} value={'Ledger'} />
                        <DeepLink.Xrays xrays={xray} highlights={highlights} />
                    </DeepLink.Parent>

                    <ObjectLink.Parent columns={2}>
                        <ObjectLink.Title value="Owner" />
                        <ObjectLink.Title value="Phone" />
                        {data.owners.map((owner, index) => {
                            const ownerNameHighlight =
                                highlightedOwnerNames?.[0][strippedHighlightedOwnerNames.indexOf(owner.name)] ||
                                highlightedOwnerNamesShingled?.[0][strippedHighlightedOwnerNamesShingled.indexOf(owner.name)];

                            const ownerPhoneHighlight = owner.phone === stripHtml(highlightedOwnerPhones?.[0]?.[0]) ? highlightedOwnerPhones?.[0]?.[0] : '';

                            return (
                                <Fragment key={index}>
                                    <ObjectLink.Item
                                        path={`/contacts/owners/${owner.contactID}`}
                                        value={`${owner.name} (${owner.percentOwned}%)`}
                                        highlight={ownerNameHighlight ? `${ownerNameHighlight} (${owner.percentOwned}%)` : ''}
                                    />
                                    <ObjectLink.Item value={owner.phone ? `${formatPhoneNumber(owner.phone)}` : '--'} highlight={ownerPhoneHighlight} forceHighlight />
                                </Fragment>
                            );
                        })}
                    </ObjectLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
