// @ts-check

import { Fragment, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Fade } from 'reactstrap';
import { Box, CircularProgress, Icon, Stack, Typography } from '@mui/material';

import EmptyView from 'Lib/components/common/table/EmptyView';
import { useGSFilter } from 'Lib/hooks/useParam';

import { searchableIndexes } from '../../data/indexTypes';
import useGlobalSearch, { useGenAISearch, useSearchResults } from '../../data/useGlobalSearch';
import HistoryList from '../history/HistoryList';

import AIResult from './cards/cardTypes/AIResult';
import SkeletonCard from './cards/cardTypes/SkeletonCard';
import SkeletonTitle from './cards/cardTypes/SkeletonTitle';
import Cards from './cards';

import { CollectionLabel, CollectionViewMore } from '~/components/globalSearch/components/StyledComponents';

export default function Results() {
    const filter = useGSFilter();

    const { data: genAIData, isError: aiError, isSuccess: aiSuccess } = useGenAISearch();
    const { data, isError, isSuccess, isFetching, isFetchingNextPage } = useGlobalSearch();

    // fetching return loading component
    if (isFetching && !isFetchingNextPage && !isSuccess) {
        return (
            <ResultsList>
                {!filter.isSet && <SkeletonTitle />}
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
            </ResultsList>
        );
    }

    // AI Results
    if (aiSuccess && genAIData) {
        if (genAIData.message) {
            return (
                <ResultsList>
                    <AIResult result={genAIData} />
                </ResultsList>
            );
        } else {
            return (
                <ResultsList>
                    <EmptyView grow backgroundColor="transparent" iconName={'chat'} title="No Results" />
                </ResultsList>
            );
        }
    }

    // AI Error
    if (aiError) {
        return (
            <ResultsList>
                <EmptyView backgroundColor="transparent" iconName={'chat'} title="No Results" subTitle="Rentvine Assist command was not recognized" />
            </ResultsList>
        );
    }

    // return recent if we haven't searched yet or if the search input is disabled because there are fewer than 2 characters.
    if (!isSuccess && !isError) {
        return (
            <ResultsList bgcolor="common.darkgray" pt={8}>
                <HistoryList />
            </ResultsList>
        );
    }

    // if an error happened, return error component
    if (isError) {
        return (
            <ResultsList>
                <EmptyView grow backgroundColor="transparent" iconName={'error'} title="An Error Occured" subTitle="Please try again..." />
            </ResultsList>
        );
    }

    // No Results component
    if (isSuccess && data?.pages[0].count === 0) {
        return (
            <ResultsList>
                <EmptyView grow backgroundColor="transparent" iconName={'chat'} title="No Results" />
            </ResultsList>
        );
    }

    // data return results component. This passes the result to the card component. If we want to implement grouping, we'd do it here.
    return (
        <ResultsList>
            {filter.isSet && <FilteredResults />}
            {data?.pages.length === 1 &&
                !filter.isSet &&
                data?.pages[0].collections.map((collection, index) => {
                    const collectionLabel = searchableIndexes.filter(item => item.key === collection.indexType)[0];

                    return (
                        <Fragment key={index}>
                            <CollectionLabelWrapper collection={collection} collectionLabel={collectionLabel} />
                            <ResultCards collection={collection} results={collection.results} />
                            <CollectionViewMoreWrapper collection={collection} />
                        </Fragment>
                    );
                })}
        </ResultsList>
    );
}

/**
 * @param {{collection: Collection, results: any[]}} param0
 * @returns
 **/
function ResultCards({ collection, results }) {
    return results.map((result, index) => {
        // make sure the card type exists in the Cards object... mostly just checking for spelling mistakes
        if (Cards[collection.indexType] === undefined) return;

        // create a component on the fly
        const Card = Cards[collection.indexType];

        // use aforementioned component
        return <Card key={index} result={result} />;
    });
}

function ResultsList({ bgcolor = 'white ', pt = 1, children }) {
    return (
        <Stack direction="column" gap={2} alignItems="stretch" justifyContent="start" sx={{ flex: '1 0', px: 8, pb: 8, pt: pt, bgcolor: bgcolor }}>
            {children}
        </Stack>
    );
}

/**
 *
 * @param {{collection: Collection, collectionLabel: IndexType}} param0
 * @returns
 */
function CollectionLabelWrapper({ collection, collectionLabel }) {
    if (!collectionLabel || collection.resultCount === 0) return;

    return (
        <CollectionLabel>
            <Stack direction="row" alignItems="center" gap={2} justifyContent="start">
                <Icon>{collectionLabel.icon}</Icon>
                <Box sx={{ fontSize: '0.9rem' }}>{collectionLabel.name}</Box>
            </Stack>
            <Box sx={{ fontSize: theme => theme.typography.pxToRem(10), letterSpacing: 'auto', fontWeight: theme => theme.typography.fontWeightRegular }}>
                Showing {collection.resultCount} of {collection.totalResults}
            </Box>
        </CollectionLabel>
    );
}

/**
 *
 * @param {{collection: Collection}} param0
 * @returns
 */
function CollectionViewMoreWrapper({ collection }) {
    const filter = useGSFilter();

    if (collection.resultCount === collection.totalResults || filter.isSet) return;

    return (
        <CollectionViewMore className="global-search_primary-link" disableFocusRipple={true} onClick={() => filter.setValue(collection.indexType)}>
            {`View More (+${collection.totalResults - collection.resultCount})`}
        </CollectionViewMore>
    );
}

function CollectionLoadMoreTrigger() {
    const filter = useGSFilter();

    const { status, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useSearchResults();

    const { ref, inView } = useInView();

    if (!filter.isSet) return;

    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView]);

    return (
        <Box ref={ref} sx={{ height: '32px', py: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Fade>
                {hasNextPage && (isFetching || isFetchingNextPage) && inView && (
                    <Box sx={{ display: 'flex', gap: 3, mt: 1, justifyContent: 'center', alignItems: 'center', color: 'gray' }}>
                        <CircularProgress size={24} thickness={1} />
                        <Box sx={{ fontSize: '12px' }}>Loading more messages...</Box>
                    </Box>
                )}

                {!hasNextPage && !isFetching && !isFetchingNextPage && (
                    <Box sx={{ fontSize: '12px', textAlign: 'center', p: 4, color: status === 'error' ? 'red' : 'gray' }}>{status === 'success' && 'End of results...'}</Box>
                )}
            </Fade>
        </Box>
    );
}

export function FilteredResults() {
    const { data } = useSearchResults();

    return (
        <>
            <ResultCards collection={data?.collection} results={data?.results} />
            <CollectionLoadMoreTrigger collection={data?.collection} collectionLabel={data?.collectionLabel} />
        </>
    );
}
