import { PureComponent } from 'react';
import { Box, Typography } from '@mui/material';

import isEmpty from 'Lib/utilities/isEmpty';
import { limitLength } from 'Lib/utilities/limitLength';

class Address extends PureComponent {
    renderInline = address => {
        const { maxLength, showCountry = true } = this.props;
        const parts = [];

        parts.push(address.address + ',');
        if (!isEmpty(address.address2)) {
            parts.push(address.address2 + ',');
        }

        parts.push(address.city + ',');
        parts.push(address.stateID);

        if (!showCountry) {
            parts.push(address.postalCode);
        } else {
            parts.push(address.postalCode);

            if (address.countryID) {
                parts.push(', ' + address.countryID);
            }
        }

        if (maxLength) return <span>{limitLength(parts.join(' '), maxLength)}</span>;
        else return <span>{parts.join(' ')}</span>;
    };

    mapAddressByPrefix = (address, prefix) => {
        const newAddress = {
            address: null,
            address2: null,
            city: null,
            stateID: null,
            postalCode: null,
            countryID: null
        };

        if (address[`${prefix}Address`]) {
            newAddress.address = address[`${prefix}Address`];
        }

        if (address[`${prefix}Address2`]) {
            newAddress.address2 = address[`${prefix}Address2`];
        }

        if (address[`${prefix}City`]) {
            newAddress.city = address[`${prefix}City`];
        }

        if (address[`${prefix}StateID`]) {
            newAddress.stateID = address[`${prefix}StateID`];
        }

        if (address[`${prefix}PostalCode`]) {
            newAddress.postalCode = address[`${prefix}PostalCode`];
        }

        if (address[`${prefix}CountryID`]) {
            newAddress.countryID = address[`${prefix}CountryID`];
        }

        return newAddress;
    };

    render() {
        const { value, displayInline = false, showCountry = true, prefix = null, unit, nowrap } = this.props;
        let address = value;

        if (prefix) {
            address = this.mapAddressByPrefix(address, prefix);
        }

        if (isEmpty(address) || !address.address) return <Typography>--</Typography>;

        if (displayInline) {
            return this.renderInline(address);
        }

        return (
            <Box className="address" sx={{ textWrap: nowrap ? 'nowrap' : 'initial' }}>
                <div>
                    {unit ? <div>{unit.name}</div> : null}
                    <div>{address.address}</div>
                    <div>{unit ? unit.address2 : address.address2}</div>
                    <div>
                        {address.city}, {address.stateID} {address.postalCode} {showCountry && address.countryID ? ', ' + address.countryID : null}
                    </div>
                </div>
            </Box>
        );
    }
}

export default Address;
