import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/portfolios',
        component: Loader(() => import('~/containers/portfolios/List'))
    },
    {
        path: '/portfolios/:id([0-9]+)',
        component: Loader(() => import('~/containers/portfolios/View'))
    },
    {
        path: '/portfolios/add',
        component: Loader(() => import('~/containers/portfolios/Add'))
    },
    {
        path: '/portfolios/batches/add',
        component: Loader(() => import('~/containers/portfolios/batches/Add'))
    },
    {
        path: '/portfolios/batches',
        component: Loader(() => import('~/containers/portfolios/batches/List'))
    },
    {
        path: '/portfolios/batches/:id([0-9]+)',
        component: Loader(() => import('~/containers/portfolios/batches/View'))
    },
    {
        path: '/portfolios/statements/:id([0-9]+)',
        component: Loader(() => import('~/containers/portfolios/statements/View'))
    },
    {
        path: '/portfolios/mass-message',
        component: Loader(() => import('~/containers/portfolios/Message'))
    },
    {
        path: '/portfolios/mass-letters',
        component: Loader(() => import('~/containers/portfolios/Letters'))
    }
];

export default routes;
