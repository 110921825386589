import { Box } from '@mui/system';

import userDevice from 'Lib/utilities/userDevice';

const isMac = userDevice().os === 'Mac';

export default function ShortcutTooltip(props) {
    const generateShortcutKey = () => {
        if (isMac) {
            if (props.meta == 'alt') {
                return 'Option';
            } else {
                return <span className="material-symbols-rounded">keyboard_command_key</span>;
            }
        } else {
            if (props.meta == 'alt') {
                return 'Alt';
            } else {
                return <span className="material-symbols-rounded">keyboard_control_key</span>;
            }
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', '& .material-symbols-rounded': { fontSize: '16px', height: '16px', width: '16px' } }}>
            {props.title || 'Keyboard Shortcut'}
            <Box ml={2} display="flex">
                {generateShortcutKey()}
                &nbsp;+&nbsp;
                {props.shortcut}
            </Box>
        </Box>
    );
}
