import { Component } from 'react';
import { default as ReactToggle } from 'react-toggle';

import './toggle.css';

class Toggle extends Component {
    handleChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    render() {
        // the 'fast' prop is destructured below as we want it to be omitted from the toggle itself (throws an annoying console warning)
        let { value, defaultChecked, fast, ...rest } = this.props;

        if (defaultChecked === false || defaultChecked === true) {
            rest.defaultChecked = defaultChecked;
        } else {
            if (value == 0 || value == false || value == '' || value == null) {
                value = false;
            } else {
                value = true;
            }
            rest.checked = value;
        }

        return <ReactToggle {...rest} onChange={this.handleChange} />;
    }
}

export default Toggle;
