import { Component } from 'react';
import ReactMoment from 'react-moment';

class Date extends Component {
    render() {
        // helperText is omitted to prevent passing through
        const { value, defaultValue = '--', format = 'MM-DD-YYYY', helperText, ...rest } = this.props;

        if (!value) {
            return defaultValue;
        }

        return (
            <ReactMoment format={format} {...rest}>
                {value}
            </ReactMoment>
        );
    }
}

export default Date;
