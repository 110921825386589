// @ts-check
import { Grid } from '@mui/material';
import HtmlReactParser from 'html-react-parser';

import Date from 'Lib/components/common/Date';
import formatAmountString from 'Lib/utilities/formatAmountString';
import isEmpty from 'Lib/utilities/isEmpty';

import Card, { Body, DeepLink, Description, Subtitle } from '../CardComponents';

import Account from '~/components/accounting/Account';
/** @param {{result: TransactionBankAdjustmentResult}} param0 */
export default function TransactionBankAdjustment({ result }) {
    const cardType = 'Bank Adjustment';

    const { data, xray, highlights } = result;

    const highlightedAmount = highlights['amount.keyword']?.[0][0];

    const parsedDescription = HtmlReactParser(highlights.description?.[0]?.[0] || '');
    const description = !isEmpty(parsedDescription) ? parsedDescription : data.description;

    return (
        <Card.Parent path={`/accounting/banking/adjustments/${data.bankAdjustmentID}`} cardType={cardType}>
            {data.isVoided ? <Card.Status status={'Voided'} variant="danger" /> : !isEmpty(data.reconciliationID) && <Card.Status status={'Reconciled'} />}
            <Card.Content>
                <Card.Header title={`$${formatAmountString(data.amount.amount)}`} highlight={highlightedAmount} forceHighlight cardType={cardType} />

                <Body>
                    <Subtitle.Parent>
                        <Grid item>
                            <Account id={data.bankAccountID} showNumber={false} />
                        </Grid>
                    </Subtitle.Parent>

                    <Description>
                        <Date value={data.datePosted} />
                        <>
                            , Description:&nbsp;
                            {description}
                        </>
                    </Description>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} highlights={highlights} forceHighlight />
                    </DeepLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
