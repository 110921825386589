import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import queryString from 'query-string';

import AnimatedLogo from 'Lib/assets/AnimatedLogo';

import { loadApplication } from '~/actions/app';
import { root_domain } from '~/agent';
import * as app from '~/services/app';
import * as session from '~/services/session';

const mapStateToProps = state => ({
    app: state.app,
    session: state.session
});

const mapDispatchToProps = {
    loadApplication
};

class Authenticated extends Component {
    state = {
        isAuthenticationFailure: false,
        isAuthenticated: false
    };
    isInitialized = false;

    componentDidMount() {
        const qs = queryString.parse(this.props.location.search);

        if (qs.code) {
            // We are in the authentication loop and need to validate the code and exchange it for an access token.
            session
                .requestToken(qs.code)
                .then(() => {
                    let loginUrl = session.getLoginUrl();

                    if (!loginUrl) {
                        loginUrl = {
                            pathname: '/'
                        };
                    } else {
                        loginUrl = JSON.parse(loginUrl);
                    }

                    this.props.history.push(loginUrl);

                    // Clear the login url after logging in
                    window.localStorage.setItem(session.LOGIN_URL, null);
                })
                .catch(() => {
                    window.location.assign(`https://account${root_domain}/auth/select`);
                });
        } else {
            session.checkForSession();
            // session.refreshToken().catch(() => {
            //     session.login();
            // });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.session.isAuthenticated === false && this.props.session.isAuthenticated === true) {
            app.initialize();
        }

        if (this.props.app.doLogin === true) {
            session.login();
        }
    }

    onLogout = e => {
        e.preventDefault();
        session.logout();
    };

    render() {
        if (this.props.app.hasAuthenticationError) {
            return (
                <Modal isOpen={true}>
                    <ModalHeader>Authorization Failure</ModalHeader>
                    <ModalBody>
                        <p>You do not have access to this portal.</p>
                        <ul>
                            <li>
                                If you are trying to access your owner portal, click <a href="/portals/owner/">here</a>
                            </li>
                            <li>
                                If you are trying to access your resident portal, click <a href="/portals/resident/">here</a>
                            </li>
                            <li>
                                If you are trying to access your vendor portal, click <a href="/portals/vendor/">here</a>
                            </li>
                            <li>
                                If you are trying to access your applicant portal, click <a href="/portals/applicant/">here</a>
                            </li>
                        </ul>
                        <p>if you believe this to be an error, please logout and back in again. If the problem persists please contact support.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.onLogout}>
                            Logout
                        </Button>
                    </ModalFooter>
                </Modal>
            );
        }

        if (!this.props.session.isAuthenticated || !this.props.app.isInitialized) {
            return <AnimatedLogo />;
        }

        return this.props.children;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authenticated));
