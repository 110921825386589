// @ts-check

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Box, Icon, List, ListItemButton, ListItemIcon, Stack, styled } from '@mui/material';

import { HistoryTypeInterface } from 'Lib/components/layout/components/history/historyTypes';
import PortalTypes from 'Lib/constants/app/portalTypes';
import { getRecentlyVisited } from 'Lib/utilities/recentlyVisited';
import { Link } from 'react-router-dom';

export default function History() {
    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <ViewTitle>Recently Viewed</ViewTitle>
            </Stack>
            <HistoryList />
        </>
    );
}

function ViewTitle({ children }) {
    return <Box sx={{ fontSize: '10px', fontStyle: 'normal', fontWeight: 400, lineHeight: '18px', letterSpacing: '1.5px', textTransform: 'uppercase' }}>{children}</Box>;
}

function HistoryList() {
    const { account, user } = useSelector(state => state.app);

    const historyKey = `${PortalTypes.Manager}_${account.accountID}_${user.userID}`;
    const recentlyViewed = getRecentlyVisited(historyKey);

    return (
        <List>
            {recentlyViewed.map((item, index) => {
                const { icon, title } = HistoryTypeInterface[item.historyTypeID] || {};

                return (
                    <ListItemButton sx={{ rowGap: 2, borderRadius: '10px' }} key={index} className="global-search_primary-link" to={item.path} replace component={Link}>
                        <Stack direction="row" flexWrap="wrap" sx={{ flex: '1 0', maxWidth: '100%', fontSize: '14px' }} alignItems="center">
                            <Stack direction="row" gap={2} sx={{ flex: '0 0 180px' }}>
                                <ListItemIcon>
                                    <Icon>{icon}</Icon>
                                </ListItemIcon>
                                <Box sx={{ fontWeight: '500' }}>{title}</Box>
                            </Stack>
                            <Box sx={{ display: 'inline-flex', flex: '1 1 250px', minWidth: '0px' }}>
                                <LinkButton>{item.label}</LinkButton>
                                &nbsp;
                                {item.content && <LinkButton sx={item.contentSx}>{item.content}</LinkButton>}
                            </Box>
                        </Stack>
                    </ListItemButton>
                );
            })}
        </List>
    );
}

const LinkButton = styled(Box)(() => ({
    color: 'var(--Blue-300, #288DEA)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textDecorationLine: 'underline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
}));
