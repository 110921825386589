import * as Sentry from '@sentry/react';

const localStorage = (key, initialValue) => {
    try {
        if (typeof window === 'undefined') return;

        const storedValue = window.localStorage.getItem(key);
        const parsedValue = storedValue ? JSON.parse(storedValue) : initialValue;

        const setValue = value => {
            try {
                const valueToStore = value instanceof Function ? value(parsedValue) : value;
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                Sentry.captureException(`setLocalStorage error ${error}`);
            }
        };

        return [parsedValue, setValue];
    } catch (error) {
        Sentry.captureException(`setLocalStorage error ${error}`);

        return [initialValue, value => {}];
    }
};

export default localStorage;
