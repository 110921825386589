// @ts-check

import { formatPhoneNumber } from 'react-phone-number-input';

import Card, { Body, DeepLink, ObjectLink, Subtitle } from '../CardComponents';

/** @param {{result: AssociationResult}} param0 */
export default function Association({ result }) {
    const cardType = 'HOA';

    const { data, xray, highlights } = result;
    const {
        'address.full.shingled': highlightedAddressShingled,
        'address.streetNumber': highlightedStreetNumber,
        'address.streetName': highlightedStreetName,
        'address.address2': highlightedAddress2,
        'address.city': highlightedCity,
        'address.state': highlightedState,
        'address.postalCode': highlightedPostalCode
    } = highlights;

    const highlightedName = highlights.name?.[0][0] || highlights['name.shingled']?.[0][0];
    const highlightedEmail = highlights.email?.[0]?.[0];
    const highlightPhone = highlights.phone?.[0]?.[0];

    const highlightedAddress =
        highlightedAddressShingled?.[0][0] ??
        [
            highlightedStreetNumber?.[0][0] || data.address.streetNumber,
            highlightedStreetName?.[0][0] || data.address.streetName,
            highlightedAddress2?.[0][0] || data.address.address2,
            highlightedCity?.[0][0] || data.address.city,
            highlightedState?.[0][0] || data.address.state,
            highlightedPostalCode?.[0][0] || data.address.postalCode
        ]
            .filter(Boolean)
            .join(' ');

    return (
        <Card.Parent path={`/associations/${data.contactID}`} cardType={cardType}>
            <Card.Status status={data.isActive ? 'Active' : 'Inactive'} />
            <Card.Content>
                <Card.Header title={data.name} highlight={highlightedName} cardType={cardType} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item value={data.address.full} highlight={highlightedAddress} />
                    </Subtitle.Parent>
                    <DeepLink.Parent>
                        {data.propertyCount >= '1' && <DeepLink.Item path={`/associations/${data.contactID}?page=1&tab=properties`} value={`${data.propertyCount} Properties`} />}
                        <DeepLink.Xrays xrays={xray} highlights={highlights} />
                    </DeepLink.Parent>
                    <ObjectLink.Parent columns={2}>
                        <ObjectLink.Title value="Email" />
                        <ObjectLink.Title value="Phone" />

                        <ObjectLink.Item path={`/associations/${data.contactID}?tab=messages&composeEmail=true`} value={data.email} highlight={highlightedEmail} />
                        <ObjectLink.Item value={`${formatPhoneNumber(data.phone)}` || '--'} highlight={highlightPhone} forceHighlight />
                    </ObjectLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
