import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Badge, Button, buttonClasses, Icon, ListItemButton, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Box, styled } from '@mui/system';

import useConfig from 'Lib/hooks/api/useConfig';
import CurrentAccount from 'Lib/muiComponents/dev/CurrentAccount';
import isEmpty from 'Lib/utilities/isEmpty';

import Submenu from './components/Submenu';
import User from './components/user/User';

const MenuButton = styled(IconButton)(({ theme }) => ({
    width: '48px',
    height: '48px',
    marginLeft: theme.spacing(4),
    span: {
        fontSize: 32,
        color: theme.palette.common.black
    }
}));

export default function Drawer({
    app,
    matchExact = false,
    full,
    items,
    badgeCounts = {},
    portalAccounts,
    portalTypeID,
    search,
    session,
    toggleDrawer,
    handleClick,
    onChangeLease,
    onChangePortal,
    onChangePortfolio
}) {
    const location = useLocation();

    const {
        portal: { isManager }
    } = useConfig();

    const NavigationButton = ({ badgeID, external, icon, label, path, submenu, submenuItems = [] }) => {
        const selected = !!matchPath(location.pathname, { path, exact: matchExact });

        return submenu ? (
            <Submenu icon={icon} label={label} submenuItems={submenuItems} full={full} handleClick={handleClick} />
        ) : (
            <ListItemButton
                disableGutters
                variant="nav"
                to={path}
                selected={selected}
                component={external ? 'div' : Link}
                sx={{ ...(full && { width: '100%', ml: 0 }) }}
                onClick={() => handleClick(path, external, full)}
            >
                <ListItemIcon variant="regular" sx={{ ml: full ? 2 : selected ? 2 : 4 }}>
                    <Icon>{icon}</Icon>
                </ListItemIcon>
                <ListItemText primary={label} />
                <Badge color="primary" badgeContent={badgeCounts[badgeID]} sx={theme => ({ '& .BaseBadge-badge': { mr: 5, color: `${theme.palette.common.white} !important` } })} />
            </ListItemButton>
        );
    };

    const DrawerHeader = () => {
        if (full) {
            return (
                <Box sx={{ display: 'flex', bgcolor: 'common.background', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={toggleDrawer}
                        sx={{
                            mt: 4,
                            mr: 2,
                            borderRadius: '8px',
                            color: 'success.main',
                            textTransform: 'capitalize',
                            fontWeight: 'regular',
                            [`& .${buttonClasses.endIcon}`]: { alignItems: 'center' },
                            '& .material-symbols-rounded': theme => ({ fontSize: `${theme.typography.pxToRem(24)} !important` })
                        }}
                        endIcon={<span className="material-symbols-rounded">menu_open</span>}
                    >
                        Close Menu
                    </Button>
                </Box>
            );
        }
    };

    // components represents items within the NavigationItems constants that are unique components (like Search, Notifications, and Quick Actions)
    const components = items.filter(item => item.Component);
    // listItems represents everything else that is in our navigable
    const listItems = items.filter(item => !item.Component);

    const [searchButton, ...buttons] = components;

    const renderDrawerButtons = () => {
        return (
            <Box sx={{ ...(!isEmpty(components) && { mt: 5, mx: 4 }) }}>
                {searchButton && <searchButton.Component search={search} />}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                    {buttons.map(({ Component }, index) => (
                        <Component key={index} />
                    ))}
                </Box>
            </Box>
        );
    };

    return (
        <>
            <DrawerHeader />
            <List
                component="nav"
                sx={{
                    display: 'flex',
                    height: '100%',
                    overflowY: 'auto',
                    bgcolor: 'navigation.background.main',
                    my: 2,
                    ml: 2,
                    py: 1
                }}
            >
                <Box sx={{ width: '100%', whiteSpace: 'break-spaces', mr: 4, ...(full && { width: '372px' }) }}>
                    {listItems.map(({ badgeID, external, icon, label, path, items: submenuItems, submenu }, index) => {
                        const navigationProps = { badgeID, external, icon, label, path, submenu, submenuItems };

                        return <NavigationButton key={index} {...navigationProps} />;
                    })}
                </Box>
            </List>
            {isManager ? (
                <CurrentAccount />
            ) : (
                <User
                    open={true}
                    full={full}
                    app={app}
                    portalAccounts={portalAccounts}
                    portalTypeID={portalTypeID}
                    session={session}
                    toggleDrawer={toggleDrawer}
                    onChangeLease={onChangeLease}
                    onChangePortfolio={onChangePortfolio}
                    onChangePortal={onChangePortal}
                />
            )}
        </>
    );
}
