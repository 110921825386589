// routes.js
import Loader from 'Lib/utilities/Loader';

import accountingRoutes from './accounting';
import billing from './company-invoices';
import contactRoutes from './contacts';
import dashboardRoutes from './dashboards';
import documentRoutes from './documents';
import importRoutes from './imports';
import leaseRoutes from './leases';
import maintenanceRoutes from './maintenance';
import marketingRoutes from './marketing';
import portfolioRoutes from './portfolios';
import propertyRoutes from './properties';
import reportRoutes from './reports';
import screeningRoutes from './screening';
import settingRoutes from './settings';

const routes = [
    {
        path: '/',
        component: Loader(() => import('~/containers/dashboard/Index'))
    },
    ...dashboardRoutes,
    ...propertyRoutes,
    ...marketingRoutes,
    ...leaseRoutes,
    ...screeningRoutes,
    ...accountingRoutes,
    ...contactRoutes,
    ...maintenanceRoutes,
    ...reportRoutes,
    ...settingRoutes,
    ...portfolioRoutes,
    ...documentRoutes,
    ...billing,
    ...importRoutes
];

export default routes;
