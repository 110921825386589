import Address from 'Lib/components/common/Address';

import Emails from './items/Emails';
import Phones from './items/Phones';
import { ResultContainer, ResultExpanded, ResultFooter, ResultItem, ResultTitle } from './Result';

export default function TenantResult(props) {
    const { emails, leases, phones } = props.meta;
    const path = `/contacts/tenants/${props.objectID}`;

    return (
        <ResultContainer disableExpand={leases ? false : true} {...props}>
            <ResultTitle icon="diversity_3" label={props.title} indexTypeID={props.indexTypeID} expanded={props.expanded} to={path} />
            {leases
                ? leases.map((lease, index) => (
                      <ResultItem key={index} to={`/leases/${lease.leaseID}`}>
                          <Address value={lease} showCountry={false} maxLength={55} displayInline />
                      </ResultItem>
                  ))
                : null}
            <ResultExpanded expanded={props.expanded}>
                <Phones value={phones} />
                <Emails value={emails} />
            </ResultExpanded>
            <ResultFooter
                to={path}
                actions={[
                    { icon: 'mail', label: 'Email', to: `${path}/?tab=messages&composeEmail=true` },
                    { icon: '3p', label: 'Text', to: `${path}/?tab=messages` }
                ]}
            />
        </ResultContainer>
    );
}
