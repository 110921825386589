class DataTable {
    constructor(data, getId) {
        if (!Array.isArray(data)) {
            return;
        }
        this.map = {};
        this.data = data;
        this.getId = getId;

        this.init();
    }

    init = () => {
        this.data.forEach((item, index) => {
            this.map[`${this.getId(item)}`] = index;
        });
    };

    getById = id => {
        if (this.map[`${id}`] == null) {
            return false;
        }

        return this.data[this.map[`${id}`]];
    };

    getByIndex = index => {
        if (!this.data[index]) {
            return false;
        }

        return this.data[index];
    };

    refresh = data => {
        this.data = data;
        this.init();
    };

    [Symbol.iterator]() {
        return this.data;
    }
}
export default DataTable;
