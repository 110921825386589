import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Box } from '@mui/system';
import moment from 'moment';

import Months from 'Lib/constants/app/months';
import ProrateMethodTypes from 'Lib/constants/settings/prorateMethodTypes';

import Currency from '../common/Currency';
import Date from '../common/Date';

export default function Prorate({ prorate }) {
    const startDate = moment(prorate.startDate);
    const endDate = moment(prorate.endDate);

    const month = Months.getProperty(startDate.format('M'), 'abbreviation');
    const year = startDate.format('Y');

    const endMonth = Months.getProperty(endDate.format('M'), 'abbreviation');
    const endYear = endDate.format('Y');

    const ddStyles = {
        display: 'flex',
        justifyContent: 'space-between'
    };

    return (
        <>
            <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
                <div>
                    Prorated Amount:{' '}
                    <strong>
                        <Currency value={prorate.amount} />
                    </strong>
                </div>
                <br />
                <em>
                    Prorated Date Range:{' '}
                    <strong>
                        <Date value={prorate.startDate} />
                    </strong>{' '}
                    -{' '}
                    <strong>
                        <Date value={prorate.endDate} />
                    </strong>
                </em>
            </div>

            <Row style={{ border: '1px solid lightgray', fontSize: '0.85em', margin: 8, padding: '20px 0 0', borderRadius: 8, opacity: 0.7, background: 'aliceblue' }}>
                {!prorate.spanMonths && <Col md={3} />}
                <Col md={6}>
                    {prorate.spanMonths && (
                        <Box sx={{ fontWeight: 'bold', pb: 2 }}>
                            <Date value={startDate} /> to <Date value={prorate.lastDayOfMonth} />
                        </Box>
                    )}
                    <dl>
                        <dd style={ddStyles}>
                            Days ({month}): <strong>{prorate.daysInMonth}</strong>
                        </dd>
                        {prorate.prorateMethodID == ProrateMethodTypes.ThirtyDays && (
                            <dd style={ddStyles}>
                                Proratable Days ({month}): <strong>30</strong>
                            </dd>
                        )}
                        {prorate.prorateMethodID == ProrateMethodTypes.Annual && (
                            <dd style={ddStyles}>
                                Days ({year}): <strong>{prorate.daysInYear}</strong>
                            </dd>
                        )}
                        <dd style={ddStyles}>
                            Amount / Day ({month}):{' '}
                            <strong>
                                <Currency decimalScale={4} value={prorate.dailyAmount} />
                            </strong>
                        </dd>
                        <dd style={ddStyles}>
                            Billable Days ({month}): <strong>{prorate.billableDaysInMonth}</strong>
                        </dd>
                        <dd style={ddStyles}>
                            Prorated Amount ({month}):{' '}
                            <strong>
                                <Currency decimalScale={4} value={prorate.prorateAmount} />
                            </strong>
                        </dd>
                    </dl>
                </Col>
                {prorate.spanMonths && (
                    <Col md="6">
                        {prorate.spanMonths && (
                            <Box sx={{ fontWeight: 'bold', pb: 2 }}>
                                <Date value={prorate.firstDayOfMonth2} /> to <Date value={endDate} />
                            </Box>
                        )}
                        <dl>
                            <dd style={ddStyles}>
                                Days ({endMonth}): <strong>{prorate.daysInMonth2}</strong>
                            </dd>
                            {prorate.prorateMethodID == ProrateMethodTypes.ThirtyDays && (
                                <dd style={ddStyles}>
                                    Proratable Days ({endMonth}): <strong>30</strong>
                                </dd>
                            )}
                            {prorate.prorateMethodID == ProrateMethodTypes.Annual && (
                                <dd style={ddStyles}>
                                    Days ({endYear}): <strong>{prorate.daysInYear2}</strong>
                                </dd>
                            )}
                            <dd style={ddStyles}>
                                Amount / Day ({endMonth}):{' '}
                                <strong>
                                    <Currency decimalScale={4} value={prorate.dailyAmount2} />
                                </strong>
                            </dd>
                            <dd style={ddStyles}>
                                Billable Days ({endMonth}): <strong>{prorate.billableDaysInMonth2}</strong>
                            </dd>
                            <dd style={ddStyles}>
                                Prorated Amount ({endMonth}):{' '}
                                <strong>
                                    <Currency decimalScale={4} value={prorate.prorateAmount2} />
                                </strong>
                            </dd>
                        </dl>
                    </Col>
                )}
                {!prorate.spanMonths && <Col md={3} />}
            </Row>
        </>
    );
}
