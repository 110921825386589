// @ts-check
import Enumeration from 'Lib/utilities/Enumeration';

const IndexTypes = new Enumeration([
    // Searchable Indexes
    { id: 1, key: 'application', name: 'Application', isSearchable: true, isGlobalSearchIndex: true, icon: 'article' },
    { id: 2, key: 'association', name: 'Association', isSearchable: true, isGlobalSearchIndex: true, icon: 'groups' },
    { id: 3, key: 'bank_account', name: 'Bank Account', isSearchable: true, isGlobalSearchIndex: true, icon: 'account_balance' },
    { id: 4, key: 'lease', name: 'Lease', isSearchable: true, isGlobalSearchIndex: true, icon: 'assignment' },
    { id: 5, key: 'portfolio', name: 'Portfolio', isSearchable: true, isGlobalSearchIndex: true, icon: 'folder_copy' },
    { id: 6, key: 'property', name: 'Property', isSearchable: true, isGlobalSearchIndex: true, icon: 'home_work' },
    { id: 7, key: 'report', name: 'Report', isSearchable: true, isGlobalSearchIndex: true, icon: 'monitoring' },
    { id: 8, key: 'saved_report', name: 'Saved Report', isSearchable: true, isGlobalSearchIndex: true, icon: 'monitoring' },
    { id: 9, key: 'unit', name: 'Unit', isSearchable: true, isGlobalSearchIndex: true, icon: 'apartment' },
    { id: 10, key: 'vendor', name: 'Vendor', isSearchable: true, isGlobalSearchIndex: true, icon: 'real_estate_agent' },
    { id: 11, key: 'work_order', name: 'Work Order', isSearchable: true, isGlobalSearchIndex: true, icon: 'receipt_long' },
    { id: 12, key: 'work_order_project', name: 'Work Order Project', isSearchable: true, isGlobalSearchIndex: true, icon: 'receipt_long' },
    { id: 13, key: 'navigation', name: 'Navigation', isSearchable: true, isGlobalSearchIndex: true, icon: 'detection_and_zone' },
    { id: 14, key: 'bank_transfer', name: 'Bank Transfer', isSearchable: true, isGlobalSearchIndex: true, icon: 'attach_money' },
    { id: 15, key: 'journal_entry', name: 'Journal Entry', isSearchable: true, isGlobalSearchIndex: true, icon: 'attach_money' },
    { id: 16, key: 'bill', name: 'Bill', isSearchable: true, isGlobalSearchIndex: true, icon: 'attach_money' },
    { id: 17, key: 'receipt', name: 'Receipt', isSearchable: true, isGlobalSearchIndex: true, icon: 'attach_money' },
    { id: 18, key: 'settlement', name: 'Settlement', isSearchable: true, isGlobalSearchIndex: true, icon: 'attach_money' },
    { id: 19, key: 'deposit', name: 'Deposit', isSearchable: true, isGlobalSearchIndex: true, icon: 'attach_money' },
    { id: 20, key: 'ledger_transfer', name: 'Ledger Transfer', isSearchable: true, isGlobalSearchIndex: true, icon: 'attach_money' },
    { id: 21, key: 'payment', name: 'Payment', isSearchable: true, isGlobalSearchIndex: true, icon: 'attach_money' },
    { id: 22, key: 'bank_adjustment', name: 'Bank Adjustment', isSearchable: true, isGlobalSearchIndex: true, icon: 'attach_money' },
    { id: 23, key: 'accounting_account', name: 'Accounting Account', isSearchable: true, isGlobalSearchIndex: false, icon: 'attach_money' },
    { id: 24, key: 'ledger', name: 'Ledger', isSearchable: true, isGlobalSearchIndex: false, icon: 'attach_money' },
    // Non-Searchable Indexes (related)
    { id: 25, key: 'owner', name: 'Owner', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 26, key: 'tenant', name: 'Tenant', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 27, key: 'manager', name: 'Manager', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 28, key: 'tag', name: 'Tag', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 29, key: 'tag_object', name: 'Tag Object', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 30, key: 'contact_phone', name: 'Contact Phone', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 31, key: 'contact_email', name: 'Contact Email', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 32, key: 'applicant', name: 'Applicant', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 33, key: 'vendor_trade', name: 'Vendor Trade', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 34, key: 'contact_vendor_trade', name: 'Contact Vendor Trade', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 35, key: 'vendor_type', name: 'Vendor Type', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 36, key: 'portfolio_owner', name: 'Portfolio Owner', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 37, key: 'property_association', name: 'Property Association', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 38, key: 'occupant', name: 'Occupant', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 39, key: 'vehicle', name: 'Vehicle', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 40, key: 'emergency_contact', name: 'Emergency Contact', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 41, key: 'lease_tenant', name: 'Lease Tenant', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 42, key: 'property_portfolio', name: 'Property Portfolio', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 43, key: 'property_feature', name: 'Property Feature', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 44, key: 'property_custom_feature', name: 'Property Custom Feature', isSearchable: false, isGlobalSearchIndex: false, icon: null },
    { id: 45, key: 'payment_batch', name: 'Payment Batch', isSearchable: false, isGlobalSearchIndex: false, icon: null }
]);

/** @type {ChipFilter[]} filterChips */
export const filterChips = Object.values(IndexTypes.list)
    .filter(item => item.isSearchable && item.isGlobalSearchIndex)
    .map((/** @type {IndexType} item */ item) => {
        return { value: item.key, label: item.name, resultCount: 0 };
    });

/** @type {IndexType[]} searchableIndexes */
export const searchableIndexes = Object.values(IndexTypes.list).filter(item => item.isSearchable);

export default IndexTypes;
