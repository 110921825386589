import { connect } from 'react-redux';

import PaperContainer from 'Lib/components/layout/components/PaperContainer';

const mapStateToProps = state => ({
    ui: state.ui
});

const App = ({ children, component, route, ui }) => {
    const pinned = !!ui.pinnedContentTypeID;

    if (component) {
        return (
            <div>
                <div className="main">
                    <PaperContainer open={ui.menuVisible}>
                        <component route={route} />
                    </PaperContainer>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="main" style={{ display: pinned ? 'flex' : 'block' }}>
                <PaperContainer open={ui.menuVisible}>{children}</PaperContainer>
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(App);
