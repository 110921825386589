/**
 * Checks if `value` is `null` or `undefined`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is nullish, else `false`.
 * @example
 *
 * _.isNil(null);
 * returns true
 *
 * _.isNil(void 0);
 * returns true
 *
 * _.isNil(NaN);
 * returns false
 */
export const isNil = value => value == null;

export default isNil;
