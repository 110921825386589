import { useEffect } from 'react';

import cancelablePromise from '../utilities/cancelablePromise';

function useApi(promise, ...rest) {
    let pendingPromises = [];

    if (!promise) return;

    const apiCall = (...args) => {
        const pendingPromise = promise(...rest, ...args);
        const wrappedPromise = cancelablePromise(pendingPromise);

        wrappedPromise.finally(() => {
            pendingPromises = pendingPromises.filter(p => p !== wrappedPromise);
        });

        pendingPromises.push(wrappedPromise);

        return wrappedPromise;
    };

    useEffect(() => {
        return () => pendingPromises.map(p => p.cancel());
    }, [promise]);

    return apiCall;
}

export default useApi;
