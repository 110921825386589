import tryParseJson from './tryParseJson';

/* 
    Get history of last N visited pages (5 by default). This is used in the side navigation in History.js as well
    as in the header of a page in PageHeader.js
*/
export const getRecentlyVisited = (historyKey, historyTypeID, length) => {
    const localStorageHistory = tryParseJson(localStorage.getItem('history') || {});
    let recentlyViewed = localStorageHistory[historyKey] || [];

    // In the context of PageHeader, we only want to show history object related to the page the User is on.
    // For instance, on the Properties View, we only want the dropdown to show the ~5 most recently visited Properties
    if (historyTypeID) {
        recentlyViewed = recentlyViewed.filter(item => item.historyTypeID == historyTypeID);
    }

    // When on a View page, we currently only want to show the 5 most recently visited pages
    if (length) {
        recentlyViewed = recentlyViewed.slice(0, length);
    }

    return recentlyViewed;
};
