/**
 * Given a name parse the initials (First Letter of each Name) up to a limit.
 * For example Jim Dubs would result in JD
 * @param {Name to parse} name
 * @returns
 */

const parseInitials = (name, limit = 4) => {
    if (!name) return;

    const initials = [];
    const nameParts = name.split(' ');

    nameParts.forEach(substr => {
        if (!substr || substr.trim().length == 0) return;
        initials.push(substr[0].toUpperCase());
    });

    return initials.slice(0, limit).join('');
};

export default parseInitials;
