import { Box, Button } from '@mui/material';

export default function Footer(props) {
    return props.children ? (
        props.children
    ) : (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box>
                {props.clearable && (
                    <Button variant="tertiary" aria-label="clear" onClick={() => props.onClear}>
                        Clear
                    </Button>
                )}
            </Box>
            <Box>
                {props.onSubmit ? (
                    <>
                        <Button variant="secondary" aria-label="cancel" onClick={() => props.onCancel} sx={{ mr: 4 }}>
                            Cancel
                        </Button>
                        <Button variant="primary" aria-label="update" type="submit" onClick={props.onSubmit} disabled={props.disabled}>
                            Update
                        </Button>
                    </>
                ) : (
                    <Button variant="secondary" aria-label="cancel" onClick={props.onClose}>
                        Close
                    </Button>
                )}
            </Box>
        </Box>
    );
}
