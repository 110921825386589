// @ts-check
import { useContext } from 'react';
import { CardHeader, Icon, IconButton, Stack, Typography } from '@mui/material';

import isEmpty from 'Lib/utilities/isEmpty';

import { NotificationsContext } from '../context';

export default function NotificationTitle() {
    const { state, dispatch } = useContext(NotificationsContext);

    return (
        <CardHeader
            sx={{
                p: 3,
                '& .material-symbols-rounded': { fontSize: theme => theme.typography.pxToRem(24) }
            }}
            title={
                !isEmpty(state.selectedNotification) ? (
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
                        <IconButton onClick={() => dispatch({ type: 'CLEAR_SELECTED_NOTIFICATION' })}>arrow_back</IconButton>
                        <Typography component="h2" sx={{ fontSize: theme => theme.typography.pxToRem(24), lineHeight: '22px' }}>
                            All Notifications
                        </Typography>
                    </Stack>
                ) : (
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
                        <IconButton disableRipple>notifications</IconButton>
                        <Typography sx={{ fontSize: theme => theme.typography.pxToRem(24), lineHeight: '22px' }}>Notifications</Typography>
                    </Stack>
                )
            }
            action={
                state.view === 'popover' && (
                    <IconButton onClick={() => dispatch({ type: 'CLOSE_VIEW' })} size="small">
                        <Icon>close</Icon>
                    </IconButton>
                )
            }
        />
    );
}
