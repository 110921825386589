import { Avatar, IconButton, ListItemButton, ListItemText, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const UserContainer = styled(Box, { shouldForwardProp: prop => prop !== 'full' })(({ theme, open, full }) => ({
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(full ? 8 : 4),
    backgroundColor: theme.palette.common.background,
    ...(open && {
        borderRadius: '12px',
        marginBottom: theme.spacing(6),
        padding: 1,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 1px 2px #B6C7D6'
    })
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: theme.palette.navigation.background.selected,
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(16)
}));

const Username = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(3),
    lineHeight: '24px',
    letterSpacing: '0.5px'
}));

const UserInfo = styled(Box)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '16px',
    letterSpacing: '0.4px',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
}));

const EllipsesButton = styled(IconButton)(() => ({
    width: '48px',
    height: '48px'
}));

const UserOptionButton = styled(ListItemButton)(({ theme }) => ({ borderRadius: 0, span: { width: '100%' }, '& .MuiTypography-root': { marginLeft: theme.spacing(2) } }));

const SelectableListItemText = styled(ListItemText)(({ theme, selected }) => ({ marginLeft: selected ? theme.spacing(3) : theme.spacing(9) }));

const Info = styled(Typography)(({ theme }) => ({ overflow: 'hidden', minWidth: '100%', fontSize: theme.typography.pxToRem(12) }));

export { EllipsesButton, Info, SelectableListItemText, UserAvatar, UserContainer, UserInfo, Username, UserOptionButton };
