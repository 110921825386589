import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Icon, styled, useMediaQuery } from '@mui/material';
import * as actions from 'ROOT/apps/manager/src/services/ui/actions';

const HeaderButton = styled(Button)(({ theme }) => ({
    borderRadius: 8,
    color: theme.palette.success.main,
    textTransform: 'capitalize',
    fontWeight: 'regular',
    '& .material-symbols-rounded': { fontSize: '24px !important' }
}));

export default function Header(props) {
    const dispatch = useDispatch();
    const { isDrawerOpen, pinnedContentTypeID } = useSelector(state => state.ui);
    const pinnable = useMediaQuery(theme => theme.breakpoints.up('xl'));

    const pinned = !isDrawerOpen && pinnedContentTypeID;

    const handlePin = () => {
        dispatch(actions.togglePinnedDrawer());
    };

    return props.children ? (
        props.children
    ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {pinned ? (
                <HeaderButton aria-label="unpin" onClick={handlePin} endIcon={<Icon>push_pin</Icon>}>
                    Unpin
                </HeaderButton>
            ) : (
                <>
                    {pinnable && (
                        <HeaderButton aria-label="pin" onClick={handlePin} endIcon={<Icon>push_pin</Icon>}>
                            Pin
                        </HeaderButton>
                    )}
                    <HeaderButton aria-label="close" onClick={() => dispatch(actions.toggleDrawer())} endIcon={<Icon>close</Icon>}>
                        Close
                    </HeaderButton>
                </>
            )}
        </Box>
    );
}
