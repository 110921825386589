import { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Form from 'Lib/components/form/Form';
import SavingButton from 'Lib/components/form/SavingButton';

class FormModal extends Component {
    state = {
        confirmText: ''
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({
                confirmText: ''
            });
        }
    }

    onSubmit = (values, form) => {
        if (form.isSubmitting) return;

        if (this.props.onSubmit) {
            this.props.onSubmit(values, form);
        }
    };

    validate = values => {
        if (this.props.validate) {
            return this.props.validate(values);
        }
    };

    toggle = form => {
        if (form.isSubmitting) return;

        if (this.props.toggle) {
            this.props.toggle();
        }
    };

    renderBody = form => {
        if (this.props.render) {
            return this.props.render(form);
        }

        return this.props.children;
    };

    renderButtons = form => {
        const { confirmText } = this.state;
        const { renderButtons, renderConfirm } = this.props;

        if (renderButtons) {
            return renderButtons(form);
        }

        const {
            savingColor = 'primary',
            savingText = 'Saving...',
            submitText = 'Save',
            submitColor = 'primary',
            submitDisabled = renderConfirm ? (confirmText.toLowerCase() === 'delete' ? false : true) : false,
            submitOutline = false,
            closeText = 'Cancel',
            closeColor = 'secondary',
            closeOutline = false
        } = this.props;

        return form.isSubmitting ? (
            <SavingButton color={savingColor} text={savingText} />
        ) : (
            <>
                <Button data-cy="form-modal-submit" type="submit" color={submitColor} outline={submitOutline} disabled={submitDisabled}>
                    {submitText}
                </Button>
                <Button data-cy="form-modal-cancel" type="button" color={closeColor} outline={closeOutline} onClick={this.toggle}>
                    {closeText}
                </Button>
            </>
        );
    };

    renderAfterForm = () => {
        if (this.props.renderAfterForm) {
            return this.props.renderAfterForm();
        }
    };

    render() {
        const { isOpen = false, size, title, initialValues = {}, stickyFooter, styleOverride } = this.props;
        const stickyFooterStyle = { position: 'sticky', bottom: '0', background: 'white' };

        return (
            <Modal isOpen={isOpen} size={size} toggle={this.toggle} style={styleOverride} zIndex={1200}>
                <Form
                    {...this.props}
                    initialValues={{ ...initialValues }}
                    onClose={this.toggle}
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    render={form => (
                        <>
                            <form onSubmit={form.handleSubmit}>
                                <ModalHeader toggle={() => this.toggle(form)}>{title}</ModalHeader>
                                <ModalBody>{this.renderBody(form)}</ModalBody>
                                <ModalFooter style={stickyFooter ? stickyFooterStyle : null}>{this.renderButtons(form)}</ModalFooter>
                            </form>
                            {this.renderAfterForm()}
                        </>
                    )}
                />
            </Modal>
        );
    }
}

export default FormModal;
