// @ts-check
import HtmlReactParser from 'html-react-parser';

import Date from 'Lib/components/common/Date';
import formatAmountString from 'Lib/utilities/formatAmountString';
import isEmpty from 'Lib/utilities/isEmpty';

import Card, { Body, DeepLink, Description, Subtitle } from '../CardComponents';

/** @param {{result: TransactionBillResult}} param0 */
export default function TransactionBill({ result }) {
    const cardType = 'Bill';

    const { data, xray, highlights } = result;

    const highlightedAmount = highlights['amount.keyword']?.[0][0];
    const highlightedPayeeContactName = highlights['payeeContact.name']?.[0]?.[0] || highlights['payeeContact.name.shingled']?.[0]?.[0];

    const parsedReference = HtmlReactParser(highlights.reference?.[0]?.[0] || highlights['reference.alphanumeric']?.[0]?.[0] || '');
    const reference = !isEmpty(parsedReference) ? parsedReference : data.reference;

    const parsedPayeeContactName = HtmlReactParser(highlightedPayeeContactName || '');
    const payeeContactName = !isEmpty(parsedPayeeContactName) ? parsedPayeeContactName : data.payeeContact.name;

    const subtype = data.isDiscount ? 'Discount' : data.isMarkup ? 'Markup' : '';

    return (
        <Card.Parent path={`/accounting/payables/bills/${data.billID}`} cardType={cardType}>
            {data.isVoided && <Card.Status status="Voided" variant="danger" />}
            <Card.Content>
                <Card.Header title={`$${formatAmountString(data.amount.amount)}`} highlight={highlightedAmount} forceHighlight cardType={cardType} subtype={subtype} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item>
                            <Date value={data.billDate} />
                            {!isEmpty(reference) && (
                                <>
                                    , Reference #:&nbsp;
                                    {reference}
                                </>
                            )}
                        </Subtitle.Item>
                    </Subtitle.Parent>

                    <Description>
                        <>
                            Payee:&nbsp;
                            {payeeContactName}
                        </>
                    </Description>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} highlights={highlights} forceHighlight />
                    </DeepLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
