import { refresh, request } from '.';

import { getAccounts, getBankAccounts } from '~/api/accounting/accounts';

export const AccountsCacheKey = 'accounting/Accounts';
export const BankAccountsCacheKey = 'accounting/BankAccounts';

export const requestAccounts = () => {
    return request(AccountsCacheKey, getAccounts, item => item.account.accountID);
};

export const refreshAccounts = () => {
    return refresh(
        AccountsCacheKey,
        () => getAccounts({ isActive: 1 }),
        item => {
            return item.account.accountID;
        }
    );
};

export const requestBankAccounts = () => {
    return request(BankAccountsCacheKey, getBankAccounts, item => {
        return item.account.accountID;
    });
};

export const refreshBankAccounts = () => {
    return refresh(
        BankAccountsCacheKey,
        () => getBankAccounts({ isActive: 1 }),
        item => {
            return item.account.accountID;
        }
    );
};
