// @ts-check
import HtmlReactParser from 'html-react-parser';

import Date from 'Lib/components/common/Date';
import formatAmountString from 'Lib/utilities/formatAmountString';
import isEmpty from 'Lib/utilities/isEmpty';

import Card, { Body, DeepLink, Description, Subtitle } from '../CardComponents';

import Account from '~/components/accounting/Account';
import SettlementTypes from '~/constants/accounting/settlementTypes';

/** @param {{result: TransactionSettlementsResult}} param0 */
export default function TransactionSettlements({ result }) {
    const cardType = 'Settlement';

    const { data, xray, highlights } = result;

    const amount = formatAmountString(Math.abs(Number(data.amount.amount)).toFixed(2));
    const displayAmount = Number(data.amount.amount) < 0 ? `\u2013$${amount}` : `$${amount}`;

    const highlightedAmount = highlights['amount.keyword']?.[0][0];

    const parsedReference = HtmlReactParser(highlights.reference?.[0]?.[0] || highlights['reference.alphanumeric']?.[0]?.[0] || '');
    const reference = !isEmpty(parsedReference) ? parsedReference : data.reference;

    return (
        <Card.Parent path={`/accounting/banking/settlements/${data.settlementID}`} cardType={cardType}>
            {data.isVoided ? <Card.Status status={'Voided'} variant="danger" /> : !isEmpty(data.reconciliationID) && <Card.Status status={'Reconciled'} />}
            <Card.Content>
                <Card.Header title={displayAmount} highlight={highlightedAmount} forceHighlight cardType={cardType} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item>
                            <Date value={data.datePosted} />
                            {!isEmpty(reference) && (
                                <>
                                    , Reference #:&nbsp;
                                    {reference}
                                </>
                            )}
                        </Subtitle.Item>
                    </Subtitle.Parent>

                    <Description>
                        Bank: <Account id={data.bankAccountID} showNumber={false} />, Type: {SettlementTypes.getName(data.settlementTypeID)}
                    </Description>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} highlights={highlights} />
                    </DeepLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
