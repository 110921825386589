import { SET_HISTORY } from 'Lib/constants/app/actionTypes';
import localStorage from 'Lib/utilities/localStorage';

export default (state = {}, action) => {
    switch (action.type) {
        case SET_HISTORY: {
            const [history, setHistory] = localStorage('history', {
                [action.key]: []
            });
            const uniqueHistory = [action.data, ...(history[action.key] || [])].filter((obj, index, self) => self.findIndex(item => item.path === obj.path) === index).slice(0, 10);

            setHistory({
                [action.key]: uniqueHistory
            });

            return {
                ...state,
                action
            };
        }
        default:
            return state;
    }
};
