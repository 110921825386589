import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/screening/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/screening/templates/Application'))
    },
    // Refunds
    {
        path: '/screening/payments/refunds',
        component: Loader(() => import('~/containers/screening/refunds/List'))
    },
    {
        path: '/screening/payments/refunds/:id([0-9]+)',
        component: Loader(() => import('~/containers/screening/refunds/View'))
    },
    // Chargebacks
    {
        path: '/screening/chargebacks/',
        component: Loader(() => import('~/containers/screening/chargebacks/List'))
    },
    {
        path: '/screening/chargebacks/:id([0-9]+)',
        component: Loader(() => import('~/containers/screening/chargebacks/View'))
    },
    // Applications
    {
        path: '/screening/applications',
        component: Loader(() => import('~/containers/screening/List'))
    },
    {
        path: '/screening/applications/:id([0-9]+)',
        component: Loader(() => import('~/containers/screening/View'))
    },
    {
        path: '/screening/applications/:id([0-9]+)/applicants/:applicantID([0-9]+)',
        component: Loader(() => import('~/containers/settings/screening/templates/Application'))
    },
    {
        path: '/screening/applications/templates/:templateID([0-9]+)',
        component: Loader(() => import('~/containers/settings/screening/templates/Application'))
    },
    // Payments
    {
        path: '/screening/payments',
        component: Loader(() => import('~/containers/screening/payments/List'))
    },
    {
        path: '/screening/payments/:id([0-9]+)',
        component: Loader(() => import('~/containers/screening/payments/View'))
    },
    // Payouts
    {
        path: '/screening/payments/payouts',
        component: Loader(() => import('~/containers/screening/payouts/List'))
    },
    {
        path: '/screening/payments/payouts/:id([0-9]+)',
        component: Loader(() => import('~/containers/screening/payouts/View'))
    },
    // Application statuses
    {
        path: '/settings/screening/application-statuses',
        component: Loader(() => import('~/containers/settings/screening/applicationStatuses/controllers/ApplicationStatusView'))
    },
    // Invitations
    {
        path: '/screening/invitations',
        component: Loader(() => import('~/containers/screening/invitations/viewModels/ListViewModel'))
    },
    {
        path: '/screening/invitations/:id([0-9]+)',
        component: Loader(() => import('~/containers/screening/invitations/viewModels/ViewModel'))
    },
    {
        path: '/screening/invitations/add',
        component: Loader(() => import('~/containers/screening/invitations/viewModels/AddViewModel'))
    }
];

export default routes;
