// @ts-check

import { useContext } from 'react';
import { Button, Icon, Stack, Typography } from '@mui/material';

import isEmpty from 'Lib/utilities/isEmpty';

import { NotificationsContext } from '../context';
import useNotifications, { useUnreadNotifications, useUpdateNotifications } from '../data/useNotifications';

export default function Header({ showingCount = 0 }) {
    const { state } = useContext(NotificationsContext);
    const { markAllAsRead } = useUpdateNotifications();

    const { data: notifications = [], isPending } = useNotifications();
    const unreadNotifications = useUnreadNotifications();

    if (isPending || !isEmpty(state.selectedNotification)) return;

    return (
        <Stack direction="row" gap={2} alignItems="center" alignContent="center" justifyContent="space-between" sx={{ px: 3, pb: 3 }}>
            <Typography
                sx={theme => ({
                    fontSize: theme.typography.pxToRem(16)
                })}
            >
                Showing {showingCount} of {notifications.length}
            </Typography>
            {!isEmpty(unreadNotifications) && (
                <Button
                    sx={theme => ({ color: 'common.black', fontWeight: '400', textTransform: 'none', fontSize: theme.typography.pxToRem(16) })}
                    startIcon={<Icon>done_all</Icon>}
                    onClick={markAllAsRead}
                >
                    Mark all as Read
                </Button>
            )}
        </Stack>
    );
}
