// @ts-check
import Card, { Body, DeepLink, Description } from '../CardComponents';

import { getDescription as getReportDescription, getReportCategoryName, getUrl as getReportUrl } from '~/constants/reports/reportTypes';

/** @param {{result: ReportResult}} param0 */
export default function Report({ result }) {
    const cardType = 'Report';

    const { data, xray, highlights } = result;

    const highlightedName = highlights.name?.[0][0] || highlights['name.shingled']?.[0][0];

    return (
        <Card.Parent path={`/reports${getReportUrl(data.reportTypeID)}`} cardType={cardType}>
            <Card.Content>
                <Card.Header title={data.name} highlight={highlightedName} cardType={cardType} subtype={getReportCategoryName(data.reportTypeID)} />

                <Body>
                    <Description>{getReportDescription(data.reportTypeID)}</Description>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} highlights={highlights} />
                    </DeepLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
