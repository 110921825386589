import { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import { ModalContext } from './Modal';

/**
 *
 * @param {JSX.Element} children optionally provide custom title, otherwise, title will come from Modal Name
 * @returns
 */

export default function ModalTitle({ children, sx }) {
    const { modalName } = useContext(ModalContext);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography variant="4" sx={{ mb: 2, ...sx }}>
                {children || modalName}
            </Typography>
        </Box>
    );
}
