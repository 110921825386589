import { setIn } from 'formik';

import { CACHE_ENTITY, REMOVE_CACHE_ENTITY } from 'Lib/constants/app/actionTypes';

const defaultState = {
    states: {},
    countries: null,
    users: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CACHE_ENTITY:
            if (!action.key) return state;
            return setIn(state, action.key, action.data);
        case REMOVE_CACHE_ENTITY:
            break;
        default:
            return state;
    }
};
