import * as actionTypes from './actionTypes';

/**
 *  Init Action
 */
export const init = app => ({
    type: actionTypes.INIT,
    app
});

/**
 * API Actions
 */
export const startApiCall = () => ({
    type: actionTypes.START_API_CALL
});

export const endApiCall = () => ({
    type: actionTypes.END_API_CALL
});

/**
 * Notify
 */
export const notify = data => ({
    type: actionTypes.NOTIFY,
    notify: data
});

export const dismissSystemAlerts = () => ({
    type: actionTypes.DISMISS_SYSTEM_ALERTS
});
