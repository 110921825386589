// @ts-check
import Card, { Body, DeepLink, Subtitle } from '../CardComponents';

import ReportTypes, { getReportCategoryName, getUrl as getReportUrl } from '~/constants/reports/reportTypes';

/** @param {{result: SavedReportResult}} param0 */
export default function SavedReport({ result }) {
    const cardType = 'Saved Report';

    const { data, xray, highlights } = result;

    const highlightedName = highlights.name?.[0][0] || highlights['name.shingled']?.[0][0];

    return (
        <Card.Parent path={`/reports${getReportUrl(data.reportTypeID)}?reportID=${data.savedReportID}`} cardType={cardType}>
            <Card.Content>
                <Card.Header title={data.name} highlight={highlightedName} cardType={cardType} subtype={getReportCategoryName(data.reportTypeID)} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item value={`Type: ${ReportTypes.getName(data.reportTypeID)}`} />
                    </Subtitle.Parent>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} highlights={highlights} />
                    </DeepLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
