import { Component } from 'react';
import { Link as ReactLink } from 'react-router-dom';

class Link extends Component {
    onClick = e => {
        const { propagate = 'true', onClick } = this.props;

        if (propagate == 'false') {
            e.stopPropagation();
        }

        if (onClick) onClick(e);
    };

    render() {
        const { fitContainer, search, to, ...rest } = this.props;

        return <ReactLink style={fitContainer && { display: 'block', margin: '6px 0' }} to={to} {...rest} onClick={this.onClick} search={search} />;
    }
}

export default Link;
