import { request } from '~/agent';

export const getAccountingSettings = filters => {
    return request.get(`accounting/settings`, { ...filters });
};

export const getShowingProviders = params => {
    return request.get(`property/settings/showing-providers`, params);
};

export const createShowingProvider = (data, params) => {
    return request.post(`property/settings/showing-providers`, data, params);
};

export const saveShowingProvider = (id, data, params) => {
    return request.post(`property/settings/showing-providers/${id}`, data, params);
};

export const deleteShowingProvider = (id, params) => {
    return request.del(`property/settings/showing-providers/${id}`, params);
};

export const updateAccountingSettings = (data, params) => {
    return request.post(`accounting/settings`, data, params);
};

export const getAccounts = filters => {
    return request.get(`accounting/accounts`, { ...filters });
};

export const getUsers = filters => {
    return request.get('users', { ...filters });
};

export const getAllUsers = filters => {
    return request.get('users/all', { ...filters });
};

// Maintenance
export const getMaintenanceSettings = filters => {
    return request.get(`maintenance/settings`, { ...filters });
};

// Custom Field Categories
export const getCustomFieldCategories = params => {
    return request.get(`custom-field-categories`, params);
};

export const getCustomFieldCategory = (id, params) => {
    return request.get(`custom-field-categories/${id}`, params);
};

export const saveCustomFieldCategory = (id, data, params) => {
    if (id) {
        return request.post(`custom-field-categories/${id}`, data, params);
    }
    return request.post(`custom-field-categories`, data, params);
};

export const reorderCustomFieldCategories = (objectTypeID, data, params) => {
    return request.post(`custom-field-categories/${objectTypeID}/order`, data, params);
};

export const reorderCustomFields = (id, data, params) => {
    return request.post(`custom-field-categories/${id}/order-fields`, data, params);
};

export const deleteCustomFieldCategory = (id, params) => {
    return request.del(`custom-field-categories/${id}`, params);
};

// Custom Fields
export const getCustomFields = params => {
    return request.get(`custom-fields`, params);
};

export const getCustomField = (id, params) => {
    return request.get(`custom-fields/${id}`, params);
};

export const saveCustomField = (id, data, params) => {
    if (id) {
        return request.post(`custom-fields/${id}`, data, params);
    }
    return request.post(`custom-fields`, data, params);
};

export const deleteCustomField = (id, params) => {
    return request.del(`custom-fields/${id}`, params);
};

// Check Templates
export const getCheckTemplates = params => {
    return request.get(`checks/templates`, params);
};

export const getCheckTemplate = (id, params) => {
    return request.get(`checks/templates/${id}`, params);
};

export const saveCheckTemplate = (id, data, params) => {
    if (id) {
        return request.post(`checks/templates/${id}`, data, params);
    }
    return request.post(`checks/templates`, data, params);
};

export const deleteCheckTemplate = (id, params) => {
    return request.del(`checks/templates/${id}`, params);
};

export const cloneCheckTemplate = (id, values, params) => {
    return request.post(`checks/templates/${id}/clone`, values, params);
};

// Check Images
export const getCheckImages = params => {
    return request.get(`checks/images`, params);
};

export const saveCheckImage = (checkImageID, data, params) => {
    if (checkImageID) {
        return request.post(`checks/images/${checkImageID}`, data, params);
    }
    return request.post(`checks/images`, data, params);
};

export const deleteCheckImage = (checkImageID, params) => {
    return request.del(`checks/images/${checkImageID}`, params);
};

export const downloadCheckImage = (checkImageID, data, params) => {
    return request.download(`checks/images/${checkImageID}/download`, data, params);
};

export const uploadCheckImage = (checkImageID, params, config) => {
    return request.upload('checks/images', null, checkImageID, params, config);
};

// Email Builder
export const getEmailTemplates = params => {
    return request.get(`emails/templates`, params);
};

export const getEmailTemplate = (id, params) => {
    return request.get(`emails/templates/${id}`, params);
};

export const getEmailTemplateFields = (id, params) => {
    return request.get(`emails/templates/fields/${id}`, params);
};

export const renderEmailTemplate = (id, data, params) => {
    return request.post(`emails/templates/${id}/render`, data, params);
};

export const saveEmailTemplate = (id, data, params) => {
    if (id) {
        return request.post(`emails/templates/${id}`, data, params);
    }
    return request.post(`emails/templates`, data, params);
};

export const deleteEmailTemplate = (id, params) => {
    return request.del(`emails/templates/${id}`, params);
};

// Files
export const getFiles = filters => {
    return request.get(`files`, { ...filters });
};

export const searchFiles = filters => {
    return request.get(`files/search`, { ...filters });
};

export const updateFile = (id, data, params) => {
    return request.post(`files/${id}`, data, params);
};

export const deleteFile = id => {
    return request.del(`files/${id}`);
};

export const getFile = (id, params) => {
    return request.get(`files/${id}`, params);
};

export const uploadFiles = file => {
    return request.upload(`files`, null, file);
};

// Account Manager
export const getManager = params => {
    return request.get(`settings/company`, { ...params });
};

export const updateManager = data => {
    return request.post(`settings/company`, { ...data });
};

export const getAchDetails = params => {
    return request.get(`settings/company/ach-details`, params);
};

export const saveBankInfo = params => {
    return request.post(`settings/company/ach-details`, params);
};

export const getTaxDetails = params => {
    return request.get(`settings/company/tax-details`, params);
};

export const saveTaxInfo = params => {
    return request.post(`settings/company/tax-details`, params);
};

// Notifications
export const getNotificationSettings = params => {
    return request.get(`notification/settings`, params);
};

export const createNotificationSetting = postBody => {
    return request.post(`notification/settings`, postBody);
};

// User Notification Settings
export const listUserNotificationSettings = params => {
    return request.get(`notification/settings/user`, params);
};

export const updateUserNotificationSettings = postBody => {
    return request.post(`notification/settings/user`, postBody);
};

export const notificationContactSettings = params => {
    return request.get(`notification/settings/contacts`, params);
};

export const updateNotificationContactSettings = postBody => {
    return request.post(`notification/settings/contacts`, postBody);
};

// Features
export const getFeatures = data => {
    return request.get(`settings/property/features`, data);
};

export const saveFeature = (id, data) => {
    if (id) {
        return request.post(`settings/property/features/${id}`, data);
    }
    return request.post(`settings/property/features`, data);
};

export const deleteFeature = id => {
    return request.del(`settings/property/features/${id}`);
};

// Utilities
export const getUtilities = data => {
    return request.get(`settings/property/utilities`, data);
};

export const saveUtility = (id, data) => {
    if (id) {
        return request.post(`settings/property/utilities/${id}`, data);
    }
    return request.post(`settings/property/utilities`, data);
};

export const deleteUtility = id => {
    return request.del(`settings/property/utilities/${id}`);
};

// Inspections Items
export const getInspectionItems = params => {
    return request.get(`settings/inspections/items`, params);
};

export const saveInspectionItem = (id, data) => {
    if (id) {
        return request.post(`settings/inspections/items/${id}`, data);
    }
    return request.post(`settings/inspections/items`, data);
};

export const deleteInspectionItem = id => {
    return request.del(`settings/inspections/items/${id}`);
};

// Inspections Actions
export const getInspectionActions = params => {
    return request.get(`settings/inspections/actions`, params);
};

export const saveInspectionAction = (id, data) => {
    if (id) {
        return request.post(`settings/inspections/actions/${id}`, data);
    }
    return request.post(`settings/inspections/actions`, data);
};

export const deleteInspectionAction = id => {
    return request.del(`settings/inspections/actions/${id}`);
};

// Inspections Actions Categories
export const getInspectionActionCategories = params => {
    return request.get(`settings/inspections/action-categories`, params);
};

export const saveInspectionActionCategory = (id, data) => {
    if (id) {
        return request.post(`settings/inspections/action-categories/${id}`, data);
    }
    return request.post(`settings/inspections/action-categories`, data);
};

export const deleteInspectionActionCategory = id => {
    return request.del(`settings/inspections/action-categories/${id}`);
};

// Inspections Areas
export const getDefaultAreas = params => {
    return request.get(`settings/inspections/default-areas`, params);
};

export const getDefaultArea = (id, params) => {
    return request.get(`settings/inspections/default-areas/${id}`, params);
};

export const saveDefaultAreas = (id, data, params) => {
    if (id) {
        return request.post(`settings/inspections/default-areas/${id}`, data, params);
    }
    return request.post(`settings/inspections/default-areas`, data, params);
};

export const deleteDefaultArea = id => {
    return request.del(`settings/inspections/default-areas/${id}`);
};

export const saveDefaultAreaItem = (defaultAreaID, defaultAreaItemID, data, params) => {
    if (defaultAreaItemID) {
        return request.post(`settings/inspections/default-areas/${defaultAreaID}/items/${defaultAreaItemID}`, data, params);
    }
    return request.post(`settings/inspections/default-areas/${defaultAreaID}/items`, data, params);
};

export const deleteDefaultAreaItem = (areaID, itemID) => {
    return request.del(`settings/inspections/default-areas/${areaID}/items/${itemID}`);
};

// Management Fees
export const getManagementFeeSettings = params => {
    return request.get(`settings/management-fees`, params);
};

export const getManagementFeeSetting = (id, params) => {
    return request.get(`settings/management-fees/${id}`, params);
};

export const saveManagementFeeSetting = (id, data, params) => {
    if (id) {
        return request.post(`settings/management-fees/${id}`, data, params);
    }
    return request.post(`settings/management-fees`, data, params);
};

export const activateManagementFeeSetting = id => {
    return request.post(`/settings/management-fees/${id}/activate`);
};

export const deactivateManagementFeeSetting = id => {
    return request.post(`/settings/management-fees/${id}/deactivate`);
};

// Late Fees
export const getLateFeeSettings = params => {
    return request.get(`settings/late-fees`, params);
};

export const getLateFeeSetting = (id, params) => {
    return request.get(`settings/late-fees/${id}`, params);
};

export const saveLateFeeSetting = (id, data) => {
    if (id) {
        return request.post(`settings/late-fees/${id}`, data);
    }
    return request.post(`settings/late-fees`, data);
};

export const activateLateFeeSetting = id => {
    return request.post(`/settings/late-fees/${id}/activate`);
};

export const deactivateLateFeeSetting = id => {
    return request.post(`/settings/late-fees/${id}/deactivate`);
};

export const cloneLateFeeSettings = (id, postBody) => {
    return request.post(`/settings/late-fees/${id}/clone`, postBody);
};

// Sales Tax
export const getSalesTaxSettings = params => {
    return request.get(`settings/sales-tax`, params);
};

export const getSalesTaxSetting = (id, params) => {
    return request.get(`settings/sales-tax/${id}`, params);
};

export const saveSalesTaxSetting = (id, data) => {
    if (id) {
        return request.post(`settings/sales-tax/${id}`, data);
    }
    return request.post(`settings/sales-tax`, data);
};

export const activateSalesTaxSetting = id => {
    return request.post(`/settings/sales-tax/${id}/activate`);
};

export const deactivateSalesTaxSetting = id => {
    return request.post(`/settings/sales-tax/${id}/deactivate`);
};

export const cloneSalesTaxSettings = (id, postBody) => {
    return request.post(`/settings/sales-tax/${id}/clone`, postBody);
};

// Sales Tax Agency
export const getSalesTaxAgencies = params => {
    return request.get(`settings/sales-tax-agency`, params);
};

export const getSalesTaxAgency = (id, params) => {
    return request.get(`settings/sales-tax-agency/${id}`, params);
};

export const saveSalesTaxAgency = (id, data) => {
    if (id) {
        return request.post(`settings/sales-tax-agency/${id}`, data);
    }
    return request.post(`settings/sales-tax-agency`, data);
};

// Withholding Tax Agency
export const getWithholdingTaxAgencies = params => {
    return request.get(`settings/withholding-tax-agencies`, params);
};

export const getWithholdingTaxAgency = (id, params) => {
    return request.get(`settings/withholding-tax-agencies/${id}`, params);
};

export const saveWithholdingTaxAgency = (id, data) => {
    if (id) {
        return request.post(`settings/withholding-tax-agencies/${id}`, data);
    }
    return request.post(`settings/withholding-tax-agencies`, data);
};

export const deleteWithholdingTaxAgency = (id, params) => {
    return request.del(`settings/withholding-tax-agencies/${id}`, params);
};

// Posting Settings
export const getPostingSettings = params => {
    return request.get(`settings/postings`, params);
};

export const getPostingSetting = (id, params) => {
    return request.get(`settings/postings/${id}`, params);
};

export const savePostingSetting = (id, data) => {
    if (id) {
        return request.post(`settings/postings/${id}`, data);
    }
    return request.post(`settings/postings`, data);
};

export const activatePostingSetting = id => {
    return request.post(`/settings/postings/${id}/activate`);
};

export const deactivatePostingSetting = id => {
    return request.post(`/settings/postings/${id}/deactivate`);
};

// Statement Settings
export const getStatementSettings = params => {
    return request.get(`settings/statements`, params);
};

export const getStatementSetting = (id, params) => {
    return request.get(`settings/statements/${id}`, params);
};

export const saveStatementSetting = (id, data) => {
    if (id) {
        return request.post(`settings/statements/${id}`, data);
    }

    return request.post(`settings/statements`, data);
};

export const activateStatementSetting = id => {
    return request.post(`settings/statements/${id}/activate`);
};

export const deactivateStatementSetting = id => {
    return request.post(`settings/statements/${id}/deactivate`);
};

// Statement Reports
export const getStatementReports = (id, params) => {
    return request.get(`settings/statements/${id}/reports`, params);
};

export const getStatementReport = (statementSettingsID, reportID, params) => {
    return request.get(`settings/statements/${statementSettingsID}/reports/${reportID}`, params);
};

export const saveStatementReport = (statementSettingID, id, data) => {
    if (id) {
        return request.post(`settings/statements/${statementSettingID}/reports/${id}`, data);
    } else {
		return request.post(`settings/statements/${statementSettingID}/reports`, data);
	}
};

export const deleteStatementReport = (statementSettingsID, reportID) => {
    return request.del(`settings/statements/${statementSettingsID}/reports/${reportID}`);
};

export const cloneStatementSettings = (id, postBody) => {
    return request.post(`settings/statements/${id}/clone`, postBody);
};

export const deleteStatementSetting = statementSettingsID => {
    return request.del(`settings/statements/${statementSettingsID}`);
};

export const cloneManagementFeeSettings = (id, postBody) => {
    return request.post(`settings/management-fees/${id}/clone`, postBody);
};

// Account Users
export const getAccountUsers = params => {
    return request.get(`settings/company/users`, params);
};

// Branding
export const getBranding = params => {
    return request.get(`settings/branding`, params);
};

export const createBranding = (postBody, params) => {
    return request.post(`settings/branding`, postBody, params);
};

export const uploadBrandingFile = (postBody, params) => {
    return request.upload(`settings/branding/upload-file`, postBody, params);
};

export const deleteBrandingFile = (id, fileName) => {
    return request.del(`settings/branding/delete-file`, id, fileName);
};

export const uploadWatermarkFile = (data, params) => {
    return request.upload(`settings/branding/upload-watermark-file`, data, params);
};

export const deleteWatermarkFile = (id, fileName) => {
    return request.del(`settings/branding/delete-watermark-file`, id, fileName);
};

// global marketing documents
export const getGlobalMarketingDocs = params => {
    return request.get(`/listings/files`, params);
};

export const addGlobalMarketingDoc = (file, params, config) => {
    return request.upload(`/listings/files`, null, file, params, config);
};

export const reorderGlobalMarketingDocs = postBody => {
    return request.post(`/listings/files/reorder`, postBody);
};

// Recurring Charges
export const getRecurringChargesSettings = params => {
    return request.get(`leases/default-recurring-charges`, params);
};

export const saveRecurringChargesSetting = (id, data, params) => {
    if (id) {
        return request.post(`leases/default-recurring-charges/${id}`, data, params);
    }
    return request.post(`leases/default-recurring-charges`, data, params);
};

export const deleteRecurringChargeSetting = (id, params) => {
    return request.del(`leases/default-recurring-charges/${id}`, params);
};

// Marketing Settings
export const updateMarketingSettings = data => {
    return request.post(`property/marketing/settings`, data);
};
