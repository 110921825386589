import Enumeration from 'Lib/utilities/Enumeration';
import { isNil } from 'Lib/utilities/isNil';

import PropertyTypeCategories from './propertyTypeCategories';

const PropertyTypes = new Enumeration([
    { id: 1, name: 'Single Family Home', propertyTypeCategoryID: PropertyTypeCategories.Residential, allowMultiUnit: false },
    { id: 2, name: 'Apartment', propertyTypeCategoryID: PropertyTypeCategories.Residential, allowMultiUnit: true },
    { id: 3, name: 'Condo', propertyTypeCategoryID: PropertyTypeCategories.Residential, allowMultiUnit: true },
    { id: 4, name: 'Townhouse', propertyTypeCategoryID: PropertyTypeCategories.Residential, allowMultiUnit: true },
    { id: 5, name: 'Duplex', propertyTypeCategoryID: PropertyTypeCategories.Residential, allowMultiUnit: true },
    { id: 6, name: 'Multiplex', propertyTypeCategoryID: PropertyTypeCategories.Residential, allowMultiUnit: true },
    { id: 7, name: 'Loft', propertyTypeCategoryID: PropertyTypeCategories.Residential, allowMultiUnit: true },
    { id: 8, name: 'Mobile Home', propertyTypeCategoryID: PropertyTypeCategories.Residential, allowMultiUnit: false },
    { id: 9, name: 'Commercial', propertyTypeCategoryID: PropertyTypeCategories.Commercial, allowMultiUnit: true },
    { id: 10, name: 'Garage', propertyTypeCategoryID: PropertyTypeCategories.Residential, allowMultiUnit: true },
    { id: 11, name: 'Lot', propertyTypeCategoryID: PropertyTypeCategories.Residential, allowMultiUnit: true }
]);

const multiUnitTypes = [
    PropertyTypes.Apartment,
    PropertyTypes.Condo,
    PropertyTypes.Townhouse,
    PropertyTypes.Duplex,
    PropertyTypes.Multiplex,
    PropertyTypes.Loft,
    PropertyTypes.Commercial,
    PropertyTypes.Garage,
    PropertyTypes.Lot
];

export const isMultiUnit = propertyTypeID => {
    return !isNil(
        multiUnitTypes.find(item => {
            return item == propertyTypeID;
        })
    );
};

export default PropertyTypes;
