import { request } from '~/agent';

export const getAccounts = params => {
    return request.get(`accounting/accounts`, params);
};

export const getBankAccounts = params => {
    return request.get(`accounting/bank-accounts`, params);
};

export const searchAccounts = params => {
    return request.get(`accounting/accounts/search`, params);
};

export const getAccount = (id, params) => {
    return request.get(`accounting/accounts/${id}`, params);
};

export const createAccount = (data, params) => {
    return request.post(`accounting/accounts`, data, params);
};

export const updateAccount = (id, data, params) => {
    return request.post(`accounting/accounts/${id}`, data, params);
};

export const deleteAccount = (id, params) => {
    return request.del(`accounting/accounts/${id}`, params);
};

// Payment Method
export const getPaymentMethod = params => {
    return request.get(`account/payment-methods`, params);
};

export const updatePaymentMethod = (data, params) => {
    return request.post(`account/payment-methods/update`, data, params);
};

export const getBankAccount = (id, params) => {
    return request.get(`accounting/accounts/${id}/bank-account`, params);
};

export const updateBankAccount = (id, data, params) => {
    return request.post(`accounting/accounts/${id}/bank-account`, data, params);
};

export const activateAccount = (id, data, params) => {
    return request.post(`accounting/accounts/${id}/activate`, data, params);
};

export const deactivateAccount = (id, params) => {
    return request.post(`accounting/accounts/${id}/deactivate`, params);
};

// Account Categories
export const getCategories = params => {
    return request.get(`accounting/account-categories`, params);
};

export const getCategory = (id, params) => {
    return request.get(`accounting/account-categories/${id}`, params);
};

export const saveCategory = (id, params) => {
    if (!id) {
        return request.post('accounting/account-categories', params);
    }
    return request.post(`accounting/account-categories/${id}`, params);
};

export const deleteCategory = (id, params) => {
    return request.del(`accounting/account-categories/${id}`, params);
};
