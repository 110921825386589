import { useQuery } from '@tanstack/react-query';

import useAgent from '../api/useAgent';

const useApplicationStatuses = () => {
    const { get } = useAgent();

    return useQuery({
        queryKey: ['screening/statuses'],
        queryFn: async ({ queryKey }) => {
            const { data } = await get(queryKey.join(''));

            return data;
        },
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false
    });
};

export default useApplicationStatuses;
