import { Children, cloneElement, isValidElement, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Tooltip, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

import Link from 'Lib/components/common/Link';
import SearchIndexTypes from 'Lib/constants/app/searchIndexTypes';
import copyToClipboard from 'Lib/utilities/copyToClipboard';
import isEmpty from 'Lib/utilities/isEmpty';
import { limitLength } from 'Lib/utilities/limitLength';
import { screenSize } from 'Lib/utilities/screenSize';

import ActionResult from './ActionResult';
import AssociationResult from './AssociationResult';
import OwnerResult from './OwnerResult';
import PortfolioResult from './PortfolioResult';
import PropertyResult from './PropertyResult';
import ReportResult from './ReportResult';
import TenantResult from './TenantResult';
import UnitResult from './UnitResult';
import VendorResult from './VendorResult';

export const Results = ({ results, setOpen }) =>
    results.map((result, index) => (
        <ResultListItemButton disableRipple key={index}>
            <Result indexTypeID={result.indexTypeID} setOpen={setOpen} {...result} />
        </ResultListItemButton>
    ));

export const ResultContainer = ({ children, disableExpand = false, toggleExpand }) => {
    return (
        <Grid
            container
            spacing={1}
            sx={{
                borderRadius: 3,
                bgcolor: 'common.background',
                width: '100%',
                ml: 0,
                '&:hover': { boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }
            }}
        >
            {/* passes disableExpand & toggleExpand to child elements which is needed to hide it's rendering */}
            {Children.map(children, child => isValidElement(child) && cloneElement(child, { disableExpand, toggleExpand }))}
        </Grid>
    );
};

export const ResultTitle = ({ disableExpand, expanded, icon, indexTypeID, label, maxLength = 45, to, toggleExpand }) => (
    <Grid container sx={{ mt: 1, mb: 1, ml: 2 }} justifyContent="space-between">
        <Grid item sm={disableExpand ? 10 : 8} sx={{ display: 'flex' }}>
            <ItemContainer>
                <ItemLink to={to} propagate={'false'}>
                    <Typography component="span">{limitLength(label, maxLength)}</Typography>
                </ItemLink>
            </ItemContainer>
        </Grid>
        <Grid item sm={disableExpand ? 2 : 4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box
                sx={{
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: '#F6F6F6',
                    borderRadius: 2,
                    border: '1px solid #545454',
                    maxWidth: 'fit-content !important',
                    height: '32px',
                    mt: 2,
                    mr: disableExpand ? 4 : 0
                }}
            >
                <ResultIcon className="material-symbols-rounded" sx={{ ml: 1 }}>
                    {icon}
                </ResultIcon>
                <Typography sx={{ width: 'fit-content', mr: 4, fontSize: '14px', letterSpacing: '0.25px' }}>{SearchIndexTypes.getProperty(indexTypeID, 'key')}</Typography>
            </Box>
            {!disableExpand && (
                <IconButton onClick={toggleExpand} sx={{ ml: 1, mr: 1 }}>
                    {expanded ? <span className="material-symbols-rounded">expand_less</span> : <span className="material-symbols-rounded">expand_more</span>}
                </IconButton>
            )}
        </Grid>
    </Grid>
);

export const ResultItem = ({ children, icon, to }) => (
    <Grid item xs={12} sx={{ display: 'flex', ml: 1, lineHeight: '22px' }}>
        {icon && <ResultIcon className="material-symbols-rounded">{icon}</ResultIcon>}
        <ItemContainer>
            {to ? (
                <ItemLink to={to} propagate={'false'}>
                    {children}
                </ItemLink>
            ) : (
                children
            )}
        </ItemContainer>
    </Grid>
);

export const ResultExpanded = ({ children, expanded }) => expanded && <Box sx={{ width: '100%', '& .MuiGrid-item': { mt: 2, mr: 4 } }}>{children}</Box>;

export const ResultFooter = ({ actions, to }) => {
    const { mobile: isMobileScreenSize, tablet: isTabletScreenSize } = screenSize();
    const mobile = isTabletScreenSize || isMobileScreenSize;

    const [anchorElement, setAnchorElement] = useState(null);

    const openPopover = Boolean(anchorElement);
    const id = openPopover ? 'simple-popover' : undefined;

    const history = useHistory();
    const baseUrl = window.location.origin;

    const handlePopoverClick = event => {
        event.stopPropagation();
        setAnchorElement(event.currentTarget);
    };

    const handleClose = event => {
        event.stopPropagation();
        setAnchorElement(null);
    };

    const handleClick = (onClick, to) => {
        if (onClick) onClick();
        else if (to) history.push(to);
    };

    const handleCopyLink = event => {
        if (!mobile) event.stopPropagation();
        copyToClipboard(`${baseUrl}${to}`);
    };

    const handleOpenLink = event => {
        if (!mobile) event.stopPropagation();
        window.open(to, '_blank');
    };

    return (
        <>
            <Divider sx={{ borderColor: 'common.gray', mt: 2, width: '100%', opacity: '0.38' }} />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 1, ml: 3 }}>
                <div>
                    {!isEmpty(actions) &&
                        actions.map(({ icon, label, to, onClick }, index) => (
                            <FooterAction
                                key={index}
                                variant="outlined"
                                aria-label={label}
                                onClick={() => handleClick(onClick, to)}
                                startIcon={!mobile && <span className="material-symbols-rounded">{icon}</span>}
                            >
                                {label}
                            </FooterAction>
                        ))}
                </div>
                {mobile ? (
                    <>
                        <IconButton data-testid="options-ellipses" aria-describedby={id} aria-label="options" onClick={handlePopoverClick}>
                            <span className="material-symbols-rounded">more_vert</span>
                        </IconButton>
                        <Popover
                            id={id}
                            open={openPopover}
                            anchorEl={anchorElement}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            sx={{ '& .MuiPopover-paper': { width: 168 } }}
                        >
                            <List disablePadding>
                                <ActionItem disablePadding>
                                    <ListItemButton disableGutters onClick={handleCopyLink}>
                                        <ListItemIcon>
                                            <span className="material-symbols-rounded">link</span>
                                        </ListItemIcon>
                                        <ListItemText>Copy Link</ListItemText>
                                    </ListItemButton>
                                </ActionItem>
                                <ActionItem disablePadding>
                                    <ListItemButton disableGutters onClick={handleOpenLink}>
                                        <ListItemIcon>
                                            <span className="material-symbols-rounded">open_in_new</span>
                                        </ListItemIcon>
                                        <ListItemText>Open New tab</ListItemText>
                                    </ListItemButton>
                                </ActionItem>
                            </List>
                        </Popover>
                    </>
                ) : (
                    <Box>
                        <Tooltip arrow title="Copy Link" placement="top">
                            <IconButton aria-label="copy-link" onClick={handleCopyLink}>
                                <span className="material-symbols-rounded">link</span>
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow title="Open New Tab" placement="top">
                            <IconButton aria-label="open-result-in-new-window" sx={{ mr: 1 }} onClick={handleOpenLink}>
                                <span className="material-symbols-rounded">open_in_new</span>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default function Result({ indexTypeID, ...rest }) {
    const [expanded, setExpanded] = useState(false);

    const chipProps = {
        toggleExpand: () => setExpanded(prevState => !prevState),
        expanded,
        indexTypeID,
        ...rest
    };

    const ResultChips = {
        [SearchIndexTypes.Unit]: <UnitResult {...chipProps} />,
        [SearchIndexTypes.Property]: <PropertyResult {...chipProps} />,
        [SearchIndexTypes.Owner]: <OwnerResult {...chipProps} />,
        [SearchIndexTypes.Tenant]: <TenantResult {...chipProps} />,
        [SearchIndexTypes.Vendor]: <VendorResult {...chipProps} />,
        [SearchIndexTypes.Portfolio]: <PortfolioResult {...chipProps} />,
        [SearchIndexTypes.Association]: <AssociationResult {...chipProps} />,
        [SearchIndexTypes.Action]: <ActionResult {...chipProps} />,
        [SearchIndexTypes.Report]: <ReportResult {...chipProps} />
    };

    const ResultChip = () => ResultChips[indexTypeID];

    return <ResultChip />;
}

const ResultIcon = styled('span')(({ theme }) => ({
    width: '24px',
    height: '24px',
    fontSize: '24px',
    marginRight: theme.spacing(2)
}));

const ItemContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    '& .MuiTypography-root': { width: '100%' },
    span: { letterSpacing: '.25px', fontWeight: theme.typography.fontWeightRegular, fontSize: theme.typography.pxToRem(14), width: '100%' }
}));

const ItemLink = styled(Link)(({ theme }) => ({
    '& .MuiTypography-root': { color: theme.palette.link.main, fontSize: theme.typography.pxToRem(14) }
}));

const ResultListItemButton = styled(ListItemButton)(({ theme }) => ({
    padding: 0,
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.common.darkgray,
    width: '100%',
    borderRadius: '8px',
    userSelect: 'text',
    '&:hover': { cursor: 'unset' },
    '&:focus': { boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }
}));

const FooterAction = styled(Button)(({ theme }) => ({
    color: theme.palette.link.main,
    borderRadius: '8px',
    marginRight: theme.spacing(1),
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightRegular,
    '& .MuiButton-startIcon': { marginRight: theme.spacing(1) },
    '& .material-symbols-rounded': { fontSize: '24px !important' }
}));

const ActionItem = styled(ListItem)(({ theme }) => ({
    whiteSpace: 'nowrap',
    '& .MuiListItemButton-root': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(4),
        color: theme.palette.link.main,
        '& .material-symbols-rounded': { paddingRight: theme.spacing(2) }
    }
}));
