import { useEffect, useState } from 'react';
import Button from 'reactstrap/lib/Button';
import moment from 'moment';

import DatePickerField from 'Lib/components/form/DatePickerField';
import DayDueField from 'Lib/components/form/DayDueField';
import useApi from 'Lib/hooks/useApi';

import Prorate from '../accounting/Prorate';

import CurrencyField from './CurrencyField';
import FormModal from './FormModal';
import SavingButton from './SavingButton';

import { calculateProrate } from '/apps/manager/src/api/leases';

export default function QuickActionsProrate(props) {
    const [isSaving, setIsSaving] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showProrate, setShowProrate] = useState(false);
    const [prorate, setProrate] = useState({});

    const getProrateAmount = useApi(calculateProrate);

    useEffect(() => {
        setShowModal(props.showModal);
    }, [props.showModal]);

    const onSave = ({ values }) => {
        setIsSaving(true);

        getProrateAmount(values)
            .then(response => {
                setProrate(response.data);
                setShowProrate(true);
                setIsSaving(false);
            })
            .catch(() => setIsSaving(false));
    };

    const toggleModal = () => {
        if (props.setShowModal) {
            props.setShowModal();
            return;
        }

        setShowModal(prevState => !prevState);
    };

    return (
        <FormModal
            size="lg"
            title="Calculate Prorated Rent"
            initialValues={{
                billingDay: 1,
                startDate: moment().format('MM/DD/YYYY')
            }}
            isOpen={showModal}
            toggle={toggleModal}
            renderButtons={form => (
                <>
                    {isSaving ? (
                        <SavingButton text="Calculating..." />
                    ) : showProrate ? (
                        <>
                            <Button onClick={() => setShowProrate(false)}>Back</Button>
                        </>
                    ) : (
                        <>
                            <Button color="primary" onClick={() => onSave(form)}>
                                Calculate
                            </Button>
                            <Button onClick={toggleModal}>Cancel</Button>
                        </>
                    )}
                </>
            )}
            render={() => {
                if (showProrate) {
                    return <Prorate prorate={prorate} />;
                }

                return (
                    <>
                        <CurrencyField id="amount" name="amount" label="Rent Amount" autoFocus />
                        <DatePickerField id="startDate" name="startDate" label="Move In Date" />
                        <DayDueField id="billingDay" name="billingDay" label="Day Due" />
                    </>
                );
            }}
        />
    );
}
