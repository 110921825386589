import Address from 'Lib/components/common/Address';
import isEmpty from 'Lib/utilities/isEmpty';

import Emails from './items/Emails';
import Phones from './items/Phones';
import { ResultContainer, ResultFooter, ResultItem, ResultTitle } from './Result';

export default function OwnerResult(props) {
    const { addresses, emails, phones } = props.meta;
    const path = `/maintenance/vendors/${props.objectID}`;

    return (
        <ResultContainer disableExpand {...props}>
            <ResultTitle icon="real_estate_agent" label={props.title} to={path} indexTypeID={props.indexTypeID} />
            {!isEmpty(addresses) && (
                <ResultItem>
                    {addresses.map((address, index) => (
                        <Address key={index} value={address} showCountry={false} maxLength="65" displayInline />
                    ))}
                </ResultItem>
            )}
            <Phones value={phones} />
            <Emails value={emails} />
            <ResultFooter
                to={path}
                actions={[
                    { icon: 'mail', label: 'Email', to: `/maintenance/vendors/${props.objectID}?composeEmail=true&tab=messages` },
                    { icon: '3p', label: 'Chat', to: `/maintenance/vendors/${props.objectID}?tab=chat` },
                    { icon: 'add', label: 'Bill', to: `/accounting/payables/bills/add?contactID=${props.objectID}` },
                    { icon: 'receipt', label: 'Bills', to: `/maintenance/vendors/${props.objectID}?tab=bills` }
                ]}
            />
        </ResultContainer>
    );
}
