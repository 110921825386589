// @ts-check

import React, { createContext, useState } from 'react';
import { Container, Modal as MuiModal, Paper, Stack } from '@mui/material';

import { useModalTrigger } from './ModalTrigger';
import { useHistory } from 'react-router-dom';

export const ModalContext = createContext();
/**
 * @typedef {object} ModalProps
 * @property {string} name - The name of the modal.
 * @property {React.ReactElement | React.ReactElement[]} children - The content of the modal.
 * @property {boolean} [passthrough] - If true, the children will be rendered directly in addition to the modal.
 * @property {function} [onClose] - A function to run when the modal is closed.
 * @property {'xs' | 'sm' | 'md' | 'lg' | 'xl' | false} [maxWidth] - The maximum width of the modal.
 * @property {object} [modalSX] - Style overrides for the modal.
 * @property {object} [containerSX] - Style overrides for the container.
 * @property {object} [paperSX] - Style overrides for the paper.
 * @returns {JSX.Element} The modal component.
 */

/**
 * Renders a modal component.
 * @typedef {function} Modal
 * @param {ModalProps} props
 * @throws {Error} If the name or children are not provided.
 */

export default function Modal({ name, children, passthrough, onClose, maxWidth = 'md', modalSX = {}, containerSX = {}, paperSX = {} }) {
    // if name not provided, throw error
    if (!name) throw new Error('Modal name is required.');
    if (!children) throw new Error('Modal children are required.');
    const history = useHistory();

    const modal = useModalTrigger();

    const [modalName, setModalName] = useState(name);

    const modalIsOpen = modal.isSet && modal.value === name;

    // handle close remove modal parameter from query parameters
    const handleClose = () => {
        // if (query.get(name)) exists, remove it from query parameters
        if (modal.isSet) {
            if (history.length > 2) {
                history.goBack();
            } else {
                modal.removeValue();
            }

            if (onClose) onClose();
        }
    };

    const changeModalName = name => {
        setModalName(name);
    };

    return (
        <ModalContext.Provider value={{ open: modalIsOpen, modalName, handleClose, changeModalName, passthrough }}>
            {/* If passthrough is true, render children directly so we can use the same content on page and in-modal. Form modals can get the open context and make fields inactive or active so we only create things once. */}
            {passthrough && children}
            <MuiModal
                open={modalIsOpen}
                onClose={handleClose}
                sx={{ width: 1, height: 1, p: { xxs: 0, mobile: '40px' }, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', ...modalSX }}
            >
                <Container disableGutters maxWidth={maxWidth} sx={{ position: 'relative', height: { xxs: 1, mobile: 5 / 6 }, ...containerSX }}>
                    <Paper elevation={6} sx={{ width: 1, height: 1, borderRadius: [0, 0, 6], overflow: 'hidden', position: 'relative', p: 0, ...paperSX }}>
                        <Stack justifyContent="flex-start" alignItems="stretch" direction="column" sx={{ height: 1 }}>
                            {children}
                        </Stack>
                    </Paper>
                </Container>
            </MuiModal>
        </ModalContext.Provider>
    );
}
