import { useState } from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as app from '~/services/app';

import Date from './Date';
import useApi from 'Lib/hooks/useApi';

const SystemAlert = ({ alerts = [], onDismiss }) => {
    const [isOpen, setIsOpen] = useState(alerts.length ? true : false);

    const dismissAlert = useApi(onDismiss);

    const handleDismiss = () => {
		setIsOpen(false);
        dismissAlert({ accountAlerts: alerts.map(({ accountAlertID }) => accountAlertID) });
		app.dismissSystemAlerts();
    };

    return (
        <Modal isOpen={isOpen} className="alert-modal" size='lg'>
            <ModalHeader>System Alert</ModalHeader>
            <ModalBody>
                {alerts.map((alert, index) => (
                    <div key={index} className="alert-modal-message-wrapper">
                        <i className="icon fal fa-exclamation-triangle" />
                        <div className="alert-modal-message">
                            <FroalaEditorView model={alert.message} />
                        </div>
                        <div className="alert-modal-message-footer">
                            <Date value={alert.datePosted} />
                        </div>
                    </div>
                ))}
            </ModalBody>
            <ModalFooter>
                <Button type="button" onClick={handleDismiss}>
                    Dismiss
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default SystemAlert;
