import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/dashboards/maintenance',
        component: Loader(() => import('~/containers/maintenance/work-orders/Dashboard'))
    },
    {
        path: '/dashboards/screening',
        component: Loader(() => import('~/containers/screening/Dashboard'))
    },
    {
        path: '/dashboards/leases',
        component: Loader(() => import('~/containers/leases/Dashboard'))
    },
    {
        path: '/dashboards/accounting',
        component: Loader(() => import('~/containers/accounting/Dashboard'))
    }
];

export default routes;
