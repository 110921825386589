const CANCELABLE_PROMISE_IDENTIFIER = '@@CancelablePromise';

export const cancelablePromise = promise => {
    class CancelablePromise {
        isCancelled = false;
        promise = null;
        wrappedPromise = null;

        static isCancelablePromise(value) {
            return !!(value && value[CANCELABLE_PROMISE_IDENTIFIER]);
        }

        constructor(thePromise) {
            this.wrappedPromise = thePromise;

            Object.defineProperty(this, CANCELABLE_PROMISE_IDENTIFIER, {
                value: true,
                writable: false,
                readable: true
            });

            this.promise = new Promise((resolve, reject) => {
                this.wrappedPromise.then(
                    value => {
                        if (this.isCancelled) return;
                        resolve(value);
                    },
                    error => {
                        if (this.isCancelled) return;
                        reject(error);
                    }
                );
            });
        }

        cancel = () => {
            this.isCancelled = true;
        };

        catch = onRejected => {
            this.promise.catch(onRejected);

            return this;
        };

        then = (onFulfilled, onRejected) => {
            this.promise.then(onFulfilled, onRejected);

            return this;
        };

        finally = onFinally => {
            this.promise.finally(onFinally);

            return this;
        };
    }

    return new CancelablePromise(promise);
};

export default cancelablePromise;
