// @ts-check

import application from './cardTypes/Application';
import association from './cardTypes/Association';
import bank_account from './cardTypes/BankAccount';
import lease from './cardTypes/Lease';
import navigation from './cardTypes/Navigation';
import portfolio from './cardTypes/Portfolio';
import property from './cardTypes/Property';
import report from './cardTypes/Report';
import saved_report from './cardTypes/SavedReport';
import bank_adjustment from './cardTypes/TransactionBankAdjustment';
import bank_transfer from './cardTypes/TransactionBankTransfer';
import bill from './cardTypes/TransactionBill';
import deposit from './cardTypes/TransactionDeposit';
import journal_entry from './cardTypes/TransactionJournalEntry';
import ledger_transfer from './cardTypes/TransactionLedgerTransfer';
import payment from './cardTypes/TransactionPayment';
import receipt from './cardTypes/TransactionReceipt';
import settlement from './cardTypes/TransactionSettlements';
import unit from './cardTypes/Unit';
import vendor from './cardTypes/Vendor';
import work_order from './cardTypes/WorkOrder';
import work_order_project from './cardTypes/WorkOrderProject';

export default {
    application,
    association,
    bank_account,
    bank_adjustment,
    lease,
    property,
    portfolio,
    report,
    saved_report,
    bank_transfer,
    navigation,
    deposit,
    journal_entry,
    ledger_transfer,
    bill,
    payment,
    receipt,
    settlement,
    unit,
    vendor,
    work_order,
    work_order_project
};
