export const MODIFIERS = {
    ALT: 'altKey',
    META: 'metaKey',
    CTRL: 'ctrlKey',
    SHIFT: 'shiftKey'
};

export const KEYCODES = {
    H: 72,
    J: 74,
    K: 75,
    S: 83,
    R: 82, 
    DOWNARROW: 40,
};


