import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import {
    CircularProgress,
    dialogClasses,
    dialogContentClasses,
    Icon,
    iconClasses,
    InputAdornment,
    ListItemButton,
    listItemButtonClasses,
    ListItemIcon,
    ListItemText,
    TextField,
    Tooltip,
    typographyClasses
} from '@mui/material';
import { Box } from '@mui/system';
import * as Sentry from '@sentry/react';

import { KEYCODES, MODIFIERS } from 'Lib/constants/keyboard';
import useApi from 'Lib/hooks/useApi';
import useDebounce from 'Lib/hooks/useDebounce';
import useKeyboardShortcut from 'Lib/hooks/useKeyboardShortcut';
import Dialog from 'Lib/muiComponents/dialog/Dialog';
import isEmpty from 'Lib/utilities/isEmpty';

import ShortcutTooltip from '../ShortcutTooltip';

import { Results } from './Result';

const SearchInput = ({ isLoading, query, setQuery, setResults }) => {
    const textInput = useRef(null);

    return (
        <TextField
            autoFocus
            autoComplete="false"
            inputRef={textInput}
            id="global-search-input"
            data-testid="global-search-input"
            label="Global Search"
            placeholder="Enter your search criteria"
            variant="outlined"
            color="success"
            value={query}
            sx={{ borderRadius: 2, mt: 2, mb: 4, width: '100%' }}
            onChange={e => setQuery(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Icon>search</Icon>
                    </InputAdornment>
                ),
                endAdornment: isLoading ? (
                    <InputAdornment position="end">
                        <CircularProgress size={20} />
                    </InputAdornment>
                ) : !isEmpty(query) ? (
                    <InputAdornment
                        position="end"
                        onClick={() => {
                            setQuery('');
                            setResults([]);
                            textInput.current.focus();
                        }}
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                    >
                        <Icon>cancel</Icon>
                    </InputAdornment>
                ) : null
            }}
        />
    );
};

const Modal = ({ isLoading, open, query, results, setResults, setOpen, setQuery, toggleModal }) => (
    <Dialog
        open={open}
        icon="search"
        title="Global Search"
        toggle={toggleModal}
        sx={theme => ({
            [theme.breakpoints.down('lg')]: {
                [`& .${dialogContentClasses.root}`]: {
                    paddingRight: theme.spacing(6),
                    paddingLeft: theme.spacing(6)
                }
            },
            [theme.breakpoints.down('sm')]: {
                [`& .${dialogContentClasses.root}`]: {
                    paddingRight: theme.spacing(4),
                    paddingLeft: theme.spacing(4)
                }
            },
            [theme.breakpoints.up('sm')]: {
                [`& .${dialogClasses.paper}`]: {
                    width: 624,
                    borderRadius: 4
                }
            }
        })}
    >
        <SearchInput isLoading={isLoading} query={query} setQuery={setQuery} setResults={setResults} />
        <Results results={results} setOpen={setOpen} />
    </Dialog>
);

export default function Search(props) {
    const location = useLocation();

    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState('');
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const searchApi = useApi(props.search);

    const debouncedQuery = useDebounce(query, 700);

    useKeyboardShortcut(MODIFIERS.META, KEYCODES.K, () => toggleModal());

    // Reset state to default is location changes (an action is taken)
    useEffect(() => {
        resetState();
    }, [location.pathname]);

    // Reset state to default when modal is closed
    useEffect(() => {
        if (!open) resetState();
    }, [open]);

    useEffect(() => {
        switch (debouncedQuery) {
            // TODO: Add Chips when a filter is matched (not MVP)
            case 'Owner:':
                setQuery('');
                setFilter('Owner');
                break;
            case 'Report:':
                setQuery('');
                setFilter('Report');
                break;
            case 'Tenant:':
                setQuery('');
                setFilter('Tenant');
                break;
            default:
                setQuery(debouncedQuery);
                setFilter(filter ?? '');

                if (!isEmpty(debouncedQuery)) {
                    const search = debouncedQuery.trim();

                    setIsLoading(true);

                    searchApi(filter ? { search: filter.concat(`:${search}`) } : { search }).then(payload => {
                        setResults(payload.data);
                        setIsLoading(false);
                    });
                }
                break;
        }
    }, [debouncedQuery]);

    const resetState = () => {
        setQuery('');
        setFilter('');
        setResults([]);
        setOpen(false);
    };

    const toggleModal = () => {
        setQuery('');
        setFilter('');
        setOpen(prevState => !prevState);
    };

    return props.children ? (
        <>
            {props.children({ onClick: toggleModal })}
            <Modal isLoading={isLoading} open={open} query={query} results={results} setResults={setResults} setOpen={setOpen} setQuery={setQuery} toggleModal={toggleModal} />
        </>
    ) : (
        <Box
            sx={theme => ({
                [`& .${listItemButtonClasses.root}`]: {
                    height: '48px',
                    bgcolor: 'common.white',
                    borderRadius: '8px',
                    boxShadow: '0px 1px 2px #B6C7D6',
                    '&:hover': {
                        bgcolor: 'common.white'
                    }
                },
                [`& .${iconClasses.root}`]: {
                    color: '#686868'
                },
                [`& .${typographyClasses.root}`]: {
                    letterSpacing: '0.5px',
                    fontSize: theme.typography.pxToRem(16),
                    lineHeight: '24px',
                    color: '#686868'
                }
            })}
        >
            <Tooltip
                arrow
                title={<ShortcutTooltip shortcut="K" toggle={toggleModal} />}
                placement="right"
                sx={{ height: '32px' }}
                PopperProps={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 12]
                            }
                        }
                    ]
                }}
            >
                <ListItemButton data-testid="global-search" disableGutters onClick={toggleModal}>
                    <ListItemIcon variant="regular" sx={{ ml: 2, mr: 2 }}>
                        <Icon>search</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Global Search" />
                </ListItemButton>
            </Tooltip>
            <Modal isLoading={isLoading} open={open} query={query} results={results} setResults={setResults} setOpen={setOpen} setQuery={setQuery} toggleModal={toggleModal} />
        </Box>
    );
}
