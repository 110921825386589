import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    dialogClasses,
    dialogContentClasses,
    Grid,
    Icon,
    iconClasses,
    List,
    ListItem,
    ListItemButton,
    listItemButtonClasses,
    ListItemIcon,
    Tooltip,
    typographyClasses,
    useMediaQuery
} from '@mui/material';
import { Box } from '@mui/system';

import QuickActionsProrate from 'Lib/components/form/QuickActionsProrate';
import { KEYCODES, MODIFIERS } from 'Lib/constants/keyboard';
import useKeyboardShortcut from 'Lib/hooks/useKeyboardShortcut';
import Dialog from 'Lib/muiComponents/dialog/Dialog';

import ShortcutTooltip from './ShortcutTooltip';

const LINKS = [
    { label: 'New Portfolio', path: '/portfolios/add' },
    { label: 'Record Other Receipt', path: '/accounting/receivables/other/receipt/add' },
    { label: 'Pay Bill', path: '/accounting/payables/bills/pay' },
    { label: 'New Property', path: '/properties/add' },
    { label: 'Record Deposit', path: '/accounting/banking/deposits/add' },
    { label: 'Pay Owners', path: '/accounting/payables/pay-owners' },
    { label: 'New Lease', path: '/leases/move-in' },
    { label: 'New Lease Charge', path: '/accounting/receivables/tenant/charge/add' },
    { label: 'Reconcile', path: '/accounting/banking/reconciliations/add' },
    { label: 'Record Tenant Receipt', path: '/accounting/receivables/tenant/receipt/add' },
    { label: 'Post Late Fees', path: '/accounting/receivables/other/lateFee/add' },
    { label: 'New Work Order', path: '/maintenance/work-orders/add' },
    { label: 'Record Owner Receipt', path: '/accounting/receivables/owner/receipt/add' },
    { label: 'Add Bill', path: '/accounting/payables/bills/add' }
];

export default function QuickActions(props) {
    const [open, setOpen] = useState(false);
    const [prorateModalOpen, setProrateModalOpen] = useState(false);

    useKeyboardShortcut(MODIFIERS.META, KEYCODES.J, () => toggleModal());

    const toggleModal = () => setOpen(prevState => !prevState);

    const Actions = () => {
        const breakpoint = '(max-width:500px)';
        const showSingleColumns = useMediaQuery(breakpoint);

        return (
            <Dialog
                open={open}
                icon="rocket"
                title="Quick Actions"
                breakpoint={breakpoint}
                toggle={toggleModal}
                sx={{ [`& .${dialogClasses.paper}`]: { borderRadius: showSingleColumns ? 0 : '20px' }, [`& .${dialogContentClasses.root}`]: { pl: 2, pr: 2 } }}
            >
                <List>
                    <Grid container spacing={{ xs: 1, sm: 2 }}>
                        {LINKS.map((link, index) => (
                            <Grid key={index} item xs={showSingleColumns ? 12 : 4} sm={4}>
                                <ListItem disablePadding aria-label={link.label}>
                                    <ListItemButton dense component={Link} to={link.path} onClick={() => setOpen(false)} sx={{ borderRadius: 2, pr: 0 }} autoFocus={index == 0}>
                                        {link.label}
                                    </ListItemButton>
                                </ListItem>
                            </Grid>
                        ))}
                        <Grid item xs={showSingleColumns ? 12 : 4} sm={4}>
                            <ListItem disablePadding aria-label="Prorate Calculator">
                                <ListItemButton
                                    dense
                                    onClick={() => {
                                        setProrateModalOpen(true);
                                        setOpen(false);
                                    }}
                                    sx={{ borderRadius: 2, color: 'link.main', pr: 0 }}
                                >
                                    Prorate Calculator
                                </ListItemButton>
                            </ListItem>
                        </Grid>
                    </Grid>
                </List>
            </Dialog>
        );
    };

    return (
        <>
            {props.children ? (
                props.children({ onClick: toggleModal })
            ) : (
                <Box
                    sx={theme => ({
                        [`& .${listItemButtonClasses.root}`]: {
                            display: 'flex',
                            flexDirection: 'column',
                            height: 40,
                            width: 64,
                            bgcolor: 'common.white',
                            borderRadius: '12px',
                            boxShadow: '0px 1px 2px #B6C7D6'
                        },
                        [`& .${iconClasses.root}`]: {
                            color: 'success.main',
                            fontSize: 24
                        },
                        [`& .${typographyClasses.root}`]: {
                            letterSpacing: '0.4px',
                            fontSize: theme.typography.pxToRem(12),
                            lineHeight: '16px'
                        }
                    })}
                >
                    <Tooltip arrow title={<ShortcutTooltip title="Quick Actions Shortcut" shortcut="J" toggle={toggleModal} />} placement="top" sx={{ height: '32px' }}>
                        <ListItemButton onClick={toggleModal}>
                            <ListItemIcon variant="xlarge">
                                <Icon>rocket</Icon>
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                </Box>
            )}
            <Actions />
            <QuickActionsProrate showModal={prorateModalOpen} setShowModal={setProrateModalOpen} />
        </>
    );
}
