import { Component } from 'react';
import { connect } from 'react-redux';

export const withCache = (WrappedComponent, caches, displayName = 'withCache') => {
    const mapStateToProps = state => {
        const obj = {};

        Object.keys(caches).forEach(name => {
            const cacheInfo = caches[name];
            obj[name] = state.dataCache[cacheInfo.key];
        });

        return obj;
    };

    const mapDispatchToProps = dispatch => ({
        callCache: () => {
            Object.keys(caches).forEach(name => {
                const cacheInfo = caches[name];

                dispatch(cacheInfo.action());
            });
        }
    });

    class CacheComponent extends Component {
        componentDidMount() {
            if (this.props.callCache) {
                this.props.callCache();
            }
        }

        render() {
            const cache = {
                isLoading: false,
                hasError: false
            };

            Object.keys(caches).forEach(name => {
                if (!this.props[name] || this.props[name].isLoading == true) {
                    cache.isLoading = true;
                }
                if (this.props[name] && this.props[name].error == true) {
                    cache.hasError = true;
                }
            });

            return <WrappedComponent {...this.props} cache={cache} />;
        }
    }

    CacheComponent.displayName = `${displayName}(${WrappedComponent.name})`;

    return connect(mapStateToProps, mapDispatchToProps)(CacheComponent);
};

export default withCache;
