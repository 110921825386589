import { Box, Icon, Stack, Typography, useTheme } from '@mui/material';

// create jsdoc for EmptyViewProps
/**
 * @typedef {Object} EmptyViewProps
 * @property {string} [title] - The title of the empty view.
 * @property {string} [subTitle] - The subtitle of the empty view.
 * @property {string} [iconName] - The name of the icon to display.
 * @property {string} [iconFlair] - The name of the icon to display.
 * @property {boolean} [grow] - If true, the empty view will grow to fill the container.
 * @property {string} [component] - The component to render the empty view as.
 * @property {string} [backgroundColor] - The background color of the empty view.
 * @property {import('react').ReactNode} [children] - The content of the empty view.
 */

// TODO: generic empty state icon coming from Noel
/**
 * @typedef {Function} EmptyView
 * @param {EmptyViewProps} parameter
 * @returns
 */
export default function EmptyView({
    title = 'Nothing Found',
    subTitle,
    iconName = 'folder_open',
    iconFlair = 'auto_awesome',
    grow = false,
    component = 'div',
    backgroundColor = 'white',
    children
}) {
    const theme = useTheme();

    return (
        <Box
            component={component}
            sx={{
                width: 1,
                height: grow ? 1 : 'auto',
                padding: theme.spacing(4),
                minHeight: '256px',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: backgroundColor
            }}
        >
            <Stack spacing={6} alignItems="center" justifyContent="center" direction="column" sx={{ alignContent: 'center' }}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    sx={{
                        backgroundColor: `success.secondary`,
                        borderRadius: '50%',
                        position: 'relative',
                        alignItems: 'center',
                        height: '128px',
                        width: '128px'
                    }}
                >
                    <Icon
                        sx={{
                            color: 'success.main',
                            fontSize: theme.typography.pxToRem(26),
                            position: 'absolute',
                            top: theme.spacing(6),
                            right: theme.spacing(6)
                        }}
                    >
                        {iconFlair}
                    </Icon>
                    <Icon
                        variant="filled"
                        sx={{
                            color: 'success.main',
                            fontSize: theme.typography.pxToRem(48)
                        }}
                    >
                        {iconName}
                    </Icon>
                </Stack>
                <Stack direction="column" spacing={2}>
                    {title && (
                        <Typography align="center" sx={{ fontSize: theme.typography.pxToRem(24) }}>
                            {title}
                        </Typography>
                    )}
                    {subTitle && <Typography align="center">{subTitle}</Typography>}
                    {children !== undefined && children}
                </Stack>
            </Stack>
        </Box>
    );
}

export function EmptyFilesView({ title }) {
    return <EmptyView grow title={title} iconName="folder_open" />;
}
