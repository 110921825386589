import { request } from '~/agent';

export const getLeases = params => {
    return request.get(`leases`, params);
};

export const getLease = (id, params) => {
    return request.get(`leases/${id}`, params);
};

export const searchLeases = params => {
    return request.get(`leases/search`, params);
};

export const searchBulkLeases = params => {
    return request.get(`leases/bulk-messages`, params);
};

export const deleteLease = id => {
    return request.del(`leases/${id}`);
};

export const updateLeaseStatus = (id, data, params) => {
    return request.post(`leases/${id}/status`, data, params);
};

export const activateLease = (id, params) => {
    return request.post(`leases/${id}/activate`, {}, params);
};

export const getLeasesForRentIncrease = params => {
    return request.get(`leases/for-rent-increase`, params);
};

export const saveRenewal = (id, leaseRenewalID, data, params) => {
    if (!leaseRenewalID) {
        return request.post(`leases/${id}/renewals`, data, params);
    } else {
        return request.post(`leases/${id}/renewals/${leaseRenewalID}`, data, params);
    }
};

export const deleteRenewal = (leaseID, renewalID) => {
    return request.del(`leases/${leaseID}/renewals/${renewalID}`);
};

export const saveLease = (id, data, params) => {
    if (!id) {
        return request.post('leases', data, params);
    }

    return request.post(`leases/${id}`, data, params);
};

export const saveLeaseTenant = (id, params) => {
    return request.post(`leases/${id}/tenants`, params);
};

export const saveLeasePortal = (id, data, params) => {
    return request.post(`leases/${id}/portal`, data, params);
};

export const saveLeasePortalPayment = (id, data, params) => {
    return request.post(`leases/${id}/portal-payment`, data, params);
};

export const saveLeasePortalAchPayment = (id, data, params) => {
    return request.post(`leases/${id}/portal-ach-payment`, data, params);
};

export const saveLeasePortalCreditCardPayment = (id, data, params) => {
    return request.post(`leases/${id}/portal-credit-card-payment`, data, params);
};

export const saveLeasePortalCashPayment = (id, data, params) => {
    return request.post(`leases/${id}/portal-cash-payment`, data, params);
};

export const postLeaseFee = (id, data, params) => {
    return request.post(`leases/${id}/fee`, data, params);
};

export const getLeaseFee = (id, params) => {
    return request.get(`leases/${id}/fee`, params);
};

export const toggleLateFees = (id, toggle) => {
    return request.post(`leases/${id}/late-fees/${toggle}`);
};

/** Recurring Charges **/
export const getRecurringCharges = (leaseID, params) => {
    return request.get(`leases/${leaseID}/recurring-charges`, params);
};

export const addRecurringCharge = (id, data, params) => {
    return request.post(`leases/${id}/recurring-charges`, data.recurringCharge ? data.recurringCharge : data, params);
};

export const updateRecurringCharge = (id, leaseRecurringChargeID, data, params) => {
    return request.post(`leases/${id}/recurring-charges/${leaseRecurringChargeID}`, data.recurringCharge, params);
};

export const deleteRecurringCharge = (id, leaseRecurringChargeID) => {
    return request.del(`leases/${id}/recurring-charges/${leaseRecurringChargeID}`);
};

export const getRecurringChargeInitialDate = (data, params) => {
    return request.post(`leases/recurring-charges/initial-charge-date`, data, params);
};

export const getRecurringChargeHistory = (leaseID, recurringChargeID, params) => {
    return request.get(`leases/${leaseID}/recurring-charges/${recurringChargeID}/transactions`, params);
};

// recurring credits
export const getRecurringCredits = (leaseID, params) => {
    return request.get(`leases/${leaseID}/recurring-credits`, params);
};

export const getRecurringCredit = (leaseID, recurringCreditID, params) => {
    return request.get(`leases/${leaseID}/recurring-credits/${recurringCreditID}`, params);
};

export const createLeaseRecurringCredit = (leaseID, postBody) => {
    return request.post(`leases/${leaseID}/recurring-credits`, postBody);
};

export const updateRecurringCredit = (leaseID, recurringCreditID, postBody) => {
    return request.post(`leases/${leaseID}/recurring-credits/${recurringCreditID}`, postBody);
};

export const deleteRecurringCredit = (leaseID, recurringCreditID) => {
    return request.del(`leases/${leaseID}/recurring-credits/${recurringCreditID}`);
};

/** Tenants **/
export const getLeaseTenants = (id, params) => {
    return request.get(`leases/${id}/tenants`, params);
};

export const addTenant = (leaseID, data, params) => {
    return request.post(`leases/${leaseID}/tenants`, data, params);
};

export const updateLeaseTenant = (leaseID, leaseTenantID, postBody, params) => {
    return request.post(`leases/${leaseID}/tenants/${leaseTenantID}`, postBody, params);
};

export const removeTenant = (leaseID, leaseTenantID, params) => {
    return request.del(`leases/${leaseID}/tenants/${leaseTenantID}`, params);
};

export const reorderTenants = (leaseID, data, params) => {
    return request.post(`leases/${leaseID}/tenants/reorder`, data, params);
};

/** Occupants **/
export const addOccupant = (leaseID, data, params) => {
    return request.post(`leases/${leaseID}/occupants`, data, params);
};

export const editOccupant = (leaseID, occupantID, data, params) => {
    return request.post(`leases/${leaseID}/occupants/${occupantID}`, data, params);
};

export const deleteOccupant = (leaseID, id, params) => {
    return request.del(`leases/${leaseID}/occupants/${id}`, params);
};

/** Pets **/
export const addPet = (leaseID, data, params) => {
    return request.post(`leases/${leaseID}/pets`, data, params);
};

export const editPet = (leaseID, petID, data, params) => {
    return request.post(`leases/${leaseID}/pets/${petID}`, data, params);
};

export const deletePet = (leaseID, petID, params) => {
    return request.del(`leases/${leaseID}/pets/${petID}`, params);
};

/** Vehicles **/
export const addVehicle = (leaseID, data, params) => {
    return request.post(`leases/${leaseID}/vehicles`, data, params);
};

export const editVehicle = (leaseID, vehicleID, data, params) => {
    return request.post(`leases/${leaseID}/vehicles/${vehicleID}`, data, params);
};

export const deleteVehicle = (leaseID, vehicleID, params) => {
    return request.del(`leases/${leaseID}/vehicles/${vehicleID}`, params);
};

/** Emergency Contacts **/
export const addEmergencyContact = (leaseID, data, params) => {
    return request.post(`leases/${leaseID}/emergency-contacts`, data, params);
};

export const editEmergencyContact = (leaseID, emergencyContactID, data, params) => {
    return request.post(`leases/${leaseID}/emergency-contacts/${emergencyContactID}`, data, params);
};

export const deleteEmergencyContact = (leaseID, emergencyContactID, params) => {
    return request.del(`leases/${leaseID}/emergency-contacts/${emergencyContactID}`, params);
};

/** Move Ins */
export const submitMoveIn = (postBody, params) => {
    return request.post(`leases`, postBody, params);
};

export const getMoveIns = params => {
    return request.get(`leases/move-ins`, params);
};

export const getMoveIn = (moveInID, params) => {
    return request.get(`leases/move-ins/${moveInID}`, params);
};

export const saveMoveIn = (moveInID, data, params) => {
    if (moveInID) {
        return request.post(`leases/move-ins/${moveInID}`, data, params);
    }

    return request.post(`leases/move-ins`, data, params);
};

export const deleteMoveIn = (moveInID, params) => {
    return request.del(`leases/move-ins/${moveInID}`, params);
};

export const completeMoveIn = (moveInID, data, params) => {
    return request.post(`leases/move-ins/${moveInID}/complete`, data, params);
};

export const getMoveInLeaseFee = (moveInID, params) => {
    return request.get(`leases/move-ins/${moveInID}/lease-fee`, params);
};

/** Move Outs */
export const getMoveOuts = params => {
    return request.get(`leases/move-outs`, params);
};

export const getMoveOut = (leaseID, params) => {
    return request.get(`leases/${leaseID}/move-out`, params);
};

export const saveMoveOut = (leaseID, data, params) => {
    return request.post(`leases/${leaseID}/move-out`, data, params);
};

export const deleteMoveOut = (leaseID, data, params) => {
    return request.del(`leases/${leaseID}/move-out`, data, params);
};

export const completeMoveOut = (leaseID, data, params) => {
    return request.post(`leases/${leaseID}/move-out/complete`, data, params);
};

/** Move Out Reasons */
export const getMoveOutReasons = params => {
    return request.get(`leases/move-out/reasons`, params);
};

export const getModelSearchMoveOutReasons = params => {
    return request.get(`leases/move-out/reasons/model-search`, params);
};

export const getMoveOutReason = (moveOutID, params) => {
    return request.get(`leases/move-out/reasons/${moveOutID}`, params);
};

export const saveMoveOutReason = (moveOutID, data, params) => {
    if (moveOutID) return request.post(`leases/move-out/reasons/${moveOutID}`, data, params);

    return request.post(`leases/move-out/reasons`, data, params);
};

export const deleteMoveOutReason = (moveOutID, data, params) => {
    return request.del(`leases/move-out/reasons/${moveOutID}`, data, params);
};

/** Tenant Payments */

export const getRecurringPayments = (leaseID, params) => {
    return request.get(`leases/${leaseID}/recurring-payments`, params);
};

export const getRecurringPayment = (leaseID, recurringPaymentID, params) => {
    return request.get(`leases/${leaseID}/recurring-payments/${recurringPaymentID}`, params);
};

export const saveRecurringPayment = (leaseID, postBody) => {
    return request.post(`leases/${leaseID}/recurring-payments`, postBody);
};

export const updateRecurringPayment = (leaseID, recurringPaymentID, postBody) => {
    return request.post(`leases/${leaseID}/recurring-payments/${recurringPaymentID}`, postBody);
};

export const deleteRecurringPayment = (leaseID, recurringPaymentID) => {
    return request.del(`leases/${leaseID}/recurring-payments/${recurringPaymentID}`);
};

// Payment Methods
export const getPaymentMethods = (leaseID, params) => {
    return request.get(`leases/${leaseID}/payment-methods`, params);
};

export const getPaymentMethod = (leaseID, paymentMethodID) => {
    return request.get(`leases/${leaseID}/payment-methods/${paymentMethodID}`);
};

export const savePaymentMethod = (leaseID, postBody) => {
    return request.post(`leases/${leaseID}/payment-methods`, postBody);
};

export const updatePaymentMethod = (leaseID, paymentMethodID, postBody) => {
    return request.post(`leases/${leaseID}/payment-methods/${paymentMethodID}`, postBody);
};

export const deletePaymentMethod = (leaseID, paymentMethodID) => {
    return request.del(`leases/${leaseID}/payment-methods/${paymentMethodID}`);
};

/** Deposits */
export const getDepositBalances = (leaseID, params) => {
    return request.get(`leases/${leaseID}/deposit-balances`, params);
};

export const getHeldDepositBalances = (leaseID, params) => {
    return request.get(`leases/${leaseID}/held-deposit-balances`, params);
};

export const calculateProrate = (data, params) => {
    return request.post(`prorate`, data, params);
};

// Lease statuses
export const getStatuses = params => {
    return request.get(`leases/statuses`, params);
};

export const getStatus = (id, params) => {
    return request.get(`leases/statuses/${id}`, params);
};

export const addStatus = (data, params) => {
    return request.post(`leases/statuses`, data, params);
};

export const updateStatus = (id, data, params) => {
    return request.post(`leases/statuses/${id}`, data, params);
};

export const deleteStatus = (id, params) => {
    return request.del(`leases/statuses/${id}`, params);
};

export const reorderStatuses = (data, params) => {
    return request.post(`leases/statuses/reorder`, data, params);
};

/**
 * issueCashCard is a POST operation that issues a cash card for lease tenant.
 * @param {jsonObject} postBody - example post body { "leaseTenantID": 210 }.
 * @param {object} params - extra params such as filters etc...
 */
export const issueCashCard = (postBody, params) => {
    return request.post(`leases/tenants/cash-cards`, postBody, params);
};

/**
 * getCashCard is a GET operation that provides the lease tenants cash card.
 * @param {int} leaseTenantCashCardID - cashCardID from lease tenant object.
 * @param {*} params - extra params such as filters.
 */
export const getCashCard = (leaseTenantCashCardID, params) => {
    return request.get(`leases/tenants/cash-cards/${leaseTenantCashCardID}`, params);
};

/**
 * printCashCard is a GET request that provides a pdf copy of the lease tenants cash card.
 * @param {int} leaseTenantCashCardID
 */
export const printCashCard = leaseTenantCashCardID => {
    return request.get(`leases/tenants/cash-cards/${leaseTenantCashCardID}/print`);
};

export const getTenantLiabilityInsuranceProviders = params => {
    return request.get(`leases/settings/tenant-liability-insurance-providers`, params);
};

export const getTenantLiabilityInsuranceProviderSettings = params => {
    return request.get(`leases/settings/tenant-liability-insurance-providers/settings`, params);
};

export const getTenantLiabilityInsuranceActivePackages = params => {
    return request.get(`leases/settings/tenant-liability-insurance-providers`, params);
};

export const getTenantLiabilityInsuranceProvider = (id, params) => {
    return request.get(`leases/settings/tenant-liability-insurance-providers/${id}`, params);
};

export const saveTenantLiabilityInsuranceProvider = (id, data) => {
    if (id) {
        return request.post(`leases/settings/tenant-liability-insurance-providers/${id}`, data);
    } else {
        return request.post(`leases/settings/tenant-liability-insurance-providers`, data);
    }
};

export const deleteTenantLiabilityInsuranceProvider = id => {
    return request.del(`leases/settings/tenant-liability-insurance-providers/${id}`);
};

export const downloadMoveOutStatement = (id, data, params) => {
    return request.downloadPost(`leases/${id}/move-out/statement`, data, params);
};

export const getInsuranceRequest = id => {
    return request.get(`leases/insurance-requests/${id}`);
};

export const updateInsuranceRequest = (id, data) => {
    return request.post(`leases/insurance-requests/${id}`, data);
};

export const activateTll = data => {
    return request.post(`leases/tenant-liability-insurance`, data);
};

export const activateRentvineInsurance = data => {
    return request.post(`leases/rentvine-insurance`, data);
};

export const deactivateTll = data => {
    return request.post(`leases/tenant-liability-insurance/deactivate`, data);
};

/** Rent Increases Batches */
export const getRentIncreaseBatches = (params, data) => {
    return request.get(`leases/rent-increase-batches`, params, data);
};

export const getRentIncreaseBatch = (id, params, data) => {
    return request.get(`leases/rent-increase-batches/${id}`, params, data);
};

export const createRentIncreaseBatch = (data, params) => {
    return request.post(`leases/rent-increase-batches`, data, params);
};

export const printRentIncreaseBatch = id => {
    return request.get(`leases/rent-increase-batches/${id}/print`);
};

export const emailRentIncreaseBatch = (id, data, params) => {
    return request.post(`leases/rent-increase-batches/${id}/email`, data, params);
};

/** Rent Increases */
export const getRentIncreases = (params, data) => {
    return request.get(`leases/rent-increases`, params, data);
};

export const getRentIncrease = (id, params, data) => {
    return request.get(`leases/rent-increases/${id}`, params, data);
};

export const voidRentIncrease = (id, params, data) => {
    return request.del(`leases/rent-increases/${id}`, params, data);
};
