// @ts-check

import { Box, Icon, Typography } from '@mui/material';

import DateTime from 'Lib/components/common/DateTime';

/** @param {{notification: NotificationItem}} param0  */
export default function TimeStamp({ notification }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Icon fontSize="small">schedule</Icon>

            <Typography sx={theme => ({ pl: 2, fontSize: `${theme.typography.pxToRem(11)}`, textTransform: 'uppercase' })}>
                <DateTime value={notification.dateTimeCreated} format="MMMM Do, YYYY [at] h:mm A" />
            </Typography>
        </Box>
    );
}
