// @ts-check
import { Link } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Typography, useTheme } from '@mui/material';
import HtmlReactParser from 'html-react-parser';

import isEmpty from 'Lib/utilities/isEmpty';

import { CircleIcon, ResultCard, SearchChip, XrayIcon } from '~/components/globalSearch/components/StyledComponents';
import XraySortOrder from '~/components/globalSearch/data/xraySortOrder';

/** ---------------------Parent------------------ */
/** @param {{children: import('react').ReactNode, path: string, cardType: string}} param0 */
function CardParent({ path = '', children, cardType = '' }) {
    return (
        <ResultCard>
            <BackgroundButton path={path} type={cardType} />

            <Grid container direction="row" columnSpacing={2} wrap="nowrap" sx={{ position: 'relative', pointerEvents: 'none' }}>
                {children}
            </Grid>
        </ResultCard>
    );
}

export function BackgroundButton({ type = '', path = '' }) {
    const theme = useTheme();

    return (
        <Button
            title={`View ${type}`}
            to={path}
            replace
            component={Link}
            onFocus={e => e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })}
            variant="text"
            disableFocusRipple={true}
            disableElevation={true}
            className="global-search_primary-link"
            disabled={path.length ? false : true}
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                border: `1px solid ${theme.palette.common.white}`,
                backgroundColor: `${theme.palette.common.white}`,
                transition: 'all 0.3s ease',
                boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 3px 8px 2px rgba(0, 0, 0, .1) !important',
                '&:hover': {
                    backgroundColor: `${theme.palette.common.lightblue}`,
                    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 3px 8px 2px rgba(0, 0, 0, .1)',
                    border: `1px solid ${theme.palette.common.blue}`
                },
                '&:focus': {
                    backgroundColor: `${theme.palette.common.lightblue}`,
                    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 3px 8px 2px rgba(0, 0, 0, .1)',
                    border: `1px solid ${theme.palette.common.blue}`
                }
            }}
        />
    );
}

/** ---------------------Content------------------ */

/** @param {{children: import('react').ReactNode}} param0 */
function ContentContainer({ children }) {
    return (
        <Grid item xs>
            <Grid container direction="column" columnSpacing={3} wrap="nowrap">
                {children}
            </Grid>
        </Grid>
    );
}

function PrimaryIcon({ icon = 'user' }) {
    return (
        <Grid item>
            <CircleIcon>{icon}</CircleIcon>
        </Grid>
    );
}

/**
 *
 * @param {{status: string, variant?: string }} param0
 * @returns
 */
function StatusChip({ status = 'Active', variant = 'default' }) {
    return (
        <Box sx={{ position: 'absolute', top: -6, right: -4 }}>
            <SearchChip label={status} variant={variant} sx={{ fontWeight: status == 'Inactive' ? 'normal' : '700' }} />
        </Box>
    );
}

/**
 *
 * @param {{value: string, highlight: string, forceHighlight?: boolean, sx?: Object}} param0
 * @returns
 */
function HighlightedText({ value = '', highlight = '', forceHighlight = false, sx = {} }) {
    return (
        <Box component={'span'} sx={{ '& > em': { fontStyle: 'normal', fontWeight: 'bold', ...sx } }}>
            {forceHighlight && highlight ? HtmlReactParser(`<em>${value}<em>`) : highlight ? HtmlReactParser(highlight) : value}
        </Box>
    );
}

/** ---------------------Header------------------ */

// Header is pre-composed since it is used in all card types
/** @param {{title: string, highlight?: string, cardType: string, subtype?: string | null, forceHighlight?: boolean}} param0 */
function Header({ title = '', highlight = '', cardType = '', subtype = '', forceHighlight = false }) {
    return (
        <HeaderContainer>
            <Type cardType={cardType} />
            <TitleContainer>
                <Title title={title} highlight={highlight} forceHighlight={forceHighlight} />
                {subtype && <Subtype subtype={subtype} />}
            </TitleContainer>
        </HeaderContainer>
    );
}

/** @param {{children: import('react').ReactNode}} param0 */

function HeaderContainer({ children }) {
    return (
        <Grid item xs sx={{ mt: 0 }}>
            <Grid container direction="column" wrap="nowrap">
                {children}
            </Grid>
        </Grid>
    );
}

function Type({ cardType = '' }) {
    return (
        <Grid item xs={12}>
            <Typography sx={{ fontSize: theme => theme.typography.pxToRem(10), lineHeight: '1.4', textTransform: 'uppercase', letterSpacing: 2, position: 'relative', bottom: -2 }}>
                {cardType}
            </Typography>
        </Grid>
    );
}

/** @param {{children: import('react').ReactNode}} param0 */
function TitleContainer({ children }) {
    return (
        <Grid item xs sx={{ py: 1 }}>
            <Grid container direction="row" wrap="nowrap" gap={2} alignItems="center" sx={{ zIndex: 1 }}>
                {children}
            </Grid>
        </Grid>
    );
}

function Title({ title = '', highlight = '', forceHighlight = false }) {
    return (
        <Grid item>
            <Typography component="h2" sx={theme => ({ fontSize: theme.typography.pxToRem(18), fontWeight: '600' })}>
                <HighlightedText value={title} highlight={highlight} forceHighlight={forceHighlight} sx={{ fontWeight: '900' }} />
            </Typography>
        </Grid>
    );
}

function Subtype({ subtype = '' }) {
    return (
        <Grid item sx={{ display: 'inline-flex' }}>
            <SearchChip label={subtype} />
        </Grid>
    );
}

/** ---------------------Body------------------ */
/** @param {{children: import('react').ReactNode}} param0 */
export function CardBody({ children }) {
    return <Grid item>{children}</Grid>;
}

/** ---------------------Subtitles------------------ */

/** @param {{children: import('react').ReactNode}} param0 */
function Subtitles({ children }) {
    return (
        <Grid container direction="row" columnSpacing={2} sx={{ pt: 1 }} alignItems="center">
            {children}
        </Grid>
    );
}

function SubtitleIcon({ icon = 'user', path = '' }) {
    return (
        <Grid item>
            <IconButton to={path} replace component={Link} variant="small" sx={{ pointerEvents: 'auto', mr: '-5px' }}>
                {icon}
            </IconButton>
        </Grid>
    );
}

function SubtitleLink({ path = '', label = '', value = '', highlight = '' }) {
    return (
        <Grid item display="flex" alignItems="center">
            {label}:
            <Button
                replace
                variant="text-underline"
                to={path}
                component={Link}
                sx={{
                    position: 'relative',
                    zIndex: '2',
                    ml: 1,
                    pointerEvents: 'auto',
                    fontSize: 'inherit',
                    minWidth: 'unset',
                    height: 'unset',
                    color: 'link.main',
                    textTransform: 'unset',
                    '&:hover': { backgroundColor: 'transparent' }
                }}
            >
                <HighlightedText value={value} highlight={highlight} />
            </Button>
        </Grid>
    );
}

/** @param {{value?: string, highlight?: string, children?: import('react').ReactNode}} param0 */
function SubtitleItem({ value = '', highlight = '', children = null }) {
    return (
        <Grid sx={{ '& > em': { fontStyle: 'normal', fontWeight: 'bold' } }} item>
            {children ?? <HighlightedText value={value} highlight={highlight} />}
        </Grid>
    );
}

/** ---------------------Description --------------- */

/** @param {{children: import('react').ReactNode}} param0 */
function CardDescription({ children }) {
    return (
        <Grid item>
            <Typography variant="body2" component="div" sx={{ fontSize: theme => theme.typography.pxToRem(14), '& > em': { fontStyle: 'normal', fontWeight: 'bold' } }}>
                {children}
            </Typography>
        </Grid>
    );
}

/** ---------------------DeepLinks------------------ */

/** @param {{children: import('react').ReactNode}} param0 */
function DeepLinks({ children }) {
    return (
        <Grid container direction="row" columnSpacing={2} sx={{ py: 1, alignItems: 'center' }}>
            {children}
        </Grid>
    );
}

function DeepLinkItem({ path = '', value = '' }) {
    if (isEmpty(value) || isEmpty(path)) return;

    return (
        <Grid item>
            <Button
                to={path}
                replace
                component={Link}
                title={'View ' + value}
                sx={theme => ({
                    py: 0.25,
                    px: 2,
                    height: 'unset',
                    zIndex: '2',
                    pointerEvents: 'auto',
                    fontWeight: '700',
                    fontSize: theme.typography.pxToRem(11),
                    backgroundColor: 'white',
                    color: `${theme.palette.common.blue}`,
                    border: `1px solid ${theme.palette.common.blue}`,
                    '&:hover': { backgroundColor: `${theme.palette.hover.main}` }
                })}
            >
                {value}
            </Button>
        </Grid>
    );
}

/** ---------------------Xrays------------------ */
const ForceHighlightFields = [
    'additionalPhones.phone',
    'owners.additionalPhones',
    'owners.additionalPhones.phone',
    'tenants.additionalPhones',
    'tenants.additionalPhones.phone',
    'pastTenants.phone'
];

/** @param {{xrays: Array<{field?: string, label: string, value: string}>, highlights?: {[key: string]: string[][]}, forceHighlight?: boolean, limit?: number}} param0 */
function Xrays({ xrays = [], highlights = [], forceHighlight = false, limit = 1 }) {
    const limitedXrays = xrays.sort((a, b) => XraySortOrder.indexOf(a.field) - XraySortOrder.indexOf(b.field)).slice(0, limit);

    return limitedXrays.map((xray, index) => {
        const matchHighlight = highlights[xray.field]?.[0]?.[0];

        if (ForceHighlightFields.includes(xray.field)) {
            forceHighlight = true;
        }

        return <Xray key={index} label={xray.label} value={xray.value} highlight={matchHighlight} forceHighlight={forceHighlight} />;
    });
}

function Xray({ label = '', value = '', highlight = '', forceHighlight = false }) {
    if (isEmpty(value) || isEmpty(label)) return;

    return (
        <Grid item>
            <SearchChip
                sx={{ py: 0.5, bgcolor: 'white', border: '1px solid black', fontWeight: 'normal' }}
                label={
                    <>
                        <XrayIcon /> {label}: <HighlightedText value={value} highlight={highlight} forceHighlight={forceHighlight} />
                    </>
                }
            />
        </Grid>
    );
}

/** ---------------------ObjectLinks------------------ */

/** @param {{children: import('react').ReactNode, columns?: number}} param0 */
function ObjectLinks({ children, columns = 1 }) {
    return (
        <Grid
            xs={12}
            item
            // container
            // columns={columns}
            columnSpacing={5}
            sx={{ display: 'grid', gridTemplateColumns: ['repeat(1, 100%)', `repeat(${columns}, ${100 / columns}%)`], pt: 1 }}
        >
            {children}
        </Grid>
    );
}

function ObjectLinkTitle({ value = '' }) {
    return (
        <Grid item alignItems="center" sx={{ display: 'flex', fontSize: theme => theme.typography.pxToRem(11) }}>
            {value}
        </Grid>
    );
}

function ObjectLinkItem({ path = '', value = '', highlight = '', forceHighlight = false }) {
    return (
        <Grid
            item
            alignItems="center"
            justifyContent="start"
            sx={theme => ({ display: 'flex', borderTop: `1px solid ${theme.palette.common.lightgray}`, fontSize: theme.typography.pxToRem(11) })}
        >
            {path !== '' ? (
                <Button
                    variant="text-underline"
                    to={path}
                    component={Link}
                    replace
                    sx={{
                        position: 'relative',
                        zIndex: '2',
                        pointerEvents: 'auto',
                        fontSize: 'inherit',
                        minWidth: 'unset',
                        height: 'unset',
                        color: 'link.main',
                        textTransform: 'unset',
                        '&:hover': { backgroundColor: 'transparent' }
                    }}
                >
                    <HighlightedText value={value} highlight={highlight} />
                </Button>
            ) : (
                <HighlightedText value={value} highlight={highlight} forceHighlight={forceHighlight} />
            )}
        </Grid>
    );
}

function Review({ path = '' }) {
    return (
        <Box sx={{ textAlign: 'right', mt: 4 }}>
            <Button
                to={path}
                component={Link}
                replace
                sx={theme => ({
                    zIndex: '2',
                    backgroundColor: 'white',
                    color: `${theme.palette.common.blue}`,
                    border: `1px solid ${theme.palette.common.blue}`,
                    pointerEvents: 'auto',
                    '&:hover': { backgroundColor: `${theme.palette.hover.main}` }
                })}
            >
                Review
            </Button>
        </Box>
    );
}

/** -- 696-------------------Exports------------------ */

export const ObjectLink = {
    Parent: ObjectLinks,
    Title: ObjectLinkTitle,
    Item: ObjectLinkItem
};

export const DeepLink = {
    Parent: DeepLinks,
    Item: DeepLinkItem,
    Xrays,
    Xray
};

export const Subtitle = {
    Parent: Subtitles,
    Icon: SubtitleIcon,
    Link: SubtitleLink,
    Item: SubtitleItem
};

export const Body = CardBody;

export const Description = CardDescription;

export const Content = ContentContainer;

export const Parent = CardParent;

export const Status = StatusChip;

export const Icon = PrimaryIcon;

export const ReviewButton = Review;

export default {
    Parent,
    Content,
    Status,
    Icon,
    Header,
    Body: {
        Parent: CardBody,
        Subtitle,
        Description,
        DeepLink,
        ObjectLink,
        ReviewButton
    }
};
