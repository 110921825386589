import { useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';

import useConfig from 'Lib/hooks/api/useConfig';
import MuiHeader from 'Lib/muiComponents/page/Header';
import { LARGE_OPEN_DRAWER_WIDTH, OPEN_DRAWER_WIDTH } from 'Lib/styles/variables';
import openExternalLink from 'Lib/utilities/openExternalLink';
import { screenSize } from 'Lib/utilities/screenSize';

import Header from './components/Header';
import AppBar from './AppBar';
import Drawer from './Drawer';

export default function Navigation(props) {
    const [mobileModalOpen, setMobileModalOpen] = useState(false);
    const {
        portal: { isManager }
    } = useConfig();
    const { mobile: isMobileScreenSize, tablet: isTabletScreenSize } = screenSize();
    const mobileSize = isTabletScreenSize || isMobileScreenSize;

    const barHeight = isManager ? '75px' : '0';

    const toggleModal = () => {
        setMobileModalOpen(prevState => !prevState);
    };

    const toggleDrawer = (event = {}) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
        props.toggleDrawer();
    };

    const handleClick = (path, external, full) => {
        if (external) {
            openExternalLink(path);
        } else {
            if (full) toggleDrawer(); // Close menu on click when backdrop/temporary Drawer is present
        }
    };

    const navigationProps = {
        app: props.app,
        session: props.session,
        items: props.items,
        search: props.search,
        quickActions: props.quickActions,
        matchExact: props.matchExact,
        portalTypeID: props.portalTypeID,
        portalAccounts: props.portalAccounts,
        badgeCounts: {
            unreadMessageCount: props.unreadMessageCount
        },
        onChangePortal: props.onChangePortal,
        onChangeLease: props.onChangeLease,
        onChangePortfolio: props.onChangePortfolio,
        mobileModalOpen,
        toggleDrawer,
        handleClick,
        toggleModal
    };

    return mobileSize && !isManager ? (
        <AppBar open={props.open} toggleDrawer={toggleDrawer} {...navigationProps} />
    ) : (
        <>
            {isManager && <MuiHeader toggleDrawer={toggleDrawer} />}

            <MuiDrawer
                anchor="left"
                variant={mobileSize ? 'temporary' : 'permanent'}
                onClose={toggleDrawer}
                open={props.open}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={theme => ({
                    flexShrink: 0,
                    whiteSpace: 'nowrap',
                    boxSizing: 'border-box',
                    '& .MuiPaper-root': {
                        backgroundColor: theme.palette.navigation.background.main,
                        border: 'none',
                        zIndex: 1000,
                        overflowX: 'hidden',
                        borderRadius: mobileSize && '0px 20px 20px 0px',
                        top: !mobileSize ? barHeight : '0',
                        height: !mobileSize ? `calc(100vh - ${barHeight})` : '100%',
                        width: !mobileSize ? LARGE_OPEN_DRAWER_WIDTH : OPEN_DRAWER_WIDTH,
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen
                        })
                    }
                })}
            >
                {!isManager && !mobileSize && <Header toggleDrawer={toggleDrawer} />}
                <Drawer {...navigationProps} full={mobileSize && true} />
            </MuiDrawer>
        </>
    );
}
