import CoreIndexes from 'Lib/constants/app/coreIndexes';

import { ResultContainer, ResultFooter, ResultTitle } from './Result';

export default function ReportResult(props) {
    const path = CoreIndexes.getProperty(props.meta.coreIndexID, 'url');

    return (
        <ResultContainer disableExpand {...props}>
            <ResultTitle label={props.title} icon="monitoring" to={path} indexTypeID={props.indexTypeID} expanded={props.expanded} />
            <ResultFooter to={path} />
        </ResultContainer>
    );
}
