import Enumeration from 'Lib/utilities/Enumeration';

export const PrimaryApplicationStatuses = new Enumeration([
    { id: 1, key: 'Pending', name: 'Pending', color: 'secondary' },
    { id: 2, key: 'Submitted', name: 'Submitted', color: 'success' },
    { id: 3, key: 'Screening', name: 'Screening', color: 'secondary' },
    { id: 4, key: 'Processing', name: 'Processing', color: 'info' },
    { id: 5, key: 'OnHold', name: 'On Hold', color: 'info' },
    { id: 6, key: 'Approved', name: 'Approved', color: 'success' },
    { id: 7, key: 'Declined', name: 'Declined', color: 'danger' },
    { id: 8, key: 'Withdrawn', name: 'Withdrawn', color: 'warning' }
]);

export default PrimaryApplicationStatuses;
