import Loader from 'Lib/utilities/Loader';

const routes = [
    // Receivables
    {
        path: '/accounting/receivables',
        component: Loader(() => import('~/containers/accounting/receivables/Receipts'))
    },
    {
        path: '/accounting/receivables/deposits',
        component: Loader(() => import('~/containers/accounting/receivables/Deposits'))
    },
    {
        path: '/accounting/receivables/tenant/receipt/add',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/receipt/Add'))
    },
    {
        path: '/accounting/receivables/tenant/receipt/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/receipt/View'))
    },
    {
        path: '/accounting/receivables/tenant/charge/add',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/charge/Add'))
    },
    {
        path: '/accounting/receivables/tenant/charge/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/charge/View'))
    },
    {
        path: '/accounting/receivables/tenant/return/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/return/View'))
    },
    {
        path: '/accounting/receivables/tenant/credit/add',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/credit/Add'))
    },
    {
        path: '/accounting/receivables/tenant/credit/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/credit/View'))
    },
    {
        path: '/accounting/receivables/tenant/deposit-release/add',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/deposit-release/Add'))
    },
    {
        path: '/accounting/receivables/tenant/deposit-release/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/deposit-release/View'))
    },
    {
        path: '/accounting/receivables/tenant/refunded-return/add',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/refunded-return/Add'))
    },
    {
        path: '/accounting/receivables/tenant/refunded-return/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/refunded-return/View'))
    },
    {
        path: '/accounting/receivables/tenant/deposit-transfer-to-owner',
        component: Loader(() => import('~/containers/accounting/receivables/tenant/DepositTransferToOwner'))
    },
    {
        path: '/accounting/receivables/owner/receipt/add',

        component: Loader(() => import('~/containers/accounting/receivables/owner/receipt/Add'))
    },
    {
        path: '/accounting/receivables/owner/receipt/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/receivables/owner/receipt/View'))
    },
    {
        path: '/accounting/receivables/owner/return/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/receivables/owner/return/View'))
    },
    {
        path: '/accounting/receivables/other/receipt/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/receivables/other/receipt/View'))
    },
    {
        path: '/accounting/receivables/other/return/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/receivables/other/return/View'))
    },
    {
        path: '/accounting/receivables/other/receipt/add',
        component: Loader(() => import('~/containers/accounting/receivables/other/receipt/Add'))
    },
    {
        path: '/accounting/receivables/other/lateFee/add',
        component: Loader(() => import('~/containers/accounting/receivables/other/lateFee/Add'))
    },
    // Payables
    {
        path: '/accounting/payables',
        component: Loader(() => import('~/containers/accounting/payables/Payables'))
    },
    {
        path: '/accounting/payables/bills/add',
        component: Loader(() => import('~/containers/accounting/payables/bills/Add'))
    },
    // TODO: Remove this test route
    {
        path: '/accounting/payables/bills/add-new',
        component: Loader(() => import('~/containers/accounting/payables/bills/NewAdd'))
    },
    {
        path: '/accounting/payables/bills/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/payables/bills/View'))
    },
    {
        path: '/accounting/payables/bills/pay',
        component: Loader(() => import('~/containers/accounting/payables/bills/Pay'))
    },
    {
        path: '/accounting/payables/bills/recurring',
        component: Loader(() => import('~/containers/accounting/payables/bills/recurringBills/List'))
    },
    {
        path: '/accounting/payables/bills/recurring/add',
        component: Loader(() => import('~/containers/accounting/payables/bills/recurringBills/Add'))
    },
    {
        path: '/accounting/payables/bills/recurring/edit/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/payables/bills/recurringBills/Edit'))
    },
    {
        path: '/accounting/payables/bills/recurring/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/payables/bills/recurringBills/View'))
    },
    {
        path: '/accounting/manager/management-fees/post',
        component: Loader(() => import('~/containers/accounting/manager/management-fees/Post'))
    },
    {
        path: '/accounting/manager/management-fees/post-bills',
        component: Loader(() => import('~/containers/accounting/manager/management-fees/PostBills'))
    },
    {
        path: '/accounting/manager/management-fees/add',
        component: Loader(() => import('~/containers/accounting/manager/management-fees/Add'))
    },
    {
        path: '/accounting/manager/management-fees/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/manager/management-fees/View'))
    },
    {
        path: '/accounting/payables/pay-owners',
        component: Loader(() => import('~/containers/accounting/payables/owner/Batch'))
    },
    {
        path: '/accounting/payables/payouts',
        component: Loader(() => import('~/containers/accounting/payables/payouts/List'))
    },
    {
        path: '/accounting/payables/payouts/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/payables/payouts/View'))
    },
    {
        path: '/accounting/payables/payouts/returns/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/payables/payouts/returns/View'))
    },
    {
        path: '/accounting/payables/batches',
        component: Loader(() => import('~/containers/accounting/payables/batches/List'))
    },
    {
        path: '/accounting/payables/batches/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/payables/batches/View'))
    },
    {
        path: '/accounting/payables/vendor-invoices',
        component: Loader(() => import('~/containers/accounting/payables/invoices/List'))
    },
    {
        path: '/accounting/payables/vendor-invoices/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/payables/invoices/View'))
    },
    {
        path: '/accounting/payables/payouts/new',
        component: Loader(() => import('~/containers/accounting/payables/payouts/New'))
    },
    {
        path: '/accounting/payables/tenant/refund',
        component: Loader(() => import('~/containers/accounting/payables/tenant/Refund'))
    },

    // Journal Entries
    {
        path: '/accounting/transactions/journal-entries',
        component: Loader(() => import('~/containers/accounting/journal-entries/List'))
    },
    {
        path: '/accounting/transactions/journal-entries/new',
        component: Loader(() => import('~/containers/accounting/journal-entries/New'))
    },
    {
        path: '/accounting/transactions/journal-entries/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/journal-entries/View'))
    },
    // Recurring Journal Entries
    {
        path: '/accounting/transactions/recurring-journal-entries',
        component: Loader(() => import('~/containers/accounting/recurring-journal-entries/List'))
    },
    {
        path: '/accounting/transactions/recurring-journal-entries/new',
        component: Loader(() => import('~/containers/accounting/recurring-journal-entries/New'))
    },
    {
        path: '/accounting/transactions/recurring-journal-entries/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/recurring-journal-entries/View'))
    },
    // Banking
    {
        path: '/accounting/banking',
        component: Loader(() => import('~/containers/accounting/banking/List'))
    },
    {
        path: '/accounting/banking/:id([0-9]+)',
        redirect: {
            to: '/accounting/banking/bank-accounts/:id([0-9]+)'
        }
    },
    {
        path: '/accounting/banking/positive-pay',
        component: Loader(() => import('~/containers/accounting/positive-pay/Index'))
    },
    {
        path: '/accounting/banking/bank-accounts/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/banking/bank-accounts/Details'))
    },
    {
        path: '/accounting/banking/bank-accounts/:id([0-9]+)/settlements',
        component: Loader(() => import('~/containers/accounting/banking/bank-accounts/Settlements'))
    },
    {
        path: '/accounting/banking/bank-accounts/:id([0-9]+)/reconciliations',
        component: Loader(() => import('~/containers/accounting/banking/bank-accounts/Reconciliations'))
    },
    {
        path: '/accounting/banking/bank-accounts/:id([0-9]+)/adjustments',
        component: Loader(() => import('~/containers/accounting/banking/bank-accounts/Adjustments'))
    },
    {
        path: '/accounting/banking/bank-accounts/:id([0-9]+)/fees',
        component: Loader(() => import('~/containers/accounting/banking/bank-accounts/Fees'))
    },
    {
        path: '/accounting/banking/bank-accounts/:id([0-9]+)/reconciliations',
        component: Loader(() => import('~/containers/accounting/banking/bank-accounts/Reconciliations'))
    },
    {
        path: '/accounting/banking/bank-accounts/:id([0-9]+)/deposits',
        component: Loader(() => import('~/containers/accounting/banking/bank-accounts/Deposits'))
    },
    {
        path: '/accounting/banking/bank-accounts/:id([0-9]+)/transfers',
        component: Loader(() => import('~/containers/accounting/banking/bank-accounts/Transfers'))
    },
    {
        path: '/accounting/banking/bank-accounts/:id([0-9]+)/ledger',
        component: Loader(() => import('~/containers/accounting/banking/bank-accounts/Ledger'))
    },

    // Checks
    {
        path: '/accounting/banking/checks/print',
        component: Loader(() => import('~/containers/accounting/banking/checks/List'))
    },

    // Adjustments
    {
        path: '/accounting/banking/adjustments',
        component: Loader(() => import('~/containers/accounting/banking/adjustments/List'))
    },
    {
        path: '/accounting/banking/adjustments/add',
        component: Loader(() => import('~/containers/accounting/banking/adjustments/Add'))
    },
    {
        path: '/accounting/banking/adjustments/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/banking/adjustments/View'))
    },
    // Deposits
    {
        path: '/accounting/banking/deposits',
        component: Loader(() => import('~/containers/accounting/banking/deposits/List'))
    },
    {
        path: '/accounting/banking/deposits/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/banking/deposits/View'))
    },
    {
        path: '/accounting/banking/deposits/add',
        component: Loader(() => import('~/containers/accounting/banking/deposits/Add'))
    },
    // Settlements
    {
        path: '/accounting/banking/settlements',
        component: Loader(() => import('~/containers/accounting/banking/settlements/List'))
    },
    {
        path: '/accounting/banking/settlements/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/banking/settlements/View'))
    },
    // Reconciliations
    {
        path: '/accounting/banking/reconciliations',
        component: Loader(() => import('~/containers/accounting/banking/reconciliations/List'))
    },
    {
        path: '/accounting/banking/reconciliations/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/banking/reconciliations/View'))
    },
    {
        path: '/accounting/banking/reconciliations/add',
        component: Loader(() => import('~/containers/accounting/banking/reconciliations/Add'))
    },
    // Transfers
    {
        path: '/accounting/banking/transfers',
        component: Loader(() => import('~/containers/accounting/banking/transfers/List'))
    },
    {
        path: '/accounting/banking/transfers/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/banking/transfers/View'))
    },
    {
        path: '/accounting/banking/transfers/add',
        component: Loader(() => import('~/containers/accounting/banking/transfers/Add'))
    },
    {
        path: '/accounting/banking/transfers/transactions/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/banking/transfers/transactions/View'))
    },
    {
        path: '/accounting/banking/pending-transfers',
        component: Loader(() => import('~/containers/accounting/banking/transfers/Pending'))
    },
    // Ledgers
    {
        path: '/accounting/transactions/ledger-transfers/add',
        component: Loader(() => import('~/containers/accounting/ledger-transfers/Add'))
    },
    {
        path: '/accounting/transactions/ledger-transfers/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/ledger-transfers/View'))
    },
    {
        path: '/accounting/transactions/ledger-transfers',
        component: Loader(() => import('~/containers/accounting/ledger-transfers/List'))
    },
    // Recurring Ledger Transfers
    {
        path: '/accounting/transactions/recurring-ledger-transfers/add',
        component: Loader(() => import('~/containers/accounting/recurring-ledger-transfers/Add'))
    },
    {
        path: '/accounting/transactions/recurring-ledger-transfers/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/recurring-ledger-transfers/View'))
    },
    {
        path: '/accounting/transactions/recurring-ledger-transfers',
        component: Loader(() => import('~/containers/accounting/recurring-ledger-transfers/List'))
    },
    // Fees
    {
        path: '/accounting/banking/fees',
        component: Loader(() => import('~/containers/accounting/banking/fees/List'))
    },
    {
        path: '/accounting/banking/fees/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/banking/fees/View'))
    },
    {
        path: '/accounting/banking/fees/add',
        component: Loader(() => import('~/containers/accounting/banking/fees/Add'))
    },
    // Transactions
    {
        path: '/accounting/transactions',
        component: Loader(() => import('~/containers/accounting/transactions/List'))
    },
    {
        path: '/accounting/transactions/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/transactions/View'))
    },
    // Diagnostics
    {
        path: '/accounting/diagnostics',
        component: Loader(() => import('~/containers/accounting/diagnostics/Diagnostics'))
    },
    // Manager
    {
        path: '/accounting/manager',
        component: Loader(() => import('~/containers/accounting/manager/Manager'))
    },
    {
        path: '/accounting/manager/dashboard',
        component: Loader(() => import('~/containers/accounting/manager/Dashboard'))
    },
    {
        path: '/accounting/manager/pay',
        component: Loader(() => import('~/containers/accounting/manager/Pay'))
    },
    {
        path: '/accounting/manager/bulk-charge',
        component: Loader(() => import('~/containers/accounting/manager/BulkCharge'))
    },
    {
        path: '/accounting/transactions/taxes',
        component: Loader(() => import('~/containers/accounting/transactions/Taxes'))
    },
    {
        path: '/accounting/payables/bills/withholdings',
        component: Loader(() => import('~/containers/accounting/payables/bills/TaxWithholdings'))
    },
    {
        path: '/accounting/recurring-management-fees',
        component: Loader(() => import('~/containers/accounting/manager/recurring-management-fees/List'))
    },
    {
        path: '/accounting/recurring-management-fee/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/manager/recurring-management-fees/View'))
    },
    {
        path: '/accounting/recurring-management-fees/add',
        component: Loader(() => import('~/containers/accounting/manager/recurring-management-fees/Add'))
    },
    {
        path: '/accounting/transactions/taxes/batches',
        component: Loader(() => import('~/containers/accounting/transactions/taxes/Batch'))
    },
    {
        path: '/accounting/transactions/taxes/batches/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/transactions/taxes/Batch'))
    },

    // Bill Credits
    {
        path: '/accounting/payables/credits/add',
        component: Loader(() => import('~/containers/accounting/payables/credits/Add'))
    },
    {
        path: '/accounting/payables/credits',
        component: Loader(() => import('~/containers/accounting/payables/credits/List'))
    },
    {
        path: '/accounting/payables/credits/:id([0-9]+)',
        component: Loader(() => import('~/containers/accounting/payables/credits/View'))
    },
    // Utilities
    {
        path: '/accounting/payables/bills/utility',
        component: Loader(() => import('~/containers/accounting/payables/bills/utility/Add'))
    }
];

export default routes;
