import { Fragment, useState } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { ListItemButton, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/system';

export default function Submenu({ icon, full, label, submenuItems, handleClick }) {
    const location = useLocation();

    const [expanded, setExpanded] = useState(!!matchPath(location.pathname, { path: submenuItems?.map(item => item.path) }));

    const toggleExpand = () => setExpanded(!expanded);

    const isSubmenuSelected = !!matchPath(location.pathname, { path: submenuItems.map(item => item.path), exact: false });

    return (
        <SubmenuContainer expanded={expanded} selected={isSubmenuSelected} full={full}>
            <ListItemButton
                disableGutters
                onClick={toggleExpand}
                sx={{
                    borderRadius: '8px',
                    '&:hover': {
                        bgcolor: expanded ? 'navigation.background.submenu' : 'navigation.background.hover'
                    },
                    '& .Mui-selected': {
                        span: { color: 'navigation.span.selected' }
                    },
                    span: { color: 'common.black' },
                    ...(expanded && {
                        height: '48px',
                        mb: 1,
                        ml: full ? 1 : 2,
                        mr: full ? 1 : 2,
                        bgcolor: 'navigation.background.selected',
                        span: {
                            color: 'navigation.span.main'
                        }
                    })
                }}
            >
                {full ? (
                    <LargeListItemIcon variant="regular" expanded={expanded}>
                        <span className="material-symbols-rounded">{icon}</span>
                    </LargeListItemIcon>
                ) : (
                    <ListItemIcon variant="regular" sx={{ ml: expanded ? 2 : 4, mr: 2 }}>
                        <span className="material-symbols-rounded">{icon}</span>
                    </ListItemIcon>
                )}
                <ListItemText primary={label} />
                <ListItemIcon variant="regular" sx={{ mr: expanded ? 1 : 3, ...(full && { mr: expanded ? 3 : 4 }) }}>
                    {expanded ? <span className="material-symbols-rounded">expand_less</span> : <span className="material-symbols-rounded">expand_more</span>}
                </ListItemIcon>
            </ListItemButton>
            <Collapse in={expanded}>
                {submenuItems.map((item, itemIndex) => {
                    const submenuItemSelected = !!matchPath(location.pathname, { path: item.path, exact: false });

                    return (
                        <Fragment key={itemIndex}>
                            {full ? (
                                <LargeListItemButton
                                    to={item.path}
                                    component={Link}
                                    variant="nav"
                                    selected={submenuItemSelected}
                                    submenu="true"
                                    onClick={() => handleClick(item.path, item.external, full)}
                                >
                                    <ListItemText primary={item.label} />
                                </LargeListItemButton>
                            ) : (
                                <RegularListItemButton
                                    to={item.path}
                                    component={Link}
                                    variant="nav"
                                    selected={submenuItemSelected}
                                    submenu="true"
                                    onClick={() => handleClick(item.path, item.external, full)}
                                >
                                    <ListItemText primary={item.label} sx={{ '& .MuiTypography-root': { ml: 1 } }} />
                                </RegularListItemButton>
                            )}
                        </Fragment>
                    );
                })}
            </Collapse>
        </SubmenuContainer>
    );
}

const SubmenuContainer = styled(Box, { shouldForwardProp: prop => prop !== 'expanded' && prop !== 'full' })(({ theme, expanded, full, selected }) => ({
    borderRadius: '8px',
    width: full && '100%',
    marginLeft: full ? theme.spacing(0) : theme.spacing(2),
    marginBottom: theme.spacing(1),
    ...(expanded && {
        backgroundColor: theme.palette.navigation.background.selected,
        paddingTop: theme.spacing(1)
    }),
    span: { color: (expanded || selected) && theme.palette.navigation.span.selected }
}));

const LargeListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
    marginRight: theme.spacing(2),
    '& .MuiListItemText-root': {
        marginLeft: theme.spacing(selected ? 6 : 8)
    }
}));

const LargeListItemIcon = styled(ListItemIcon, { shouldForwardProp: prop => prop !== 'expanded' })(({ theme, expanded }) => ({
    marginLeft: theme.spacing(expanded ? 1 : 2),
    marginRight: theme.spacing(2)
}));

const RegularListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    '& .MuiListItemText-root': {
        marginLeft: theme.spacing(selected ? 6 : 8)
    }
}));
