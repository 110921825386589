// @ts-check

import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Stack } from '@mui/material';

/**
 * Wrapper for optional modal header content
 * @param {{children: import('react').ReactElement[] | Function}} children or callback function that gets the state from the location
 * @returns
 */

export default function ModalBody({ children }) {
    const { state } = useLocation();

    const render = state => {
        // if children is a function, pass in the state
        if (typeof children === 'function') {
            return children(state);
        }
        if (children) {
            return children;
        }
    };

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flex: '1 1 auto', minHeight: 0, m: [0, 0, 6], mt: [0, 0, 0], position: 'relative', borderRadius: [0, 0, 3], overflow: 'hidden' }}
        >
            <>{render(state)}</>
        </Stack>
    );
}
