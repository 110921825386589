// @ts-check
import { Fragment } from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';

import PrimaryApplicationStatuses from 'Lib/constants/screening/primaryApplicationStatuses';
import useApplicationStatuses from 'Lib/hooks/data/useApplicationStatuses';
import isEmpty from 'Lib/utilities/isEmpty';
import stripHtml from 'Lib/utilities/stripHtml';

import Card, { Body, DeepLink, ObjectLink, Subtitle } from '../CardComponents';

/** @param {{result: ApplicationResult}} param0 */
export default function Application({ result }) {
    const cardType = 'Application';

    const { data, xray, highlights } = result;
    const { data: applicationStatuses } = useApplicationStatuses();

    const {
        'applicants.name': highlightedNames,
        'applicants.name.shingled': highlightedNamesShingled,
        'applicants.phone': highlightedPhones,
        'unit.address.full.shingled': highlightedAddressShingled,
        'unit.address.streetNumber': highlightedStreetNumber,
        'unit.address.streetName': highlightedStreetName,
        'unit.address.address2': highlightedAddress2,
        'unit.address.city': highlightedCity,
        'unit.address.state': highlightedState,
        'unit.address.postalCode': highlightedPostalCode
    } = highlights;

    const {
        applicationStatus: { name = '', primaryApplicationStatusID }
        // @ts-ignore
    } = applicationStatuses?.find(({ applicationStatus }) => applicationStatus.applicationStatusID == data.applicationStatusID) ?? { applicationStatus: {} };

    let status = '';
    if (name?.includes(PrimaryApplicationStatuses.getName(primaryApplicationStatusID))) {
        status = name;
    } else if (name) {
        status = `${PrimaryApplicationStatuses.getName(primaryApplicationStatusID)} - ${name}`;
    }

    const applicantNames = data.applicants.map(applicant => applicant.name).join(', ');

    const strippedHighlightedNames = highlightedNames?.[0].map(highlightedName => stripHtml(highlightedName)) ?? [];
    const strippedHighlightedNamesShingled = highlightedNamesShingled?.[0].map(highlightedNameShingled => stripHtml(highlightedNameShingled)) ?? [];

    const highlightedApplicants = data.applicants
        .map(a => a.name)
        .filter(name => !strippedHighlightedNames.includes(name) && !strippedHighlightedNamesShingled.includes(name))
        .concat(highlightedNames?.[0], highlightedNamesShingled?.[0])
        .filter(Boolean)
        .join(', ');

    const highlightedAddress =
        highlightedAddressShingled?.[0][0] ??
        [
            highlightedStreetNumber?.[0][0] || data.unit.address.streetNumber,
            highlightedStreetName?.[0][0] || data.unit.address.streetName,
            highlightedAddress2?.[0][0] || data.unit.address.address2,
            highlightedCity?.[0][0] || data.unit.address.city,
            highlightedState?.[0][0] || data.unit.address.state,
            highlightedPostalCode?.[0][0] || data.unit.address.postalCode
        ]
            .filter(Boolean)
            .join(' ');

    return (
        <Card.Parent path={`/screening/applications/${data.applicationID}`} cardType={cardType}>
            {!isEmpty(status) && <Card.Status status={status} />}
            <Card.Content>
                <Card.Header title={applicantNames} highlight={highlightedApplicants} cardType={cardType} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item value={data.unit.address.full} highlight={highlightedAddress} />
                    </Subtitle.Parent>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} highlights={highlights} />
                    </DeepLink.Parent>

                    <ObjectLink.Parent columns={2}>
                        <ObjectLink.Title value="Name" />
                        <ObjectLink.Title value="Phone" />
                        {data.applicants.map(applicant => {
                            const applicantNameHighlight =
                                highlightedNames?.[0][strippedHighlightedNames.indexOf(applicant.name)] ||
                                highlightedNamesShingled?.[0][strippedHighlightedNamesShingled.indexOf(applicant.name)];

                            const phoneHighlight = applicant.phone === stripHtml(highlightedPhones?.[0][0]) ? highlightedPhones?.[0][0] : '';

                            return (
                                <Fragment key={applicant.applicantID}>
                                    <ObjectLink.Item
                                        path={`/screening/applications/${data.applicationID}/applicants/${applicant.applicantID}`}
                                        value={applicant.name}
                                        highlight={applicantNameHighlight}
                                    />
                                    <ObjectLink.Item value={applicant.phone ? `${formatPhoneNumber(applicant.phone)}` : '--'} highlight={phoneHighlight} forceHighlight />
                                </Fragment>
                            );
                        })}
                    </ObjectLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
