// @ts-check

import { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Chip, Collapse, Icon, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { v4 as UUID } from 'uuid';

import useDebounce from 'Lib/hooks/useDebounce';
import { screenSize } from 'Lib/utilities/screenSize';
import stopPropagation from 'Lib/utilities/stopPropagation';
import userDevice from 'Lib/utilities/userDevice';

import { filterChips } from '../../data/indexTypes';
import { useGenAISearch } from '../../data/useGlobalSearch';

import { useGSFilter, useGSIncludeInactive, useGSSearch } from './Params';

export default function Input() {
    const { mobile, tablet } = screenSize();
    const inputRef = useRef(null);

    const filter = useGSFilter();
    const search = useGSSearch();
    const includeInactive = useGSIncludeInactive();

    const [searchInput, setSearchInput] = useState(search.value);
    const isGenAIToggled = searchInput.startsWith('/');

    const debouncedValue = useDebounce(searchInput, 450);
    const { isFetching } = useGenAISearch();

    const activeFilter = filterChips.filter(chip => chip.value === filter.value)[0];

    const desktopSize = !mobile && !tablet;

    useEffect(() => {
        // If genAISearch is populated via voice, automatically submit the search phrase. This is normally an explicit action when typing a search.
        if (debouncedValue === search.value || isGenAIToggled) return;
        search.setValue(debouncedValue);
    }, [debouncedValue]);

    const clearSearch = () => {
        setSearchInput('');
        search.removeValue();
        filter.removeValue();
    };

    const { os } = userDevice();

    // when active filter changes, refocus on the input
    useEffect(() => {
        if (activeFilter) {
            inputRef?.current?.querySelector('input').focus();
        }
    }, [activeFilter]);

    const handleKeydown = e => {
        // keyCode 13 represents the Enter/Return button for mobile purposes
        if (e.key === 'Backspace' && !e.currentTarget.value) {
            clearSearch();
        }
    };

    const handleSubmit = () => {
        search.setValue(searchInput);
    };

    return (
        <TextField
            autoFocus
            required
            fullWidth
            ref={inputRef}
            className="global-search_search-input"
            id={UUID()}
            value={searchInput}
            data-cy="table-search-input"
            variant="outlined"
            placeholder="Search"
            aria-label="Search"
            onChange={event => setSearchInput(event.target.value)}
            onKeyDown={event => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    handleSubmit();
                }
            }}
            InputProps={{
                className: 'global-search_primary-link',
                onKeyDown: handleKeydown,
                startAdornment: <InputStartAdornment activeFilter={activeFilter} onClearFilter={filter.removeValue} isGenAIToggled={isGenAIToggled} />,
                endAdornment: (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                        {searchInput !== '' ? (
                            <>
                                {!isGenAIToggled && (
                                    <IconButton
                                        aria-label="clear search"
                                        onClick={e => {
                                            e.stopPropagation();
                                            clearSearch();

                                            // set focus on this elements inner input
                                            inputRef?.current?.querySelector('input').focus();
                                        }}
                                    >
                                        cancel
                                    </IconButton>
                                )}
                                {isGenAIToggled ? (
                                    <LoadingButton
                                        loading={isFetching}
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                        sx={{ height: 'unset', py: 1, px: 5, right: '-5px' }}
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </LoadingButton>
                                ) : null}
                            </>
                        ) : (
                            desktopSize && (
                                <InputAdornment position="end">
                                    {os === 'Mac' ? <Icon sx={{ mr: 1 }}>keyboard_command_key</Icon> : <Box>Ctrl +&nbsp;</Box>}
                                    <Box>K</Box>
                                </InputAdornment>
                            )
                        )}
                    </>
                )
            }}
        />
    );
}

/** @param {{activeFilter: ChipFilter, onClearFilter: () => void, isGenAIToggled: boolean}} props */
function InputStartAdornment({ activeFilter, onClearFilter, isGenAIToggled }) {
    return (
        <InputAdornment position="start">
            <Stack direction="row" gap={2} alignItems="center">
                {isGenAIToggled ? (
                    <Icon sx={{ mr: 2 }} variant="filled">
                        auto_awesome
                    </Icon>
                ) : (
                    <>
                        <Icon sx={{ display: { xxs: activeFilter ? 'none' : 'block', sm: 'block' } }}>search</Icon>
                        <Collapse in={Boolean(activeFilter)} orientation="horizontal" collapsedSize={0} timeout={100}>
                            {activeFilter ? (
                                <Chip
                                    sx={{ fontSize: theme => theme.typography.pxToRem(12), lineHeight: '1' }}
                                    label={activeFilter.label}
                                    // clickable
                                    onDelete={e => {
                                        stopPropagation(e);
                                        onClearFilter();
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </Collapse>
                    </>
                )}
            </Stack>
        </InputAdornment>
    );
}
