import Select from './Select';

const DayDueSelect = ({ itemIndex, name, label, limit, ...rest }) => {
	const dayDueList = [];
    let dayDueIndex, dayDueProperty;
	limit = limit ? limit : 31;

    for (let i = 1; i <= limit; i++) {
        dayDueList.push({ value: i, label: `${i}` });
    }

    if (itemIndex) {
        dayDueIndex = `dayDue_${itemIndex}`;
        dayDueProperty = `${name}[${itemIndex}].dayDue`;
    } else {
        dayDueIndex = name;
        dayDueProperty = name;
    }

    return <Select {...rest} id={dayDueIndex} name={dayDueProperty} options={dayDueList} label={label} />;
};

export default DayDueSelect;
