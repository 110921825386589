import Address from 'Lib/components/common/Address';

import { ResultContainer, ResultFooter, ResultItem, ResultTitle } from './Result';

export default function AssociationResult(props) {
    const path = `/associations/${props.objectID}`;

    return (
        <ResultContainer disableExpand {...props}>
            <ResultTitle icon="groups" label={props.title} indexTypeID={props.indexTypeID} expanded={props.expanded} to={path} />
            {props.meta.address
                ? props.meta.address.map((lease, index) => (
                      <ResultItem key={index}>
                          <Address value={lease} showCountry={false} maxLength={55} displayInline />
                      </ResultItem>
                  ))
                : null}
            <ResultFooter
                to={path}
                actions={[
                    { icon: 'add', label: 'Association', to: '/associations/add' },
                    { icon: 'add', label: 'Bill', to: `/accounting/payables/bills/add?contactID=${props.objectID}` }
                ]}
            />
        </ResultContainer>
    );
}
