import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import api from './api';
import localStorage from './local-storage';
import notify from './notify';
import promise from './promise';

import { default as dataCache } from '~/services/cache/middleware';

const middleware = [promise, api, thunk, notify, localStorage, routerMiddleware(history), dataCache];
let composedMiddleware;

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    composedMiddleware = compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
} else {
    composedMiddleware = compose(applyMiddleware(...middleware));
}

export default composedMiddleware;
