import { useEffect } from 'react';

import { MODIFIERS } from 'Lib/constants/keyboard';

function useKeyboardShortcut(modifier, keycode, callback) {
    useEffect(() => {
        function handleKeyDown(event) {
            if ((event[modifier] || (event.ctrlKey && modifier === MODIFIERS.META)) && event.keyCode === keycode) {
                callback(event);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [modifier, keycode, callback]);
}

export default useKeyboardShortcut;
