import {
    ACCOUNTING_SETTINGS_RELOAD,
    ACCOUNTING_SETTINGS_RELOAD_FAILED,
    ACCOUNTS_RELOAD,
    ACCOUNTS_RELOAD_FAILED,
    APPLICATION_LOAD,
    APPLICATION_LOAD_FAILED,
    LOGIN,
    LOGIN_ERROR
} from 'Lib/constants/app/actionTypes';

import agent from '~/agent';
import { getAccounts } from '~/api/accounting/accounts';
import { getInit } from '~/api/app';
import { getAccountingSettings } from '~/api/settings';

export const loadApplication = () => ({
    type: APPLICATION_LOAD,
    payload: getInit(),
    errorType: APPLICATION_LOAD_FAILED
});

export const reloadAccounts = () => ({
    type: ACCOUNTS_RELOAD,
    payload: getAccounts(),
    errorType: ACCOUNTS_RELOAD_FAILED
});

export const reloadAccountingSettings = () => ({
    type: ACCOUNTING_SETTINGS_RELOAD,
    payload: getAccountingSettings(),
    errorType: ACCOUNTING_SETTINGS_RELOAD_FAILED
});

export const authenticate = params => ({
    type: LOGIN,
    payload: agent.requestToken(params),
    errorType: LOGIN_ERROR
});
