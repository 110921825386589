// @ts-check
import HtmlReactParser from 'html-react-parser';

import Date from 'Lib/components/common/Date';
import formatAmountString from 'Lib/utilities/formatAmountString';
import isEmpty from 'Lib/utilities/isEmpty';

import Card, { Body, DeepLink, Description, Subtitle } from '../CardComponents';

import Account from '~/components/accounting/Account';
/** @param {{result: TransactionDepositResult}} param0 */
export default function TransactionDeposit({ result }) {
    const cardType = 'Deposit';

    const { data, xray, highlights } = result;

    const highlightedAmount = highlights['amount.keyword']?.[0][0];

    const parsedDepositNumber = HtmlReactParser(highlights.depositNumber?.[0]?.[0] || highlights['depositNumber.alphanumeric']?.[0]?.[0] || '');
    const depositNumber = !isEmpty(parsedDepositNumber) ? parsedDepositNumber : data.depositNumber;

    return (
        <Card.Parent path={`/accounting/banking/deposits/${data.depositID}`} cardType={cardType}>
            {data.isVoided ? <Card.Status status={'Voided'} variant="danger" /> : !isEmpty(data.reconciliationID) && <Card.Status status={'Reconciled'} />}
            <Card.Content>
                <Card.Header title={`$${formatAmountString(data.amount.amount)}`} highlight={highlightedAmount} forceHighlight cardType={cardType} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item>
                            <Date value={data.dateDeposited} />
                            {!isEmpty(depositNumber) && (
                                <>
                                    , Deposit #:&nbsp;
                                    {depositNumber}
                                </>
                            )}
                        </Subtitle.Item>
                    </Subtitle.Parent>

                    <Description>
                        Bank: <Account id={data.bankAccountID} showNumber={false} />
                    </Description>

                    <DeepLink.Parent>
                        <DeepLink.Xrays xrays={xray} highlights={highlights} forceHighlight />
                    </DeepLink.Parent>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
