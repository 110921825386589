export const CALL_API = 'CALL_API';

export const api = () => next => action => {
    if (!action[CALL_API]) {
        return next(action);
    }

    const details = action[CALL_API];

    if (!details.promise) {
        return false;
    }

    let { promise } = details;

    if (details.promise.promise) {
        promise = details.promise.promise;
    }

    promise.then(response => response).catch(error => error);

    return false;
};

export default api;
