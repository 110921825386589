import Loader from 'Lib/utilities/Loader';

const routes = [
    // Transactions

    {
        path: '/documents/transactions',
        component: Loader(() => import('~/containers/documents/transactions/List'))
    },
    {
        path: '/documents/transactions/:id([0-9]+)',
        component: Loader(() => import('~/containers/documents/transactions/View'))
    },
    {
        path: '/documents/transactions/:id([0-9]+)/builder',
        component: Loader(() => import('~/containers/documents/transactions/Builder'))
    },
    {
        path: '/documents/transactions/add',
        component: Loader(() => import('~/containers/documents/transactions/Add'))
    },
    {
        path: '/documents',
        redirect: { to: `/documents/transactions` }
    }
];

export default routes;
