import { Component } from 'react';
import { NumericFormat } from 'react-number-format';
import { Box } from '@mui/material';

import isEmpty from 'Lib/utilities/isEmpty';
import precise from 'Lib/utilities/precise';

class Currency extends Component {
    render() {
        const {
            id,
            className,
            decimalScale = 2,
            style,
            required,
            emptyText = '$0.00',
            prefix = '$',
            value,
            defaultValue,
            renderText = false,
            colorFormat = false,
            sx = {},
            component = 'div'
        } = this.props;
        const attrs = {
            id,
            className,
            style,
            required,
            prefix,
            renderText: renderText ? value => value : null,
            decimalScale,
            fixedDecimalScale: true,
            thousandSeparator: ',',
            value: isEmpty(value) ? defaultValue : value
        };

        if (isEmpty(attrs.value)) {
            return <span>{emptyText}</span>;
        }

        let textColor;
        if (value < 0) textColor = 'error.main';
        else if (value > 0) textColor = 'success.main';

        return (
            <Box component={component} sx={colorFormat ? { color: textColor } : sx}>
                <NumericFormat {...attrs} value={precise(value, decimalScale)} displayType="text" />
            </Box>
        );
    }
}

export default Currency;
