import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/settings',
        component: Loader(() => import('~/containers/settings/Index'))
    },
    {
        path: '/settings/accounting',
        component: Loader(() => import('~/containers/settings/accounting/Index'))
    },
    {
        path: '/settings/accounting/taxes',
        component: Loader(() => import('~/containers/settings/accounting/Taxes'))
    },
    {
        path: '/settings/accounting/accounts',
        component: Loader(() => import('~/containers/settings/accounting/accounts/List'))
    },
    {
        path: '/settings/accounting/accounts/add',
        component: Loader(() => import('~/containers/settings/accounting/accounts/Add'))
    },
    {
        path: '/settings/accounting/accounts/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/accounting/accounts/View'))
    },
    // Account categories
    {
        path: '/settings/accounting/account-categories',
        component: Loader(() => import('~/containers/settings/accounting/account-categories/List'))
    },
    // Management Fees
    {
        path: '/settings/management-fees',
        component: Loader(() => import('~/containers/settings/accounting/management-fees/List'))
    },
    {
        path: '/settings/management-fees/add',
        component: Loader(() => import('~/containers/settings/accounting/management-fees/Add'))
    },
    {
        path: '/settings/management-fees/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/accounting/management-fees/View'))
    },
    // Recurring Charges
    {
        path: '/settings/recurring-charges',
        component: Loader(() => import('~/containers/settings/accounting/recurring-charges/List'))
    },
    // Late Fees
    {
        path: '/settings/late-fees',
        component: Loader(() => import('~/containers/settings/accounting/late-fees/List'))
    },
    {
        path: '/settings/late-fees/add',
        component: Loader(() => import('~/containers/settings/accounting/late-fees/Add'))
    },
    {
        path: '/settings/late-fees/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/accounting/late-fees/View'))
    },
    // Maintenance
    {
        path: '/settings/maintenance/settings',
        component: Loader(() => import('~/containers/settings/maintenance/Settings'))
    },
    {
        path: '/settings/maintenance/appointment-settings',
        component: Loader(() => import('~/containers/settings/maintenance/AppointmentSettings'))
    },
    // Sales Tax
    {
        path: '/settings/sales-tax',
        component: Loader(() => import('~/containers/settings/accounting/sales-tax/List'))
    },
    {
        path: '/settings/sales-tax/add',
        component: Loader(() => import('~/containers/settings/accounting/sales-tax/Add'))
    },
    {
        path: '/settings/sales-tax/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/accounting/sales-tax/View'))
    },
    // Sales Tax Agency
    {
        path: '/settings/sales-tax-agency',
        component: Loader(() => import('~/containers/settings/accounting/sales-tax-agency/List'))
    },
    // Withholding Tax Agency
    {
        path: '/settings/withholding-tax-agencies',
        component: Loader(() => import('~/containers/settings/accounting/withholding-tax-agencies/List'))
    },
    {
        path: '/settings/withholding-tax-agencies/add',
        component: Loader(() => import('~/containers/settings/accounting/withholding-tax-agencies/Add'))
    },
    {
        path: '/settings/withholding-tax-agencies/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/accounting/withholding-tax-agencies/View'))
    },
    {
        path: '/settings/postings',
        component: Loader(() => import('~/containers/settings/accounting/postings/List'))
    },
    {
        path: '/settings/postings/add',
        component: Loader(() => import('~/containers/settings/accounting/postings/Add'))
    },
    {
        path: '/settings/postings/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/accounting/postings/View'))
    },
    {
        path: '/settings/statements',
        component: Loader(() => import('~/containers/settings/accounting/statements/List'))
    },
    {
        path: '/settings/statements/add',
        component: Loader(() => import('~/containers/settings/accounting/statements/Add'))
    },
    {
        path: '/settings/statements/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/accounting/statements/View'))
    },
    {
        path: '/settings/screening/settings',
        component: Loader(() => import('~/containers/settings/screening/Settings'))
    },
    {
        path: '/settings/screening',
        component: Loader(() => import('~/containers/settings/screening/templates/Settings'))
    },
    {
        path: '/settings/screening/templates',
        component: Loader(() => import('~/containers/settings/screening/templates/List'))
    },
    {
        path: '/settings/screening/templates/add',
        component: Loader(() => import('~/containers/settings/screening/templates/Settings'))
    },
    {
        path: '/settings/screening/templates/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/screening/templates/Settings'))
    },
    {
        path: '/settings/screening/templates/:id([0-9]+)/builder',
        component: Loader(() => import('~/containers/settings/screening/templates/Builder'))
    },
    {
        path: '/settings/screening/templates/preview',
        component: Loader(() => import('~/containers/settings/screening/templates/Preview'))
    },
    {
        path: '/settings/screening/templates/:id([0-9]+)/application',
        component: Loader(() => import('~/containers/settings/screening/templates/Application'))
    },
    {
        path: '/settings/screening',
        component: Loader(() => import('~/containers/settings/Screening'))
    },
    {
        path: '/settings/screening/addendum/templates',
        component: Loader(() => import('~/containers/settings/screening/addenda/List'))
    },
    {
        path: '/settings/screening/addendum/templates/:id([0-9]+)/builder',
        component: Loader(() => import('~/containers/settings/screening/addenda/Builder'))
    },
    {
        path: '/settings/screening/addendum/templates/:id([0-9]+)/pages',
        component: Loader(() => import('~/containers/settings/screening/addenda/Pages'))
    },
    {
        path: '/settings/leasing',
        component: Loader(() => import('~/containers/settings/Leasing'))
    },
    {
        path: '/settings/account',
        component: Loader(() => import('~/containers/settings/account/Account'))
    },
    {
        path: '/settings/account/users',
        component: Loader(() => import('~/containers/settings/account/Users'))
    },
    {
        path: '/settings/account/notifications',
        component: Loader(() => import('~/containers/settings/account/notifications/View'))
    },
    {
        path: '/settings/account/personal-settings',
        component: Loader(() => import('~/containers/settings/account/notifications/UserSettings'))
    },
    {
        path: '/reminders',
        component: Loader(() => import('~/containers/settings/account/reminders/List'))
    },
    {
        path: '/reminders/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/account/reminders/View'))
    },
    {
        path: '/settings/custom-field-categories',
        component: Loader(() => import('~/containers/settings/custom-field-categories/List'))
    },
    {
        path: '/settings/custom-field-categories/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/custom-field-categories/View'))
    },
    {
        path: '/settings/snippets',
        component: Loader(() => import('~/containers/settings/snippets/List'))
    },
    // Check Images
    {
        path: '/settings/check-images',
        component: Loader(() => import('~/containers/settings/check-images/List'))
    },
    // Check Templates
    {
        path: '/settings/check-templates',
        component: Loader(() => import('~/containers/settings/checks/List'))
    },
    {
        path: '/settings/check-templates/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/checks/CheckTemplate'))
    },
    {
        path: '/settings/check-templates/add',
        component: Loader(() => import('~/containers/settings/checks/CheckTemplate'))
    },
    // Letter Templates
    {
        path: '/settings/letter-templates',
        component: Loader(() => import('~/containers/settings/letters/List'))
    },
    {
        path: '/settings/letter-templates/add',
        component: Loader(() => import('~/containers/settings/letters/Add'))
    },
    {
        path: '/settings/letter-templates/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/letters/View'))
    },
    {
        path: '/settings/letter-templates/:id([0-9]+)/edit',
        component: Loader(() => import('~/containers/settings/letters/Edit'))
    },
    // Email Template Builder
    {
        path: '/settings/email-templates',
        component: Loader(() => import('~/containers/settings/email-templates/List'))
    },
    {
        path: '/settings/email-templates/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/email-templates/View'))
    },
    {
        path: '/settings/email-templates/add',
        component: Loader(() => import('~/containers/settings/email-templates/Add'))
    },
    {
        path: '/settings/email-templates/:id([0-9]+)/edit',
        component: Loader(() => import('~/containers/settings/email-templates/Edit'))
    },
    // Files
    {
        path: '/files',
        component: Loader(() => import('~/containers/settings/files/List'))
    },
    {
        path: '/files/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/files/View'))
    },
    {
        path: '/settings/inspections/items',
        component: Loader(() => import('~/containers/settings/inspections/items/List'))
    },
    {
        path: '/settings/inspections/actions',
        component: Loader(() => import('~/containers/settings/inspections/actions/List'))
    },
    {
        path: '/settings/inspections/defaults',
        component: Loader(() => import('~/containers/settings/inspections/defaults/List'))
    },
    {
        path: '/settings/inspections/defaults/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/inspections/defaults/View'))
    },
    {
        path: '/settings/vendor-trades',
        component: Loader(() => import('~/containers/settings/vendor-trades/List'))
    },
    {
        path: '/settings/vendor-types',
        component: Loader(() => import('~/containers/settings/vendor-types/List'))
    },
    // Portal settings
    {
        path: '/settings/portal-settings',
        component: Loader(() => import('~/containers/settings/portal-settings/View'))
    },
    {
        path: '/settings/portal-settings/resident',
        component: Loader(() => import('~/containers/settings/portal-settings/Resident'))
    },
    {
        path: '/settings/portals/invitations/batch',
        component: Loader(() => import('~/containers/settings/invitations/Batch'))
    },
    // email signature
    {
        path: '/settings/email-signature',
        component: Loader(() => import('~/containers/settings/email-signature/View'))
    },
    {
        path: '/settings/error-logging',
        component: Loader(() => import('~/containers/settings/ErrorLogging'))
    },
    // tags
    {
        path: '/settings/tags',
        component: Loader(() => import('~/containers/settings/tags/controllers/TagsListViewController'))
    },
    // Webhooks
    {
        path: '/settings/webhooks',
        component: Loader(() => import('~/containers/settings/webhooks/List'))
    },
    // property
    {
        path: '/settings/property-groups',
        component: Loader(() => import('~/containers/properties/groups/viewControllers/PropertyGroupsListViewController'))
    },
    {
        path: '/settings/property-groups/:id([0-9]+)',
        component: Loader(() => import('~/containers/properties/groups/viewControllers/GroupsViewController'))
    },
    {
        path: '/settings/property-managers',
        component: Loader(() => import('~/containers/settings/property-managers/List'))
    },
    {
        path: '/settings/pm-diagnostics',
        component: Loader(() => import('~/containers/settings/property-managers/Diagnostics'))
    },
    {
        path: '/settings/property-custom-features',
        component: Loader(() => import('~/containers/settings/custom-features/List'))
    },
    {
        path: '/settings/marketing-documents',
        component: Loader(() => import('~/containers/settings/marketing-documents/controllers/MarketingDocuments'))
    },
    {
        path: '/settings/marketing',
        component: Loader(() => import('~/containers/settings/marketing/Settings'))
    },
    {
        path: '/settings/property-utilities',
        component: Loader(() => import('~/containers/settings/property-utilities/List'))
    },
    // Document
    {
        path: '/settings/document/settings',
        component: Loader(() => import('~/containers/settings/document/Settings'))
    },
    {
        path: '/settings/document/templates',
        component: Loader(() => import('~/containers/settings/document/templates/List'))
    },
    {
        path: '/settings/document/templates/:id([0-9]+)',
        component: Loader(() => import('~/containers/settings/document/templates/View'))
    },
    // Insurance
    {
        path: '/settings/insurance',
        component: Loader(() => import('~/containers/settings/insurance/List'))
    },
    // Lease
    {
        path: '/settings/lease',
        component: Loader(() => import('~/containers/settings/lease/Settings'))
    },
    // Portfolio
    {
        path: '/settings/portfolio',
        component: Loader(() => import('~/containers/settings/portfolio/Settings'))
    }
];

export default routes;
