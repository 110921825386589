import { Typography } from '@mui/material';

import { ResultItem } from '../Result';

export default function Portfolio({ value }) {
    return (
        <ResultItem icon="folder_copy" to={`/portfolios/${value.portfolioID}`}>
            <Typography>{value.name}</Typography>
        </ResultItem>
    );
}
