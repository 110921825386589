import { Typography } from '@mui/material';

import Address from 'Lib/components/common/Address';
import isEmpty from 'Lib/utilities/isEmpty';

import Portfolio from './items/Portfolio';
import Tenants from './items/Tenants';
import { ResultContainer, ResultExpanded, ResultFooter, ResultItem, ResultTitle } from './Result';

export default function PropertyResult(props) {
    const { address, lease, previousLease, portfolio } = props.meta;
    const path = `/properties/${props.objectID}`;

    return (
        <ResultContainer {...props}>
            <ResultTitle icon="home_work" expanded={props.expanded} label={props.title} maxLength={45} to={path} indexTypeID={props.indexTypeID} />
            <ResultItem>
                <Address value={address} showCountry={false} displayInline={true} />
            </ResultItem>
            <ResultExpanded expanded={props.expanded}>
                <Portfolio value={portfolio} />
                <Tenants value={lease} />
                {!isEmpty(previousLease) && (
                    <ResultItem icon="assignment" sx={{ mb: 2 }} to={`/leases/${previousLease?.leaseID}`}>
                        <Typography>Previous Lease</Typography>
                    </ResultItem>
                )}
            </ResultExpanded>
            <ResultFooter
                to={path}
                actions={[
                    { icon: 'add', label: 'Work Order', to: `/maintenance/work-orders/add?propertyID=${props.objectID}` },
                    { icon: 'add', label: 'Bill', to: `/accounting/payables/bills/add?propertyID=${props.objectID}` }
                ]}
            />
        </ResultContainer>
    );
}
