import { VIEWS } from 'Lib/components/common/table/controls/TableView';

import * as actionTypes from './actionTypes';

export const initialState = {
    activitiesRefreshCount: 0,
    watchersRefreshCount: 0,
    drawerContentTypeID: null,
    pinnedContentTypeID: null,
    isDrawerOpen: false,
    menuVisible: false,
    savedSearches: [],
    searches: [],
    table: {
        listTypeID: null,
        listViewID: JSON.parse(localStorage?.getItem('tableView') ?? VIEWS.LIST),
        rows: [],
        columns: [],
        pageSize: JSON.parse(localStorage?.getItem('tablePageSize') ?? 15)
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_MENU:
            return {
                ...state,
                menuVisible: action.isVisible
            };
        case actionTypes.SET_SAVED_SEARCHES:
            return {
                ...state,
                savedSearches: action.savedSearches
            };
        case actionTypes.SET_TABLE_INFORMATION:
            return {
                ...state,
                table: {
                    ...state.table,
                    ...action.table
                }
            };
        case actionTypes.SET_TABLE_VIEW:
            return {
                ...state,
                table: {
                    ...state.table,
                    listViewID: action.tableViewID
                }
            };
        case actionTypes.TOGGLE_PINNED_DRAWER:
            return {
                ...state,
                pinnedContentTypeID: state.pinnedContentTypeID === action.pinnedContentTypeID ? null : action.pinnedContentTypeID,
                drawerContentTypeID: null,
                isDrawerOpen: false
            };
        case actionTypes.CLOSE_PINNED_DRAWER:
            return {
                ...state,
                pinnedContentTypeID: null,
                drawerContentTypeID: null,
                isDrawerOpen: false
            };
        case actionTypes.PIN_DRAWER:
            return {
                ...state,
                pinnedContentTypeID: action.pinnedContentTypeID,
                drawerContentTypeID: null,
                isDrawerOpen: false
            };
        case actionTypes.TOGGLE_DRAWER:
            return {
                ...state,
                drawerContentTypeID: action.drawerContentTypeID ? action.drawerContentTypeID : state.drawerContentTypeID,
                isDrawerOpen: !state.isDrawerOpen
            };
        case actionTypes.SET_DRAWER_OPEN:
            return {
                ...state,
                isDrawerOpen: action.isDrawerOpen
            };
        case actionTypes.SET_SEARCH:
            // TODO: [RENTVINE-8378] Add listTypeID to searches to only show searches for the current view.

            // Verify action.search is a string with at least one valid non-space character
            if (typeof action.search !== 'string' || !action.search.trim() || state.searches.includes(action.search)) {
                return {
                    ...state,
                    searches: [...state.searches]
                };
            }

            return {
                ...state,
                searches: [action.search, ...state.searches].filter(search => search)
            };
        case actionTypes.REFRESH_ACTIVITIES:
            return {
                ...state,
                activitiesRefreshCount: state.activitiesRefreshCount + 1
            };
        case actionTypes.REFRESH_WATCHERS:
            return {
                ...state,
                watchersRefreshCount: state.watchersRefreshCount + 1
            };
        case actionTypes.SET_TABLE_PAGE_SIZE:
            return {
                ...state,
                table: {
                    ...state.table,
                    pageSize: action.pageSize
                }
            };
        default:
            return state;
    }
};

export default reducer;
