import { Component } from 'react';

import isEmpty from 'Lib/utilities/isEmpty';

class Email extends Component {
    getEmailFromJson = emails => {
        if (isEmpty(emails)) {
            return;
        }

        if (typeof emails === 'string') {
            emails = JSON.parse(emails);
        }

        if (!Array.isArray(emails)) {
            return;
        }

        if (emails.length === 0) {
            return;
        }

        return emails[0].email;
    };

    render() {
        const { email, json } = this.props;
        let value = email;

        if (isEmpty(value) && !isEmpty(json)) {
            value = this.getEmailFromJson(json);
        }

        if (isEmpty(value)) {
            return '--';
        }

        return <span>{value}</span>;
    }
}

export default Email;
