// @ts-check
import WorkOrderProjectStatuses from 'Lib/constants/maintenance/workOrderProjectStatuses';

import Card, { Body, Description, Subtitle } from '../CardComponents';

/** @param {{result: WorkOrderProjectResult}} param0 */
export default function WorkOrderProject({ result }) {
    const cardType = 'Work Order Project';

    const { data, highlights } = result;

    const {
        workOrderProjectNumber: highlightedWorkOrderProjectNumber,
        name: highlightedName,
        'address.full.shingled': highlightedAddressShingled,
        'address.streetNumber': highlightedStreetNumber,
        'address.streetName': highlightedStreetName,
        'address.address2': highlightedAddress2,
        'address.city': highlightedCity,
        'address.state': highlightedState,
        'address.postalCode': highlightedPostalCode
    } = highlights;

    const highlightedAddress =
        highlightedAddressShingled?.[0][0] ??
        [
            highlightedStreetNumber?.[0][0] || data.address.streetNumber,
            highlightedStreetName?.[0][0] || data.address.streetName,
            highlightedAddress2?.[0][0] || data.address.address2,
            highlightedCity?.[0][0] || data.address.city,
            highlightedState?.[0][0] || data.address.state,
            highlightedPostalCode?.[0][0] || data.address.postalCode
        ]
            .filter(Boolean)
            .join(' ');

    const title = `[${data.workOrderProjectNumber}] ${data.name}`;
    const highlightedTitle = `[${highlightedWorkOrderProjectNumber || data.workOrderProjectNumber}] ${highlightedName || data.name}`;

    return (
        <Card.Parent path={`/maintenance/projects/${data.workOrderProjectID}`} cardType={cardType}>
            <Card.Status status={WorkOrderProjectStatuses[data.workOrderProjectStatusID]} />
            <Card.Content>
                <Card.Header title={title} highlight={highlightedTitle} cardType={cardType} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item value={data.address.full} highlight={highlightedAddress} />
                    </Subtitle.Parent>

                    <Description>{data.description}</Description>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
