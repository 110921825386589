// @ts-check
import { useReducer } from 'react';

import LoadingIndicator from 'Lib/muiComponents/common/LoadingIndicator';
import isEmpty from 'Lib/utilities/isEmpty';

import EmptyNotifications from './components/Empty';
import NotificationList from './components/list/List';
import MenuButton from './components/MenuButton';
import Search from './components/Search';
import ViewSwitcher from './components/ViewSwitcher';
import useNotifications, { useReadNotifications, useUnreadNotifications } from './data/useNotifications';
import Detail from './views/Detail';
import Modal from './views/Modal';
import NotificationPopperContainer from './views/Popper';
import SidebarWrapper from './views/Sidebar';
import { initialState, NotificationsContext, reducer } from './context';

export default function Index(props) {
    /** @type {[state: NotificationsState, dispatch: import('react').DispatchWithoutAction]} */
    const [state, dispatch] = useReducer(reducer, initialState);

    const { data: notifications = [], isPending, isError } = useNotifications();
    const { data: unreadNotifications = [] } = useUnreadNotifications();
    const { data: readNotifications = [] } = useReadNotifications();

    return (
        <NotificationsContext.Provider value={{ state, dispatch }}>
            {/* Menu Button */}
            <MenuButton {...props} />

            {/* Popper */}
            <NotificationPopperContainer>
                {isPending ? (
                    <LoadingIndicator />
                ) : isError ? (
                    <EmptyNotifications message="There was an error fetching your notifications." header="Error" />
                ) : !isEmpty(state.selectedNotification) ? (
                    <Detail notification={state.selectedNotification} />
                ) : (
                    <NotificationList notifications={notifications.slice(0, 3)} />
                )}
            </NotificationPopperContainer>

            {/* Side Drawer */}
            <SidebarWrapper>
                {isPending ? (
                    <LoadingIndicator />
                ) : isError ? (
                    <EmptyNotifications message="There was an error fetching your notifications." header="Error" />
                ) : !isEmpty(state.pinnedNotification) ? (
                    <Detail notification={state.pinnedNotification} />
                ) : !isEmpty(state.selectedNotification) ? (
                    <Detail notification={state.selectedNotification} />
                ) : (
                    <>
                        <Search />
                        <ViewSwitcher />
                        <NotificationList notifications={getActiveNotifications(state.detailView, state.searchQuery, notifications, unreadNotifications, readNotifications)} />
                    </>
                )}
            </SidebarWrapper>

            {/* Modal */}
            <Modal>
                {isPending ? (
                    <LoadingIndicator />
                ) : isError ? (
                    <EmptyNotifications message="There was an error fetching your notifications." header="Error" />
                ) : !isEmpty(state.selectedNotification) ? (
                    <Detail notification={state.selectedNotification} />
                ) : (
                    <>
                        <Search />
                        <ViewSwitcher />
                        <NotificationList notifications={getActiveNotifications(state.detailView, state.searchQuery, notifications, unreadNotifications, readNotifications)} />
                    </>
                )}
            </Modal>
        </NotificationsContext.Provider>
    );
}

// Decides active notifications based on state in context.
/**
 * @param {NotificationsState["detailView"]} detailView
 * @param {NotificationsState["searchQuery"]} searchQuery
 * @param {NotificationReturn[]} notifications
 * @param {NotificationReturn[] | []} unreadNotifications
 * @param {NotificationReturn[] | []} readNotifications
 * @returns {NotificationReturn[] | []}
 */
const getActiveNotifications = (detailView, searchQuery, notifications, unreadNotifications, readNotifications) => {
    // get the right set of notifications based on the detail view.
    const detailViewNotifications = detailView === 'all' ? notifications : detailView === 'unread' ? unreadNotifications : readNotifications;

    // if we're in the side drawer or pinned container, we need to filter the notifications based on the search query if it exists.
    if (searchQuery !== '')
        return detailViewNotifications?.filter(({ notification }) => {
            if (notification?.subject.toLowerCase().includes(searchQuery.toLowerCase()) || notification.body.toLowerCase().includes(searchQuery.toLowerCase())) {
                return true;
            }
        });

    // if no search exists, return detail view.
    return detailViewNotifications;
};
