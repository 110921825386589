import { useHistory } from 'react-router';
import { List, ListItemIcon } from '@mui/material';

import Dialog from 'Lib/muiComponents/dialog/Dialog';

import { SelectableListItemText, UserOptionButton } from './StyledComponents';

export default function SelectPortal({ isVendor, isOwner, isTenant, open, portal: currentPortal, portals, onChangePortal, togglePortalsModal }) {
    const history = useHistory();

    return (
        <Dialog toggle={togglePortalsModal} open={open} title="Switch Contacts">
            <List sx={{ pt: 0 }}>
                {portals?.map((portal, index) => {
                    let selectedPortal = null;

                    if (isOwner) selectedPortal = portal.owner.contactID == currentPortal.owner.contactID;
                    else if (isTenant) selectedPortal = portal.tenant.contactID == currentPortal.tenant.contactID;
                    else if (isVendor) selectedPortal = portal.vendor.contactID == currentPortal.vendor.contactID;

                    return (
                        <UserOptionButton
                            key={index}
                            onClick={() => {
                                onChangePortal(portal);
                                history.push('/');
                                togglePortalsModal();
                            }}
                            sx={{ borderRadius: 2, bgcolor: 'common.background', mb: 1 }}
                        >
                            {selectedPortal && (
                                <ListItemIcon sx={{ color: 'success.main' }}>
                                    <span className="material-symbols-rounded">task_alt</span>
                                </ListItemIcon>
                            )}
                            {isTenant && <SelectableListItemText primary={portal.tenant?.name} selected={selectedPortal} />}
                            {isOwner && <SelectableListItemText primary={portal.owner?.name} selected={selectedPortal} />}
                            {isVendor && <SelectableListItemText primary={portal.vendor?.name} selected={selectedPortal} />}
                        </UserOptionButton>
                    );
                })}
            </List>
        </Dialog>
    );
}
