import { useEffect, useState } from 'react';
import Button from 'reactstrap/lib/Button';

import DatePickerField from 'Lib/components/form/DatePickerField';
import DayDueField from 'Lib/components/form/DayDueField';
import useApi from 'Lib/hooks/useApi';
import isEmpty from 'Lib/utilities/isEmpty';

import Prorate from '../accounting/Prorate';

import CurrencyField from './CurrencyField';
import FormModal from './FormModal';
import SavingButton from './SavingButton';

const ProrateModal = props => {
    const [isEditing, setEdit] = useState(false);
    const [isSaving, setSaving] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [prorate, setProrate] = useState({});

    useEffect(() => {
        setShowModal(props.showModal);

        if (isEmpty(prorate) && props.showModal) {
            onSave({
                values: {
                    startDate: props.form.values.lease.moveInDate || null,
                    amount: props.fieldValue || null,
                    billingDay: 1
                }
            });
        }
    }, [props.showModal]);

    const getProrateAmount = useApi(props.calculateProrate);

    const onSave = ({ values }) => {
        setSaving(true);

        getProrateAmount(values)
            .then(response => {
                setProrate(response.data);
                setEdit(false);
                setSaving(false);
            })
            .catch(() => setSaving(false));
    };

    const setAmount = () => {
        props.form.setFieldValue(props.name, prorate.amount);

        setShowModal(false);
        setProrate({});
    };

    const toggleModal = () => {
        if (showModal) props.form.setFieldValue(props.dependent, 0);
        setShowModal(!showModal);
    };

    const renderModal = () => {
        return (
            <FormModal
                size="lg"
                title="Calculate Prorated Amount"
                isOpen={showModal}
                toggle={toggleModal}
                initialValues={{
                    startDate: props.form.values.lease.moveInDate || null,
                    amount: props.fieldValue || null,
                    billingDay: 1
                }}
                renderButtons={form => (
                    <>
                        {isSaving ? (
                            <SavingButton text="Calculating..." />
                        ) : !isEditing ? (
                            <div style={{ display: 'flex', flex: '1', justifyContent: 'space-between' }}>
                                <div>
                                    <Button onClick={() => setEdit(!isEditing)}>Edit</Button>
                                </div>
                                <div>
                                    <Button color="primary" onClick={setAmount}>
                                        Set Prorate
                                    </Button>
                                    {'   '}
                                    <Button onClick={toggleModal}>Cancel</Button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <Button color="primary" onClick={() => onSave(form)}>
                                    Calculate
                                </Button>
                                <Button onClick={() => setEdit(false)}>Back</Button>
                            </>
                        )}
                    </>
                )}
                render={() => {
                    if (!isEditing) {
                        return <Prorate prorate={prorate} />;
                    }

                    return (
                        <>
                            <CurrencyField id="amount" name="amount" label="Charge Amount" autoFocus />
                            <DatePickerField id="startDate" name="startDate" label="Move In Date" />
                            <DayDueField id="billingDay" name="billingDay" label="Day Due" />
                        </>
                    );
                }}
            />
        );
    };

    return (
        <>
            {props.showButton && (
                <Button className={props.class} outline onClick={toggleModal}>
                    Prorate
                </Button>
            )}
            {renderModal()}
        </>
    );
};

export default ProrateModal;
