import update from 'immutability-helper';

import PortalTypes from 'Lib/constants/app/portalTypes';

import * as actionTypes from './actionTypes';

export const initialState = {
    isInitialized: false,
    portalTypeID: PortalTypes.Manager
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT:
            return {
                ...state,
                ...action.app
            };
        case actionTypes.DISMISS_SYSTEM_ALERTS:
            return update(state, {
                systemAlerts: {
                    $set: []
                }
            });
        default:
            return state;
    }
};

export default reducer;
