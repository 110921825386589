import { useHistory } from 'react-router';
import { Chip, List, ListItemIcon } from '@mui/material';
import { Box } from '@mui/system';

import PrimaryLeaseStatuses from 'Lib/constants/leases/primaryLeaseStatuses';
import Dialog from 'Lib/muiComponents/dialog/Dialog';
import { limitLength } from 'Lib/utilities/limitLength';

import { SelectableListItemText, UserOptionButton } from './StyledComponents';

const sortLeases = leases => {
    return leases
        .sort((a, b) => {
            if (a.unit.address.replace(/[0-9]/g, '') < b.unit.address.replace(/[0-9]/g, '')) {
                return -1;
            }
            if (a.unit.address.replace(/[0-9]/g, '') > b.unit.address.replace(/[0-9]/g, '')) {
                return 1;
            }

            return 0;
        })
        .sort((a, b) => parseInt(a.lease.primaryLeaseStatusID) - parseInt(b.lease.primaryLeaseStatusID));
};

export default function SelectLease({ portal, lease: currentLease, open, onChangeLease, toggleLeasesModal }) {
    const sortedLeases = sortLeases(portal?.leases);

    const history = useHistory();

    return (
        <Dialog toggle={toggleLeasesModal} open={open} title="Switch Leases">
            <List sx={{ pt: 0 }}>
                {sortedLeases.map(({ lease, unit }, index) => {
                    const selectedLease = lease.leaseID == currentLease.lease.leaseID;
                    const chipColor =
                        lease.primaryLeaseStatusID == PrimaryLeaseStatuses.Active ? 'success' : lease.primaryLeaseStatusID == PrimaryLeaseStatuses.Pending ? 'warning' : 'error';

                    return (
                        <UserOptionButton
                            key={index}
                            onClick={() => {
                                onChangeLease({ lease, unit });
                                history.push('/');
                                toggleLeasesModal();
                            }}
                            sx={{ borderRadius: 2, bgcolor: 'common.background', mb: 1 }}
                        >
                            {selectedLease && (
                                <ListItemIcon sx={{ color: 'success.main' }}>
                                    <span className="material-symbols-rounded">task_alt</span>
                                </ListItemIcon>
                            )}
                            <SelectableListItemText
                                primary={
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <p>
                                            {limitLength(unit.address, 20)},{unit.address2 ? limitLength(unit.address2, 20) : null}
                                        </p>
                                        <Chip size="small" label={PrimaryLeaseStatuses.getName(lease.primaryLeaseStatusID)} color={chipColor} />
                                    </Box>
                                }
                                selected={selectedLease}
                            />
                        </UserOptionButton>
                    );
                })}
            </List>
        </Dialog>
    );
}
