// @ts-check
import { Icon } from '@mui/material';

import Card, { Body, Description, ReviewButton } from '../CardComponents';
export default function AIResult({ result }) {
    const cardType = 'Rentvine Assist';

    return (
        <Card.Parent path={result.link} cardType={cardType}>
            <Icon variant="filled" sx={{ position: 'absolute', top: -6, right: -4 }}>
                auto_awesome
            </Icon>
            <Card.Content>
                <Card.Header title="" cardType={cardType} />
                <Body>
                    <Description>{result.message}</Description>
                    <ReviewButton path={result.link} />
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
