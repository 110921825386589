// @ts-check
import HtmlReactParser from 'html-react-parser';

import Date from 'Lib/components/common/Date';
import PaymentTypes from 'Lib/constants/accounting/payments/paymentTypes';
import TransactionTypes from 'Lib/constants/accounting/transactionTypes';
import isEmpty from 'Lib/utilities/isEmpty';
import stripHtml from 'Lib/utilities/stripHtml';

import Card, { Body, Description, Subtitle } from '../CardComponents';

/** @param {{result: TransactionReceiptResult}} param0 */
export default function TransactionReceipt({ result }) {
    const { data, highlights } = result;

    const CardDetails = {
        [TransactionTypes.LeasePayment]: {
            cardType: 'Tenant Receipt',
            transactionUrl: `/accounting/receivables/tenant/receipt/${data.transactionID}`
        },
        [TransactionTypes.OwnerPayment]: {
            cardType: 'Owner Receipt',
            transactionUrl: `/accounting/receivables/owner/receipt/${data.transactionID}`
        },
        [TransactionTypes.OtherPayment]: {
            cardType: 'Other Receipt',
            transactionUrl: `/accounting/receivables/other/receipt/${data.transactionID}`
        }
    };

    const { cardType, transactionUrl } = CardDetails[data.transactionTypeID] || {};

    const highlightedAmount = highlights['amount.keyword']?.[0][0];

    const paymentType = PaymentTypes.getName(data.paymentTypeID);

    const parsedReference = HtmlReactParser(highlights.reference?.[0]?.[0] || highlights['reference.alphanumeric']?.[0]?.[0] || '');
    const reference = !isEmpty(parsedReference) ? parsedReference : data.reference;

    const dataPayerName = data.receivedFrom ?? data.contact.name;
    const highlightPayerNames = [highlights.receivedFrom?.[0]?.[0] ?? highlights['contact.name']?.[0]?.[0]];
    const highlightedPayerName = highlightPayerNames.find(highlightPayerName => stripHtml(highlightPayerName) === dataPayerName) ?? '';

    const parsedPayerName = HtmlReactParser(highlightedPayerName || '');
    const payerName = !isEmpty(parsedPayerName) ? parsedPayerName : dataPayerName;

    let statusText = '';
    let statusVariant = '';

    if (data.isVoided) {
        statusText = 'Voided';
        statusVariant = 'danger';
    } else {
        if (!isEmpty(data.depositID)) {
            statusText = 'Deposited';
        } else if (!isEmpty(data.settlementID)) {
            statusText = 'Settled';
        }

        if (!isEmpty(data.returnTransactionID)) {
            statusText += ': Returned';
            statusVariant = 'danger';
        }
    }

    return (
        <Card.Parent path={transactionUrl} cardType={cardType}>
            {!isEmpty(statusText) && <Card.Status status={statusText} variant={statusVariant} />}

            <Card.Content>
                <Card.Header title={`$${data.amount.amount}`} highlight={highlightedAmount} forceHighlight cardType={cardType} subtype={paymentType} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Item>
                            <Date value={data.datePosted} />
                            {!isEmpty(reference) && (
                                <>
                                    , Reference #:&nbsp;
                                    {reference}
                                </>
                            )}
                        </Subtitle.Item>
                    </Subtitle.Parent>

                    <Description>{!isEmpty(payerName) && <>Payer: {payerName}&nbsp;</>}</Description>
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
