import { Component } from 'react';
import moment from 'moment';

import DatePicker from './DatePicker';
import Field from './Field';

import 'moment-timezone';

class DatePickerField extends Component {
    state = {
        setValue: false
    };
    field = null;
    form = null;

    handleChange = (date, form, field, isCalendarOpen) => {
        const possibleDate = moment(date);

        if (!possibleDate.isValid()) {
            form.setFieldValue(field.name, null);

            return;
        }

        form.setFieldValue(field.name, possibleDate.format('MM/DD/YYYY'));

        if (this.props.onChange) {
            this.props.onChange(possibleDate.format('MM/DD/YYYY'), form, field, isCalendarOpen);
        }
    };

    handleBlurRaw = date => {
        if (this.props.onBlurRaw) {
            this.props.onBlurRaw(date);
        }
    };

    handleBlur = date => {
        if (this.props.onBlur) {
            this.props.onBlur(date);
        }
    };

    handleKeyDown = (e, form, field) => {
        let value = null;
        let didChange = false;

        switch (e.key) {
            case 'ArrowUp':
                value = moment(field.value).add(1, 'days').format('MM/DD/YYYY');

                form.setFieldValue(field.name, value);

                this.setState({
                    setValue: true
                });
                didChange = true;
                break;
            case 'ArrowDown':
                value = moment(field.value).subtract(1, 'days').format('MM/DD/YYYY');

                form.setFieldValue(field.name, value);

                this.setState({
                    setValue: true
                });
                didChange = true;
                break;
            case 't':
                value = moment().format('MM/DD/YYYY');

                form.setFieldValue(field.name, value);

                this.setState({
                    setValue: true
                });
                didChange = true;
                break;
            case 'm':
                value = moment(field.value).add(1, 'months').format('MM/DD/YYYY');

                form.setFieldValue(field.name, value);

                this.setState({
                    setValue: true
                });
                didChange = true;
                break;
            case 'l':
                value = moment(field.value).subtract(1, 'months').format('MM/DD/YYYY');

                form.setFieldValue(field.name, value);

                this.setState({
                    setValue: true
                });
                didChange = true;
                break;
            case 'y':
                value = moment(field.value).add(1, 'years').format('MM/DD/YYYY');

                form.setFieldValue(field.name, value);

                this.setState({
                    setValue: true
                });
                didChange = true;
                break;
            case 'p':
                value = moment(field.value).subtract(1, 'years').format('MM/DD/YYYY');

                form.setFieldValue(field.name, value);

                this.setState({
                    setValue: true
                });
                didChange = true;
                break;
            default:
                this.setState({
                    setValue: false
                });
                break;
        }

        if (didChange && this.props.onChange) {
            this.props.onChange(value, form, field);
        }
    };

    renderInput = props => {
        const { form, field } = props;
        const { id, className, ...rest } = this.props;

        const attrs = {
            ...rest,
            id,
            className,
            name: field.name
        };

        if (attrs.className) {
            attrs.className = 'form-control ' + attrs.className;
        } else {
            attrs.className = 'form-control';
        }

        return (
            <DatePicker
                {...attrs}
                setValue={this.state.setValue}
                value={field.value}
                onBlurRaw={this.handleBlurRaw}
                onBlur={this.handleBlur}
                onChange={(date, isCalendarOpen) => this.handleChange(date, form, field, isCalendarOpen)}
                onKeyDown={e => this.handleKeyDown(e, form, field)}
            />
        );
    };

    render() {
        return <Field {...this.props} fast={false} render={props => this.renderInput(props)} />;
    }
}

export default DatePickerField;
