import Enumeration from 'Lib/utilities/Enumeration';

const ReportCategories = new Enumeration([
    { id: 1, name: 'Owner' },
    { id: 2, name: 'Tenant' },
    { id: 3, name: 'Vendor' },
    { id: 4, name: 'Accounting' },
    { id: 5, name: 'Maintenance' },
    { id: 6, name: 'Property' },
    { id: 7, name: 'Lease' },
    { id: 8, name: 'Portfolio' },
    { id: 9, name: 'General' },
    { id: 10, name: 'Screening' },
    { id: 11, name: 'RentSign' }
]);

export default ReportCategories;
