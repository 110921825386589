import isString from 'Lib/utilities/isString';

const DELIMITER = '!';
const WILDCARD = '*';
const ALLOW = 1;

class Acl {
    componentsByName = {};
    accessList = {};
    access = {};

    constructor() {
        this.accessList[`${WILDCARD}${DELIMITER}${WILDCARD}`] = ALLOW;
        this.componentsByName[`${WILDCARD}`] = ALLOW;
    }

    addComponent = (componentName, accessList) => {
        if (!this.componentsByName[componentName]) {
            this.componentsByName[componentName] = componentName;
        }

        this.addComponentAccess(componentName, accessList);
    };

    addComponentAccess = (componentName, accessList) => {
        if (!this.componentsByName[componentName]) {
            throw 'Component not found';
        }

        if (!Array.isArray(accessList) && !isString(accessList)) {
            throw 'Invalid Access List';
        }

        if (!Array.isArray(accessList)) {
            accessList = [accessList];
        }

        accessList.forEach(accessName => {
            const accessKey = `${componentName}${DELIMITER}${accessName}`;

            if (!this.accessList[accessKey]) {
                this.accessList[accessKey] = true;
            }
        });

        return true;
    };

    allow = (componentName, access) => {
        if (!this.componentsByName[componentName]) return;
        let accessKey = null;

        if (Array.isArray(access)) {
            access.forEach(accessName => {
                accessKey = `${componentName}${DELIMITER}${accessName}`;

                if (!this.accessList[accessKey]) return;

                this.access[accessKey] = ALLOW;
            });
        } else {
            if (access != WILDCARD) {
                accessKey = `${componentName}${DELIMITER}${access}`;
            } else {
                accessKey = `${componentName}${DELIMITER}${WILDCARD}`;
            }

            this.access[accessKey] = ALLOW;
        }
    };

    isAllowed = (componentName, access) => {
        let haveAccess;

        const accessKey = this.canAccess(componentName, access);

        if (accessKey != false && this.access[accessKey]) {
            haveAccess = this.access[accessKey];
        }

        return haveAccess === ALLOW;
    };

    canAccess = (componentName, access) => {
        let accessKey = `${componentName}${DELIMITER}${access}`;

        if (this.access[accessKey]) return accessKey;

        accessKey = `${componentName}${DELIMITER}${WILDCARD}`;

        if (this.access[accessKey]) return accessKey;

        accessKey = `${WILDCARD}${DELIMITER}${WILDCARD}`;

        if (this.access[accessKey]) return accessKey;

        return false;
    };
}

export default Acl;
