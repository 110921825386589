import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse, IconButton, Stack, useMediaQuery, useScrollTrigger } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MuiBadge, { badgeClasses } from '@mui/material/Badge';
import MuiDrawer from '@mui/material/Drawer';
import drawerClasses from '@mui/material/Drawer/drawerClasses';
import Toolbar from '@mui/material/Toolbar';
import { Box, styled } from '@mui/system';

import icon from 'Lib/assets/rv-icon.png';
import PortalTypes from 'Lib/constants/app/portalTypes';

import Header from './components/Header';
import History from './components/history/History';
import Notifications from './components/notifications/index';
import QuickActions from './components/QuickActions';
import Search from './components/search/Search';
import Drawer from './Drawer';

export default function AppBar(props) {
    const [items, setItems] = useState([]);
    const trigger = useScrollTrigger({ threshold: 0 });

    const history = useHistory();
    const xsBreakpoint = '380';
    const smBreakpoint = '744';

    const barHeight = trigger ? '48px' : '64px';
    const renderSmallHeader = useMediaQuery(`(max-width:${smBreakpoint}px)`);

    useEffect(() => {
        setItems(props.items.filter(item => !item.Component));
    }, []);

    const openedMixin = theme => ({
        [theme.breakpoints.down(xsBreakpoint)]: {
            width: '100%'
        },
        width: `${xsBreakpoint}px`,
        background: theme.palette.navigation.background.main,
        zIndex: 1201,
        borderRadius: '0px 20px 20px 0px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        overflowX: 'hidden'
    });

    return (
        <>
            <MuiDrawer
                anchor="left"
                onClose={props.toggleDrawer}
                open={props.open}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={theme => ({
                    width: xsBreakpoint,
                    flexShrink: 0,
                    whiteSpace: 'nowrap',
                    boxSizing: 'border-box',
                    ...openedMixin(theme),
                    [`& .${drawerClasses.paper}`]: openedMixin(theme)
                })}
            >
                <Drawer {...props} items={items} full />
            </MuiDrawer>
            <MuiAppBar
                elevation={1}
                className={`animated-minimize ${trigger && 'minimized'}`}
                sx={{ bgcolor: 'navigation.background.main', height: barHeight, minHeight: '40px', zIndex: 1000 }}
            >
                <Toolbar disableGutters sx={{ height: '100%', minHeight: barHeight }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', minHeight: '40px' }}>
                        {/* Drawer Icon */}
                        <Box>
                            <IconButton aria-label="drawer" id="mobile-menu" onClick={props.toggleDrawer}>
                                menu
                            </IconButton>

                            {/* Uses the Icon logo variant on phones */}
                            {renderSmallHeader && <img src={icon} alt="Rentvine" height="24" width="24" onClick={() => history.push('/')} />}
                        </Box>

                        {/* Uses the RENTVINE logo variant on Tablets */}
                        {!renderSmallHeader && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                <Header appBar />
                            </Box>
                        )}

                        {/* Search Button Icon */}
                        <Box sx={{ alignSelf: 'center', mr: 1 }} className={`animated-minimize ${trigger && 'minimized-hidden'}`}>
                            {props.search && (
                                <Search search={props.search}>
                                    {({ onClick }) => (
                                        <IconButton aria-label="global search" onClick={onClick}>
                                            search
                                        </IconButton>
                                    )}
                                </Search>
                            )}

                            {/* Creates Component Buttons: Notifications, QuickActions, History */}
                            {props.portalTypeID === PortalTypes.Manager && (
                                <>
                                    <Notifications {...props}>
                                        {({ onClick }) => (
                                            <IconButton aria-label="notifications" onClick={onClick}>
                                                {props.badgeCount > 0 ? (
                                                    <NotificationBadge variant="dot" color="info" overlap="circular" sx={{ [`& .${badgeClasses.badge}`]: { mr: 0 } }}>
                                                        notifications
                                                    </NotificationBadge>
                                                ) : (
                                                    'notifications'
                                                )}
                                            </IconButton>
                                        )}
                                    </Notifications>
                                    <QuickActions {...props}>
                                        {({ onClick }) => (
                                            <IconButton aria-label="recently viewed" onClick={onClick}>
                                                rocket
                                            </IconButton>
                                        )}
                                    </QuickActions>
                                    <History {...props} placement="bottom-start">
                                        {({ onClick, setRef }) => (
                                            <IconButton aria-label="recently viewed" onClick={onClick} ref={setRef}>
                                                schedule
                                            </IconButton>
                                        )}
                                    </History>
                                </>
                            )}
                        </Box>
                    </Stack>
                </Toolbar>
            </MuiAppBar>
        </>
    );
}

const NotificationBadge = styled(MuiBadge)(({ theme }) => ({
    [`& .${badgeClasses.badge}`]: {
        marginTop: theme.spacing(1),
        marginRight: '2px',
        border: `1px solid ${theme.palette.common.lightgray}`,
        borderRadius: '50%',
        width: '12px',
        height: '12px'
    }
}));
