import { ResultContainer, ResultFooter, ResultTitle } from './Result';

export default function PortfolioResult(props) {
    const path = `/portfolios/${props.objectID}`;

    return (
        <ResultContainer disableExpand {...props}>
            <ResultTitle label={props.title} icon="folder_copy" indexTypeID={props.indexTypeID} to={path} />
            <ResultFooter
                to={path}
                actions={[
                    { icon: 'article', label: 'Statement', to: `${path}/?isCreatingStatement=true` },
                    { icon: 'mail', label: 'Email', to: `${path}/?tab=messages&composeEmail=true` },
                    { icon: '3p', label: 'Chat', to: `${path}/?tab=chat` },
                    { icon: 'receipt_long', label: 'Ledger', to: `/portfolios/${props.objectID}?tab=ledger` }
                ]}
            />
        </ResultContainer>
    );
}
