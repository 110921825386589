import { Component, createRef } from 'react';
import { NumericFormat } from 'react-number-format';

import Field from './Field';
import ProrateModal from './ProrateModal';

class CurrencyField extends Component {
    state = {
        value: null
    };
    input = createRef();

    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    handleChange = (e, form, field) => {
        this.setState({ value: null });

        if (this.props.onChange) {
            this.props.onChange(e, form, field);
        }

        if (!e) {
            form.setFieldValue(field.name, '');

            return;
        }

        form.setFieldValue(field.name, e.value);
    };

    getInputRef = element => {
        if (element) {
            if (this.props.disable == true) {
                element.disabled = true;
            }
        }
    };

    renderInput = props => {
        const { form, field, error } = props;
        const { allowNegative, id, className, required, prefix = '$', placeholder = ' ', prorate, styleOverride, readOnly } = this.props;

        if (field.value == null) {
            field.value = '';
        }

        const attrs = {
            id,
            className,
            required,
            prefix,
            name: field.name,
            value: field.value,
            placeholder,
            allowNegative,
            decimalScale: 2,
            thousandSeparator: ',',
            fixedDecimalScale: true
        };

        if (attrs.className) {
            attrs.className = 'form-control' + attrs.className;
        } else {
            attrs.className = 'form-control';
        }

        if (readOnly) {
            attrs.className = attrs.className + ' read-only';
        }

        if (this.state.value) {
            attrs.value = this.state.value;
        }

        if (error) {
            attrs.className = attrs.className + ' ' + 'is-invalid';
        }

        if (prorate) {
            return (
                <NumericFormat
                    {...attrs}
                    data-testid={this.props['data-testid']}
                    getInputRef={this.getInputRef}
                    onValueChange={e => this.handleChange(e, form, field)}
                    readOnly={readOnly}
                />
            );
        }

        return (
            <NumericFormat
                {...attrs}
                style={{
                    ...styleOverride
                }}
                data-testid={this.props['data-testid']}
                readOnly={readOnly ? true : ''}
                getInputRef={this.getInputRef}
                disabled={readOnly ? true : this.props.disable}
                onValueChange={e => this.handleChange(e, form, field)}
                autoComplete="off"
            />
        );
    };

    renderProrate = props => (
        <ProrateModal
            class="prorate-button"
            form={props.form}
            name={this.props.name}
            dependent={props.dependent}
            fieldValue={props.inputValue}
            calculateProrate={props.calculateProrate}
            showButton={props.showButton}
            showModal={this.props.showModal}
        />
    );

    render() {
        return (
            <Field
                {...this.props}
                key={this.props.showModal}
                className="form-field"
                render={props => (
                    <div style={{ display: 'flex' }}>
                        {this.renderInput(props)}
                        <div className={this.props.prorate ? 'with-prorate' : ''} style={{ display: 'flex' }}>
                            {this.props.prorate && this.props.children({ prorate: this.renderProrate, inputProps: { ...props } })}
                        </div>
                    </div>
                )}
            />
        );
    }
}

export default CurrencyField;
