// @ts-check

import { useSelector } from 'react-redux';
import { IconButton, Stack } from '@mui/material';
import MuiBadge, { badgeClasses } from '@mui/material/Badge';

import History from 'Lib/components/layout/components/history/History';
import { useBadgeCount } from 'Lib/components/layout/components/notifications/data/useNotifications';
import Notifications from 'Lib/components/layout/components/notifications/index';
import QuickActions from 'Lib/components/layout/components/QuickActions';
import User from 'Lib/components/layout/components/user/User';
import useConfig from 'Lib/hooks/api/useConfig';
import isEmpty from 'Lib/utilities/isEmpty';
import { screenSize } from 'Lib/utilities/screenSize';

export default function PrimaryActions() {
    const { app, session } = useSelector(state => state);
    const { mobile } = screenSize();

    const {
        portal: { isManager }
    } = useConfig();
    const badgeCount = isManager ? useBadgeCount() : 0;

    if (isEmpty(app.user)) {
        return;
    }

    return (
        <Stack direction="row" spacing={1}>
            <Notifications>
                {({ onClick }) => (
                    <IconButton aria-label="notifications" onClick={onClick}>
                        {badgeCount && badgeCount > 0 ? (
                            <MuiBadge variant="dot" color="info" overlap="circular" sx={{ [`& .${badgeClasses.badge}`]: { mr: 0 } }}>
                                notifications
                            </MuiBadge>
                        ) : (
                            'notifications'
                        )}
                    </IconButton>
                )}
            </Notifications>
            <QuickActions>
                {({ onClick }) => (
                    <IconButton aria-label="recently viewed" onClick={onClick}>
                        rocket
                    </IconButton>
                )}
            </QuickActions>
            <User app={app} portalAccounts={app.portalAccounts} portalTypeID={app.portalTypeID} session={session} placement={'header'} />
        </Stack>
    );
}
