import { Component } from 'react';
import { Formik } from 'formik';

import Notify from 'Lib/components/modals/Notify';
import isEmpty from 'Lib/utilities/isEmpty';

import './form.scss';

class Form extends Component {
    render() {
        const { initialValues = {}, validateOnChange = false, validateOnBlur = false, render, notify = {}, ...rest } = this.props;

        return (
            <Formik {...rest} initialValues={initialValues} validateOnChange={validateOnChange} validateOnBlur={validateOnBlur}>
                {formik => (
                    <>
                        {render(formik)}
                        {!isEmpty(notify) && <Notify {...notify} form={formik} />}
                    </>
                )}
            </Formik>
        );
    }
}

export default Form;
