import { Box, List } from '@mui/material';

import Footer from 'Lib/components/layout/components/sidedrawer/Footer';
import Header from 'Lib/components/layout/components/sidedrawer/Header';
import Title from 'Lib/components/layout/components/sidedrawer/Title';
import isFunction from 'Lib/utilities/isFunction';

export default function DrawerContent(props) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Box sx={theme => ({ padding: `0 ${theme.spacing(4)}` })}>
                <Box elevation={0} sx={{ pt: 3, position: 'sticky', top: 0, zIndex: 3, bgcolor: 'common.white' }}>
                    <Header>{isFunction(props.header) ? props.header() : props.header}</Header>
                    <Title titleIcon={props.titleIcon} title={props.titleHeader}>
                        {isFunction(props.title) ? props.title() : props.title}
                    </Title>
                </Box>
                <List sx={{ pt: 0 }}>{props.children}</List>
            </Box>
            <Box elevation={0} sx={{ py: 3, position: 'sticky', bottom: 0, zIndex: 1 }}>
                <Footer clearable={props.clearable} onSubmit={props.onSubmit}>
                    {isFunction(props.footer) ? props.footer() : props.footer}
                </Footer>
            </Box>
        </Box>
    );
}
