import { Fragment } from 'react';

import Phone from 'Lib/components/common/Phone';

import { ResultItem } from '../Result';

export default function Phones({ value, max = 2 }) {
    return value ? (
        <ResultItem icon="call">
            {value.slice(0, max).map(({ phone }, index) => (
                <Fragment key={index}>
                    <Phone value={phone} />
                    {value.length === index + 1 ? null : ', '}
                </Fragment>
            ))}
            {value.length > max ? ' ...' : null}
        </ResultItem>
    ) : null;
}
