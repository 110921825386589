import { createBrowserHistory } from 'history';
import { createStore } from 'redux';

import middleware from './middlewares';
import createRootReducer from './reducers';

const initialState = {};

export const history = createBrowserHistory();

const store = createStore(createRootReducer(history), initialState, middleware);

export default store;
