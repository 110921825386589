import { Component } from 'react';
import { formatPhoneNumber, formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input';

import isEmpty from 'Lib/utilities/isEmpty';

class Phone extends Component {
    render() {
        const { value, extension } = this.props;
		let extensionUrl = '';
		let extensionLabel = '';

        if (isEmpty(value)) {
            return '--';
        }

		if ( ! isEmpty(extension)) {
            extensionUrl = `p${extension}`;
			extensionLabel = ` x${extension}`;
        }
		
        const phoneNumber = parsePhoneNumber(value);

        if (phoneNumber) {
            if (phoneNumber.country == 'US') {
                return <a href={`tel:${value}${extensionUrl}`}>{formatPhoneNumber(value)}{extensionLabel}</a>;
            }
        }

        return <a href={`tel:${value}${extensionUrl}`}>{formatPhoneNumberIntl(value)}{extensionLabel}</a>;
    }
}

export default Phone;
