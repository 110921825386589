import { Chip, Typography } from '@mui/material';

import isEmpty from 'Lib/utilities/isEmpty';

import { ResultItem } from '../Result';

export default function Tenants({ value, maxTenantCount = 2 }) {
    return !value || isEmpty(value) ? (
        <ResultItem icon="location_home">
            <Chip
                variant="outlined"
                avatar={<span className="material-symbols-rounded">done</span>}
                sx={{
                    border: 1,
                    borderRadius: 2,
                    borderColor: 'success.main',
                    bgcolor: 'success.secondary',
                    height: '24px',
                    width: '98px',
                    span: theme => ({ color: `${theme.palette.success.main} !important` }),
                    '& .MuiChip-avatar': { display: 'flex', alignItems: 'center', ml: 2, mr: 2, fontSize: '20px' },
                    '& .MuiChip-label': { pl: 0 }
                }}
                label="Vacant"
            />
        </ResultItem>
    ) : (
        <ResultItem icon="location_home" to={`/leases/${value.leaseID}`}>
            {value.tenants
                ? value.tenants.slice(0, maxTenantCount).map((tenant, index) => (
                      <Typography key={index}>
                          {tenant}
                          {value.tenants?.length === index + 1 ? null : ', '}
                      </Typography>
                  ))
                : null}
            {value.tenants?.length > maxTenantCount ? ' ...' : null}
        </ResultItem>
    );
}
