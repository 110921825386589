import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import cache from './cache';
import history from './history';

import app from '~/services/app/reducer';
import dataCache from '~/services/cache/reducer';
import session from '~/services/session/reducer';
import timeline from '~/services/timeline/reducer';
import ui from '~/services/ui/reducer';

export default routerHistory =>
    combineReducers({
        app,
        history,
        cache,
        router: connectRouter(routerHistory),
        session,
        dataCache,
        ui,
        timeline
    });
