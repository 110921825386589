import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/billing',
        component: Loader(() => import('~/containers/company-invoices/controllers/List'))
    },
    {
        path: '/billing/:id([0-9]+)',
        component: Loader(() => import('~/containers/company-invoices/controllers/ViewController'))
    }
];

export default routes;
