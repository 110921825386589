// @ts-check

import PortalTypes from 'Lib/constants/app/portalTypes';

export default function useConfig() {
    const root_domain = CONFIG.domain;
    const apiRoot = `https://${window.location.host}${CONFIG.apiRoot}`;
    const portalTypeId = CONFIG.portalTypeId;
    const loginUrl = CONFIG.loginUrl;
    const client_id = CONFIG.clientId;

    // Faster way to do portal conditionals
    const portal = {
        isManager: PortalTypes.Manager === portalTypeId,
        isOwner: PortalTypes.Owner === portalTypeId,
        isTenant: PortalTypes.Tenant === portalTypeId,
        isVendor: PortalTypes.Vendor === portalTypeId,
        isApplicant: PortalTypes.Applicant === portalTypeId,
        isAssociation: PortalTypes.Association === portalTypeId,
        isAdmin: PortalTypes.Admin === portalTypeId,
        isPublic: PortalTypes.Public === portalTypeId
    };

    return { apiRoot, portalTypeId, portal, loginUrl, root_domain, client_id };
}

// const {portal: {isManager}} = useConfig();   // Destructuring
