import { Stack } from '@mui/material';

/**
 * Wrapper for optional modal header content
 * @param {JSX.Element} children
 * @returns
 */

export default function ModalHeader({ children, sx }) {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: [4, 4, 6], flexShrink: 0, flexGrow: 0, minHeight: 0, ...sx }}>
            {children}
        </Stack>
    );
}
