import { useContext } from 'react';
import { IconButton } from '@mui/material';

import { ModalContext } from './Modal';

/**
 * Wrapper for optional modal close component
 * @param {JSX.Element} children
 * @returns
 */

export default function ModalClose({ sx }) {
    // get handleClose from the ModalContext
    const { handleClose } = useContext(ModalContext);

    return (
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                color: 'success.main',
                alignSelf: 'flex-end',
                ...sx,
                '& .material-symbols-rounded': { fontSize: '24px !important' }
            }}
        >
            close
        </IconButton>
    );
}
