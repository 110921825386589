// @ts-check
import Enumeration from 'Lib/utilities/Enumeration';

const WorkOrderProjectStatuses = new Enumeration([
    { id: 1, key: 'Pending', name: 'Pending', color: 'secondary' },
    { id: 2, key: 'Open', name: 'Open', color: 'success' },
    { id: 3, key: 'Closed', name: 'Closed', color: 'danger' },
    { id: 4, key: 'OnHold', name: 'On Hold', color: 'warning' }
]);


export default WorkOrderProjectStatuses;
