import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';

import { screenSize } from 'Lib/utilities/screenSize';

export const VIEWS = { LIST: 1, CARD: 2, CALENDAR: 3 };

export const ListButton = ({ listViewID, setTableView }) => {
    const dispatch = useDispatch();

    return (
        <Tooltip
            title={
                <Box data-cy="table-list-view-tooltip" component="div">
                    List
                </Box>
            }
            arrow
            placement="bottom"
        >
            <Box>
                <IconButton data-cy="table-list-view-button" variant="regular" selected={listViewID === VIEWS.LIST} onClick={() => dispatch(setTableView(VIEWS.LIST))}>
                    format_list_bulleted
                </IconButton>
            </Box>
        </Tooltip>
    );
};

export const CardButton = ({ listViewID, setTableView }) => {
    const dispatch = useDispatch();

    return (
        <Tooltip
            title={
                <Box data-cy="table-card-view-tooltip" component="div">
                    Card
                </Box>
            }
            arrow
            placement="bottom"
        >
            <Box>
                <IconButton data-cy="table-card-view-button" variant="regular" selected={listViewID === VIEWS.CARD} onClick={() => dispatch(setTableView(VIEWS.CARD))}>
                    grid_view
                </IconButton>
            </Box>
        </Tooltip>
    );
};

export const CalendarButton = () => {
    const history = useHistory();

    return (
        <Tooltip title="Calendar" arrow placement="bottom">
            <Box>
                <IconButton variant="regular" onClick={() => history.push('/work-orders/schedule')}>
                    today
                </IconButton>
            </Box>
        </Tooltip>
    );
};

/*
    List/Card Views are almost always going to render but there are times where we will want to override this and only show
    the List and Calendar buttons/views (currently only in the Vendor Portal on the Work Orders list)
*/
export default function TableView({ setTableView, views = [VIEWS.LIST, VIEWS.CARD] }) {
    const listViewID = useSelector(state => state.ui.table.listViewID);

    const { mobile } = screenSize();

    const renderListButton = views.includes(VIEWS.LIST);
    const renderCardButton = views.includes(VIEWS.CARD);
    const renderCalendarButton = views.includes(VIEWS.CALENDAR);

    const MobileView = () => (
        <Grid container spacing={1}>
            {renderListButton && (
                <Grid item>
                    <ListButton listViewID={listViewID} setTableView={setTableView} />
                </Grid>
            )}
            {renderCardButton && (
                <Grid item>
                    <CardButton listViewID={listViewID} setTableView={setTableView} />
                </Grid>
            )}
            {renderCalendarButton && (
                <Grid item>
                    <CalendarButton />
                </Grid>
            )}
        </Grid>
    );

    const DesktopView = () => (
        <Grid container spacing={2} sx={{ maxWidth: 'fit-content', justifyContent: 'flex-end' }}>
            <Grid item>
                <ListButton listViewID={listViewID} setTableView={setTableView} />
            </Grid>
            {renderCardButton && (
                <Grid item>
                    <CardButton listViewID={listViewID} setTableView={setTableView} />
                </Grid>
            )}
            {renderCalendarButton && (
                <Grid item>
                    <CalendarButton />
                </Grid>
            )}
        </Grid>
    );

    return mobile ? <MobileView /> : <DesktopView />;
}
