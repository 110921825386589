// @ts-check

import { createContext } from 'react';

export function reducer(state, action) {
    switch (action.type) {
        case 'SET_VIEW':
            return { ...state, view: action.payload };
        case 'CLOSE_VIEW':
            return { ...state, view: '', openedView: 'all', selectedNotification: [] };
        case 'SET_DETAIL_VIEW':
            return { ...state, detailView: action.payload };
        case 'SET_SELECTED_NOTIFICATION':
            return { ...state, selectedNotification: action.payload };
        case 'CLEAR_SELECTED_NOTIFICATION':
            return { ...state, selectedNotification: [] };
        case 'SET_ANCHOR_EL':
            return { ...state, anchorEl: action.payload };
        case 'SET_SEARCH_QUERY':
            return { ...state, searchQuery: action.payload };
        case 'PIN_NOTIFICATION':
            return { ...state, pinnedNotification: action.payload, view: 'pinned' };
        case 'CLEAR_PINNED_NOTIFICATION':
            return { ...state, pinnedNotification: [] };
        default:
            throw new Error('Invalid action type');
    }
}

/**
 * The initial state for the notifications context.
 * @type {NotificationsState}
 */
export const initialState = {
    view: '',
    detailView: 'all',
    anchorEl: null,
    searchQuery: '',
    selectedNotification: {},
    pinnedNotification: {}
};

export const NotificationsContext = createContext({
    state: initialState,
    dispatch: ({ type = '', payload = {} }) => {}
});
