import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    Container,
    containerClasses,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer as MuiDrawer,
    Icon,
    Paper,
    paperClasses,
    Typography,
    typographyClasses,
    useMediaQuery
} from '@mui/material';
import { Box, styled } from '@mui/system';
import * as actions from 'ROOT/apps/manager/src/services/ui/actions';

import isFunction from 'Lib/utilities/isFunction';

import PinnedContainer from './PinnedContainer';

export const DRAWER_CONTENT_TYPES = {
    FILTERS: 1,
    NOTIFICATIONS: 2,
    SAVED_SEARCH: 3,
    NOTES: 4,
    ALERTS: 5,
    TIMELINE: 6,
    LETTERS: 7,
    WATCHERS: 8,
    VENDOR_RATINGS: 9
};

const Header = ({ children, drawerContentTypeID, pinnable }) => {
    const dispatch = useDispatch();
    const showPin = useMediaQuery(theme => theme.breakpoints.up('xl')) && pinnable;
    const { isDrawerOpen, pinnedContentTypeID } = useSelector(state => state.ui);

    const pinned = !isDrawerOpen && pinnedContentTypeID;

    const closePin = () => {
        dispatch(actions.closePinnedDrawer());
    };

    const openPin = () => {
        dispatch(actions.pinDrawer(drawerContentTypeID));
    };

    return children ? (
        children
    ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {pinned ? (
                <HeaderButton aria-label="unpin" onClick={closePin} endIcon={<Icon>push_pin</Icon>}>
                    Unpin
                </HeaderButton>
            ) : (
                <>
                    {showPin && (
                        <HeaderButton aria-label="pin" onClick={openPin} endIcon={<Icon>push_pin</Icon>}>
                            Pin
                        </HeaderButton>
                    )}
                    <HeaderButton
                        aria-label="close"
                        onClick={() => {
                            dispatch(actions.toggleDrawer());
                        }}
                        endIcon={<Icon>close</Icon>}
                    >
                        Close
                    </HeaderButton>
                </>
            )}
        </Box>
    );
};

const Title = ({ title, children, titleIcon }) => {
    return children ? (
        children
    ) : titleIcon ? (
        <Container sx={{ display: 'flex', alignItems: 'center', paddingBottom: '1rem' }}>
            <Icon sx={{ mr: 4 }}>{titleIcon}</Icon>
            <Typography variant="h2">{title}</Typography>
        </Container>
    ) : (
        <Typography variant="h2">{title}</Typography>
    );
};

const Footer = ({ ButtonProps = {}, children, disabled, loading, onCancel, onClear, onClose, onSubmit }) => {
    const { submitText = 'Update' } = ButtonProps;

    return children ? (
        children
    ) : (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box>
                {onClear && (
                    <Button variant="tertiary" aria-label="clear" onClick={onClear}>
                        Clear
                    </Button>
                )}
            </Box>
            <Box>
                {onSubmit ? (
                    <>
                        <Button variant="secondary" aria-label="cancel" onClick={onCancel} sx={{ mr: 4 }}>
                            Cancel
                        </Button>
                        <LoadingButton variant="primary" aria-label={submitText ?? 'update'} type="submit" onClick={onSubmit} disabled={disabled} loading={loading}>
                            {submitText}
                        </LoadingButton>
                    </>
                ) : (
                    <Button variant="secondary" aria-label="cancel" onClick={onClose}>
                        Close
                    </Button>
                )}
            </Box>
        </Box>
    );
};

const SideDrawerHeader = ({ contentHeader, drawerContentTypeID, header, handleClose, pinnable, title, size }) => {
    if (size == 'lg') {
        return (
            <DialogTitle component="div" sx={{ display: 'block', py: 3, px: 0 }}>
                <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', pt: 3, px: 4 }}>
                    <Title title={title}>{isFunction(title) && title()}</Title>
                    <Header onClose={handleClose} drawerContentTypeID={drawerContentTypeID} pinnable={pinnable}>
                        {isFunction(header) ? header() : header}
                    </Header>
                </Paper>
                {contentHeader ? <Box sx={{ bgcolor: 'common.white' }}>{contentHeader}</Box> : null}
            </DialogTitle>
        );
    }

    return (
        <DialogTitle component="div" sx={{ display: 'block', py: 3, px: 4 }}>
            <Header onClose={handleClose} drawerContentTypeID={drawerContentTypeID} pinnable={pinnable}>
                {isFunction(header) ? header() : header}
            </Header>
            <Title title={title}>{isFunction(title) && title()}</Title>
            {contentHeader ? <Box sx={{ bgcolor: 'common.white' }}>{contentHeader}</Box> : null}
        </DialogTitle>
    );
};

export default function SideDrawer(props) {
    const dispatch = useDispatch();
    const { drawerContentTypeID, isDrawerOpen } = useSelector(state => state.ui);

    const openPopover = Boolean(drawerContentTypeID == props.drawerContentTypeID) && isDrawerOpen;
    const id = openPopover ? 'side-drawer' : undefined;

    const handleClose = () => {
        dispatch(actions.toggleDrawer());
    };

    // contentRef is currently only used for the Notes and Timeline bottom scroll event, which invokes a supplied callback that fetches the next page of data.
    const renderContent = () => (
        <Box sx={{ height: '100%' }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <SideDrawerHeader drawerContentTypeID={props.drawerContentTypeID} handleClose={handleClose} {...props} />
                <DialogContent
                    ref={props.contentRef}
                    sx={{ pt: 0, px: 4, pb: 2, overflowY: 'auto', overflowX: 'hidden', [`& .${containerClasses.root}`]: { maxWidth: 'inherit' } }}
                >
                    {props.children}
                </DialogContent>
                {!props.hideFooter ? (
                    <DialogActions elevation={0} sx={{ p: 4, borderTop: theme => `1px solid ${theme.palette.common.accentgray}` }}>
                        <Footer
                            onClose={handleClose}
                            onCancel={props.onCancel}
                            onClear={props.onClear}
                            onSubmit={props.onSubmit}
                            loading={props.loading}
                            disabled={props.isSubmitDisabled}
                            ButtonProps={props.ButtonProps}
                        >
                            {isFunction(props.footer) ? props.footer() : props.footer}
                        </Footer>
                    </DialogActions>
                ) : null}
            </Box>
        </Box>
    );

    return (
        <>
            <Drawer anchor="right" id={id} onClose={handleClose} open={openPopover} sx={props.sx} size={props.size}>
                {renderContent()}
            </Drawer>
            <PinnedContainer id={props.drawerContentTypeID}>{renderContent()}</PinnedContainer>
        </>
    );
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'size' })(({ theme, size }) => ({
    [`& > .${paperClasses.root}`]: {
        overflowX: 'hidden',
        borderRadius: '20px 0px 0px 20px',
        boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.25)',
        width: size === 'lg' ? '55%' : 396,
        [theme.breakpoints.down('mobile')]: {
            width: '100%',
            borderRadius: 0
        },
        [`& .${typographyClasses.root}`]: {
            fontSize: theme.typography.pxToRem(14)
        },
        [`& .${typographyClasses.h2}`]: {
            fontSize: theme.typography.pxToRem(24),
            lineHeight: '32px'
        },
        '& input::placeholder': {
            fontSize: theme.typography.pxToRem(14)
        }
    }
}));

const HeaderButton = styled(Button)(({ theme }) => ({
    borderRadius: 8,
    color: theme.palette.success.main,
    textTransform: 'capitalize',
    fontWeight: 'regular',
    '& .material-symbols-rounded': { fontSize: '24px !important' }
}));
