import { Component } from 'react';

import Field from 'Lib/components/form/Field';
import DayDueSelect from './DayDueSelect';

class DayDueField extends Component {
    handleChange = (option, form, field) => {
        if (this.props.onChange) {
            this.props.onChange(option, form, field);
        }

        if (!option) {
            form.setFieldValue(field.name, null);
            return;
        }
        form.setFieldValue(field.name, option.value);
    };

    handleBlur = (form, field) => {
        if (this.props.onBlur) {
            this.props.onBlur(form, field);
        }
        form.setFieldTouched(field.name);
    };

    renderInput(props) {
        const { form, field } = props;

        return (
            <DayDueSelect
                {...this.props}
                {...props}
                value={field.value}
                onChange={option => this.handleChange(option, form, field)}
                onBlur={() => this.handleBlur(form, field)}
            />
        );
    }

    render() {
        return (
            <Field
                {...this.props}
                render={props => this.renderInput(props)}
            />
        );
    }
}

export default DayDueField;
