import { Dialog, DialogActions, DialogContent, DialogTitle as MuiDialogTitle, Icon, IconButton, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

import isFunction from 'Lib/utilities/isFunction';
import { screenSize } from 'Lib/utilities/screenSize';

export const DialogTitle = props => {
    const { icon, toggle, title, ...rest } = props;

    if (isFunction(title)) return title();

    return (
        <MuiDialogTitle component="div" {...rest}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        span: { mr: 2, fontSize: '32px' }
                    }}
                >
                    {icon && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Icon>{icon}</Icon>
                        </Box>
                    )}
                    {title}
                </Box>
                {toggle && (
                    <IconButton
                        aria-label="close"
                        onClick={toggle}
                        sx={{
                            color: 'success.main',
                            '& .material-symbols-rounded': { fontSize: '24px !important' }
                        }}
                    >
                        close
                    </IconButton>
                )}
            </Box>
        </MuiDialogTitle>
    );
};

DialogTitle.propTypes = {
    children: PropTypes.node
};

export default function Index({ children, contentHeader, actions, breakpoint, fullscreen, open, icon, title, toggle, sx, contentSx, contentRef, fullWidth, actionsSx, ...rest }) {
    const mediaQuery = useMediaQuery(theme => (breakpoint ? breakpoint : theme.breakpoints.down('md')));
    const useFullscreen = fullscreen || mediaQuery;

    const { mobile: isMobileScreenSize, tablet: isTabletScreenSize } = screenSize();
    const mobile = isTabletScreenSize || isMobileScreenSize;

    return (
        <Dialog
            aria-labelledby="dialog-title"
            onClose={toggle || (() => {})}
            fullWidth={fullWidth ? fullWidth : fullscreen ? fullscreen : useFullscreen}
            fullScreen={fullscreen ? fullscreen : useFullscreen}
            open={open}
            sx={sx}
            {...rest}
        >
            <DialogTitle id="dialog-title" icon={icon} title={title} toggle={toggle} />
            {contentHeader ? <Box sx={{ px: 6 }}>{contentHeader}</Box> : null}
            <DialogContent ref={contentRef} sx={contentSx}>
                {children}
            </DialogContent>
            {actions && <DialogActions sx={mobile ? { borderTop: '1px solid rgba(143, 143, 143, 0.4)', ...actionsSx } : undefined}>{actions()}</DialogActions>}
        </Dialog>
    );
}
