import Enumeration from 'Lib/utilities/Enumeration';

const SearchIndexTypes = new Enumeration([
    { id: 1, key: 'Unit', name: 'Unit', code: 'unit', url: '/units' },
    { id: 2, key: 'Owner', name: 'Owner Contact', code: 'owner', url: '/contacts/owners' },
    { id: 3, key: 'Tenant', name: 'Tenant Contact', code: 'tenant', url: '/contacts/tenants' },
    { id: 4, key: 'Vendor', name: 'Vendor Contact', code: 'vendor', url: '/contacts/vendors' },
    { id: 5, key: 'Property', name: 'Property', code: 'property', url: '/properties' },
    { id: 6, key: 'Action', name: 'Action', code: 'action' },
    { id: 7, key: 'Portfolio', name: 'Portfolio', code: 'portfolio', url: '/portfolios' },
    { id: 8, key: 'Report', name: 'Report', code: 'report' },
    { id: 9, key: 'Association', name: 'Association', code: 'association', url: '/associations' }
]);

export default SearchIndexTypes;
