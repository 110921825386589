import Loader from 'Lib/utilities/Loader';

const routes = [
    {
        path: '/leases/:id([0-9]+)',
        component: Loader(() => import('~/containers/leases/View'))
    },
    {
        path: '/leases/:id([0-9]+)/move-out',
        component: Loader(() => import('~/containers/leases/MoveOut'))
    },
    {
        path: '/leases',
        component: Loader(() => import('~/containers/leases/List'))
    },
    {
        path: '/leases/move-in',
        component: Loader(() => import('~/containers/leases/MoveIn'))
    },
    {
        path: '/leases/move-ins/:id([0-9]+)',
        component: Loader(() => import('~/containers/leases/MoveIn'))
    },
    {
        path: '/leases/insurance-requests/:id([0-9]+)',
        component: Loader(() => import('~/containers/leases/InsuranceRequest'))
    },
    {
        path: '/leases/mass-message',
        component: Loader(() => import('~/containers/leases/Message'))
    },
    {
        path: '/leases/mass-letters',
        component: Loader(() => import('~/containers/leases/Letters'))
    },
    {
        path: '/settings/lease/lease-statuses',
        component: Loader(() => import('~/containers/settings/lease/leaseStatuses/controllers/LeaseStatusView'))
    },
    {
        path: '/leases/rent-increase',
        component: Loader(() => import('~/containers/leases/RentIncrease'))
    },
    {
        path: '/leases/rent-increase-batches',
        component: Loader(() => import('~/containers/leases/RentIncreaseBatches'))
    },
    {
        path: '/leases/rent-increase-batches/:id([0-9]+)',
        component: Loader(() => import('~/containers/leases/RentIncreaseBatch'))
    }
];

export default routes;
