// @ts-check
import PropertyTypes from 'Lib/constants/property/propertyTypes';
import stripHtml from 'Lib/utilities/stripHtml';

import Card, { Body, DeepLink, ObjectLink, Subtitle } from '../CardComponents';

/** @param {{result: PropertyResult}} param0 */
export default function Property({ result }) {
    const cardType = 'Property';

    const { data, xray, highlights } = result;

    const {
        'address.full.shingled': highlightedAddressShingled,
        'address.streetNumber': highlightedStreetNumber,
        'address.streetName': highlightedStreetName,
        'address.address2': highlightedAddress2,
        'address.city': highlightedCity,
        'address.state': highlightedState,
        'address.postalCode': highlightedPostalCode,
        'lease.tenants.name': highlightedNames,
        'lease.tenants.name.shingled': highlightedNamesShingled
    } = highlights;

    const highlightedAddress =
        highlightedAddressShingled?.[0][0] ??
        [
            highlightedStreetNumber?.[0][0] || data.address.streetNumber,
            highlightedStreetName?.[0][0] || data.address.streetName,
            highlightedAddress2?.[0][0] || data.address.address2,
            highlightedCity?.[0][0] || data.address.city,
            highlightedState?.[0][0] || data.address.state,
            highlightedPostalCode?.[0][0] || data.address.postalCode
        ]
            .filter(Boolean)
            .join(' ');

    const tenantNames = data.lease.tenants.map(tenant => tenant.name).join(', ');
    const highlightedPortfolioName = highlights['portfolio.name']?.[0][0] || highlights['portfolio.name.shingled']?.[0][0];

    const strippedHighlightedNames = highlightedNames?.[0].map(highlightedName => stripHtml(highlightedName)) ?? [];
    const strippedHighlightedNamesShingled = highlightedNamesShingled?.[0].map(highlightedNameShingled => stripHtml(highlightedNameShingled)) ?? [];

    const highlightedTenantNames = data.lease.tenants
        .map(a => a.name)
        .filter(name => !strippedHighlightedNames.includes(name) && !strippedHighlightedNamesShingled.includes(name))
        .concat(highlightedNames?.[0], highlightedNamesShingled?.[0])
        .filter(Boolean)
        .join(', ');

    return (
        <Card.Parent path={`/properties/${data.propertyID}`} cardType={cardType}>
            <Card.Status status={data.isActive ? 'Active' : 'Inactive'} />
            <Card.Content>
                <Card.Header title={data.address.full} highlight={highlightedAddress} cardType={cardType} subtype={PropertyTypes.getName(data.propertyTypeID)} />

                <Body>
                    <Subtitle.Parent>
                        <Subtitle.Link path={`/portfolios/${data.portfolio.portfolioID}`} value={data.portfolio.name} highlight={highlightedPortfolioName} label="Portfolio" />
                    </Subtitle.Parent>

                    {data.isMultiUnit ? (
                        <DeepLink.Parent>
                            <DeepLink.Item path={`/properties/${data.propertyID}?tab=units`} value={`${data.unitCount} Units`} />
                            <DeepLink.Xrays xrays={xray} highlights={highlights} />
                        </DeepLink.Parent>
                    ) : (
                        <>
                            <DeepLink.Parent>
                                <DeepLink.Xrays xrays={xray} highlights={highlights} />
                            </DeepLink.Parent>
                            <ObjectLink.Parent>
                                <ObjectLink.Title value={data.lease.leaseID ? 'Lease' : 'Vacant'} />
                                <ObjectLink.Item path={`/leases/${data.lease.leaseID}`} value={tenantNames} highlight={highlightedTenantNames} />
                            </ObjectLink.Parent>
                        </>
                    )}
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
