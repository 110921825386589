// @ts-check

/* 
    ! DO NOT remove the eslint-disable line below, it will auto-sort and import the package after. For some reason, importing in index.js does
    ! not behave the same as importing it before in this file.
    ! 'regenerator-runtime' needs to be imported before 'react-speech-recognition'.
    ! https://github.com/JamesBrill/react-speech-recognition/issues/196#issuecomment-1797719100
    ! https://www.npmjs.com/package/react-speech-recognition#regeneratorruntime-is-not-defined
*/
/* eslint-disable simple-import-sort/imports */
import 'regenerator-runtime';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { Box, Icon, IconButton, InputAdornment, TextField } from '@mui/material';

import { KEYCODES, MODIFIERS } from 'Lib/constants/keyboard';
import useKeyboardShortcut from 'Lib/hooks/useKeyboardShortcut';
import { ModalTrigger } from 'Lib/muiComponents/modal';
import { useModalTrigger } from 'Lib/muiComponents/modal/ModalTrigger';
import { screenSize } from 'Lib/utilities/screenSize';
import userDevice from 'Lib/utilities/userDevice';

export default function Trigger() {
    const { os } = userDevice();
    const { smallMobile: isSmallMobileScreenSize, mobile: isMobileScreenSize, tablet: isTabletScreenSize } = screenSize();
    const mobileSize = isTabletScreenSize || isMobileScreenSize;
    const modal = useModalTrigger();

    useKeyboardShortcut(MODIFIERS.META, KEYCODES.K, e => {
        modal.setValue('Global Search', null, 'push');

        e.stopPropagation();
        e.preventDefault();
    });

    const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const startSpeech = e => {
        e.stopPropagation();
        SpeechRecognition.startListening();
    };

    const stopSpeech = e => {
        e.stopPropagation();
        SpeechRecognition.stopListening();
    };

    return (
        <ModalTrigger
            name="Global Search"
            sx={theme => ({
                width: '100%',
                '& .MuiInputBase-root': {
                    backgroundColor: mobileSize ? 'transparent' : `${theme.palette.common.lightblue}`,
                    border: mobileSize ? 'none' : `1px solid ${theme.palette.common.lightgray}`,
                    padding: '1rem',
                    borderRadius: '5px',
                    cursor: 'text !important',
                    '&:hover': { borderColor: theme.palette.success.main }
                },
                '& .MuiInputAdornment-root, & input::placeholder, & .MuiInputAdornment-positionEnd .MuiIcon-root': { color: 'common.black', fontSize: '0.9rem', opacity: '1' },
                '& input': { pointerEvents: 'none' },
                '& input::placeholder': { opacity: isSmallMobileScreenSize && '0' }
            })}
        >
            <TextField
                placeholder="Search"
                variant="standard"
                fullWidth
                disabled
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon>search</Icon>
                        </InputAdornment>
                    ),
                    endAdornment: !mobileSize && (
                        <InputAdornment position="end">
                            {os === 'Mac' ? <Icon sx={{ mr: 1 }}>keyboard_command_key</Icon> : <Box>Ctrl +&nbsp;</Box>}
                            <Box>K</Box>
                        </InputAdornment>
                    ),
                    disableUnderline: true
                }}
            />
        </ModalTrigger>
    );
}
