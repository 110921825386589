import * as actionTypes from './actionTypes';

export const initialState = {
    fetchLogs: false
}

export const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LOGS:
            return {
                fetchLogs: action.data
            }
        default: 
            return state;
    }
};

export default reducer;