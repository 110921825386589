// @ts-check

import  { useContext } from 'react';

import Dialog from 'Lib/muiComponents/dialog/Dialog';
import { screenSize } from 'Lib/utilities/screenSize';

import SideBarTitle from '../components/SideBarTitle';
import { NotificationsContext } from '../context';

export default function Modal({ children }) {
    const { state, dispatch } = useContext(NotificationsContext);

    const { mobile: isMobileScreenSize, tablet: isTabletScreenSize } = screenSize();
    const isMobile = isTabletScreenSize || isMobileScreenSize;

    return (
        <Dialog
            open={state.view === 'modal'}
            title={<SideBarTitle />}
            toggle={() => {
                dispatch({ type: 'CLOSE_VIEW' });
            }}
            fullscreen={isMobile}
        >
            {children}
        </Dialog>
    );
}
