// @ts-check

import { useContext } from 'react';
import { ToggleButtonGroup } from '@mui/material';

import { NotificationsContext } from '../context';
import { StyledToggleButton } from '../StyledComponents';

export default function ViewSwitcher() {
    const { state, dispatch } = useContext(NotificationsContext);

    return (
        <ToggleButtonGroup
            sx={{ height: '40px', mb: '1rem' }}
            fullWidth
            color="primary"
            value={state.detailView}
            exclusive
            onChange={(event, value) => dispatch({ type: 'SET_DETAIL_VIEW', payload: value })}
        >
            <StyledToggleButton sx={{ borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }} value={'all'}>
                All
            </StyledToggleButton>
            <StyledToggleButton value={'unread'}>Unread</StyledToggleButton>
            <StyledToggleButton sx={{ borderTopRightRadius: '20px', borderBottomRightRadius: '20px' }} value={'read'}>
                Read
            </StyledToggleButton>
        </ToggleButtonGroup>
    );
}
