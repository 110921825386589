const XraySortOrder = [
    'code',
    'code.shingled',

    'unit.code',

    'additionalemails.email.domain',
    'additionalemails.email.user',
    'additionalemails.email',

    'additionalPhones.phone',

    'payeeName',
    'payeeName.shingled',

    'ownerPortalNameOverride',
    'ownerPortalNameOverride.shingled',

    'owners.payeeName',
    'owners.payeeName.shingled',

    'owners.email.domain',
    'owners.email.user',
    'owners.email',

    'owners.additionalEmails.email.domain',
    'owners.additionalEmails.email.user',
    'owners.additionalEmails.email',

    'owners.additionalPhones.phone',
    'owners.additionalPhones',

    'tenants.email.domain',
    'tenants.email.user',
    'tenants.email',

    'tenants.additionalEmails.email.domain',
    'tenants.additionalEmails.email.user',
    'tenants.additionalEmails.email',

    'tenants.additionalPhones',
    'tenants.additionalPhones.phone',

    'occupants.name',
    'occupants.name.shingled',
    'occupants.name.keyword',

    'lease.occupants.name',
    'lease.occupants.name.shingled',
    'lease.occupants.name.keyword',

    'pastTenants.name',
    'pastTenants.name.shingled',
    'pastTenants.name.keyword',

    'pastTenants.email.domain',
    'pastTenants.email.user',
    'pastTenants.email',

    'pastTenants.phone',

    'emergencyContacts.email.domain',
    'emergencyContacts.email.user',
    'emergencyContacts.email',

    'emergencyContacts.name',
    'emergencyContacts.shingled',
    'emergencyContacts.name.keyword',

    'emergencyContacts.phone',

    'address.full',
    'address.full.shingled',
    'address.streetName',
    'address.streetNumber',
    'address.address',
    'address.address2',
    'address.city',
    'address.postalCode',
    'address.state',

    'amountWithDiscount.keyword',

    'applicants.email.domain',
    'applicants.email.user',
    'applicants.email',

    'applicationNumber',

    'associations.name',
    'associations.name.shingled',
    'associations.name.keyword',

    'features',
    'features.shingled',
    'features.keyword',

    'liabilityInsurancePolicyNumber',
    'liabilityInsurancePolicyNumber.alphanumeric',

    'lineItems.keyword',

    'memo',
    'memo.alphanumeric',

    'number',

    'portfolio.owners.name',
    'portfolio.owners.name.shingled',
    'portfolio.owners.name.keyword',

    'portfolioProperties.address.full',
    'portfolioProperties.address.full.shingled',
    'portfolioProperties.address.streetname',
    'portfolioProperties.address.streetnumber',
    'portfolioProperties.address.address',
    'portfolioProperties.address.address2',
    'portfolioProperties.address.city',
    'portfolioProperties.address.postalcode',
    'portfolioProperties.address.state',

    'portfolioProperties.code',
    'portfolioProperties.code.shingled',

    'rentersInsurancePolicyNumber',
    'rentersInsurancePolicyNumber.alphanumeric',

    'taxPayerName',
    'taxPayerName.shingled',

    'vehicles.description',
    'vehicles.description.shingled',

    'vehicles.plateNumber',
    'vehicles.plateNumber.alphanumeric',
    'vehicles.plateNumber.keyword',

    'vendorTrades',
    'vendorTrades.shingled',
    'vendorTrades.keyword',

    'websiteUrl',

    'workersCompInsurancePolicyNumber',
    'workersCompInsurancePolicyNumber.alphanumeric',

    'tags',
    'tags.shingled',
    'tags.keyword'
];

export default XraySortOrder;
