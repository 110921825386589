import { Component } from 'react';
import { Timeline, Tween } from 'react-gsap';

class AnimatedLogo extends Component {
    render() {
        return (
            <div style={{ position: 'fixed', zIndex: 9999, height: '100%', width: '100%', top: 0, left: 0, background: '#fff', display: 'flex', textAlign: 'center', repeat: -1 }}>
                <div style={{ margin: 'auto' }}>
                    <Timeline
                        target={
                            <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 564.73 690.05" style={{ height: '30%', width: '100%' }}>
                                <title>rentvine icon</title>
                                <defs>
                                    <radialGradient
                                        id="radial-gradient"
                                        cx="306.7"
                                        cy="644.07"
                                        r="182.98"
                                        gradientTransform="translate(-53.25 558.49) scale(1.09 0.13)"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop offset="0" stopOpacity="0.4" />
                                        <stop offset="0.2" stopColor="#595959" stopOpacity="0.37" />
                                        <stop offset="0.4" stopColor="#a1a1a1" stopOpacity="0.34" />
                                        <stop offset="0.61" stopColor="#d5d5d5" stopOpacity="0.32" />
                                        <stop offset="0.81" stopColor="#f5f5f5" stopOpacity="0.3" />
                                        <stop offset="1" stopColor="#fff" stopOpacity="0.3" />
                                    </radialGradient>
                                </defs>
                                <g id="logo-main">
                                    <g transform="translate(564.73, 0) scale(-1, 1)">
                                        <path
                                            id="upper-white"
                                            d="M472.9,392.62a80.85,80.85,0,0,1-73.74,80.7q-3.53.31-7.14.32H209.2A118.65,118.65,0,0,1,90.56,355V91.62h265a117,117,0,0,1,117,117Z"
                                            fill="#fff"
                                        />
                                        <Tween
                                            from={{ yPercent: 100, autoAlpha: 1, scaleY: 1 }}
                                            to={{ yPercent: 0, scaleY: 1.15, repeat: 0, yoyo: false, delay: 0.75 }}
                                            duration={0.5}
                                        >
                                            <path id="pillar-1" d="M282.36,140.9l-.59,177.45a997,997,0,0,1-84.33-87.17V159.63a18.74,18.74,0,0,1,18.73-18.73Z" fill="#4ab248" />
                                        </Tween>
                                        <Tween
                                            from={{ yPercent: 100, autoAlpha: 1, scaleY: 1 }}
                                            to={{ yPercent: 0, scaleY: 1.15, repeat: 0, yoyo: false, delay: 0.95 }}
                                            duration={0.5}
                                        >
                                            <path
                                                id="pillar-2"
                                                d="M421.19,240.42v176C392,402,363.86,384.16,337.1,364.18V212.63h56.3A27.79,27.79,0,0,1,421.19,240.42Z"
                                                fill="#4ab248"
                                            />
                                        </Tween>
                                        <path
                                            id="lower-white"
                                            d="M461.51,434.16a80.82,80.82,0,0,1-62.35,39.16q-3.53.31-7.14.32H209.2A118.65,118.65,0,0,1,90.56,355V91.62h2.12A1338.53,1338.53,0,0,0,197.44,231.44a997,997,0,0,0,84.33,87.17c17.68,16.09,36.16,31.51,55.33,45.83,26.76,20,54.88,37.81,84.09,52.26a418.22,418.22,0,0,0,40.12,17.39Z"
                                            fill="#fff"
                                        />
                                        <path
                                            id="green-border"
                                            d="M354,24.59H24.33V352.36c0,104,84.3,188.31,188.3,188.31H389.7A150.7,150.7,0,0,0,540.4,390V211C540.4,108,457,24.59,354,24.59ZM90.56,91.36h265a117,117,0,0,1,117,117l.33,184c0,.41,0,.81,0,1.22s0,.73,0,1.1a80.44,80.44,0,0,1-11.37,39.23,2.25,2.25,0,0,1-.12.21,80.84,80.84,0,0,1-62.22,38.94q-3.53.31-7.14.32H209.21A118.65,118.65,0,0,1,90.57,354.73V91.36Z"
                                            fill="#4ab248"
                                        />
                                        <path
                                            id="white-border"
                                            d="M564.73,0H0V690.05H564.73ZM540.4,390a150.7,150.7,0,0,1-150.7,150.7H212.63c-104,0-188.3-84.31-188.3-188.31V24.59H354C457,24.59,540.4,108,540.4,211Z"
                                            fill="#fff"
                                        />
                                        <Tween from={{ yPercent: 100, xPercent: 100, autoAlpha: 0 }} to={{ yPercent: 0, xPercent: 0, autoAlpha: 1, delay: 0.2 }} duration={1}>
                                            <path
                                                id="slash"
                                                d="M444.36,496.43s-8.39-3-23.17-10.8h0c-6.25-3.29-13.64-7.44-22-12.57a551.66,551.66,0,0,1-61.94-44.55s0,0,0,0l-.06-.06a722.94,722.94,0,0,1-55.53-50.93v0a912.35,912.35,0,0,1-84.08-99l0,0h0c-35.35-47.87-71.67-105.67-106.87-175.23q-16.21-32-32-67.47C59.21,37,71.26,58.55,92.68,91.36A1338.53,1338.53,0,0,0,197.44,231.18a997,997,0,0,0,84.33,87.17c17.68,16.09,36.16,31.51,55.33,45.83,26.76,20,54.88,37.81,84.09,52.26a418.22,418.22,0,0,0,40.12,17.39c24.16,9,15,14,16.87,27.09l.22,18.82C480.07,491.52,444.36,496.43,444.36,496.43Z"
                                                fill="#4ab248"
                                                stroke="#4ab148"
                                                strokeMiterlimit="10"
                                                strokeWidth="3"
                                            />
                                        </Tween>
                                    </g>
                                </g>
                                <g>
                                    <Timeline target={<ellipse id="shadow" cx="282.37" cy="642.29" rx="282.37" ry="47.75" opacity="0.62" fill="url(#radial-gradient)" />}>
                                        <Tween from={{ autoAlpha: 0 }} to={{ autoAlpha: 1 }}></Tween>
                                        <Tween from={{ scale: 1, delay: 1.5 }} to={{ scale: 1.5, transformOrigin: 'center', yoyo: true }} repeat={-1} duration={1} />
                                    </Timeline>
                                </g>
                            </svg>
                        }
                    >
                        <Tween from={{ scale: 0, rotationY: 360, autoAlpha: 0 }} to={{ scale: 1, rotationY: 0, autoAlpha: 1 }} duration={1.2} />
                        <Tween from={{ scale: 1, delay: 1.5 }} to={{ scale: 1.5, transformOrigin: 'center', yoyo: true }} repeat={-1} duration={1} />
                    </Timeline>
                </div>
            </div>
        );
    }
}

export default AnimatedLogo;
