// @ts-check
import { formatPhoneNumber } from 'react-phone-number-input';

import Card, { Body, DeepLink, ObjectLink } from '../CardComponents';

/** @param {{result: VendorResult}} param0 */
export default function Vendor({ result }) {
    const cardType = 'Vendor';

    const { data, xray, highlights } = result;

    const highlightedName = highlights.name?.[0][0] || highlights['name.shingled']?.[0][0];
    const highlightedEmail = highlights.email?.[0]?.[0];
    const highlightedPhone = highlights.phone?.[0]?.[0];

    return (
        <Card.Parent path={`/maintenance/vendors/${data.contactID}`} cardType={cardType}>
            <Card.Status status={data.isActive ? 'Active' : 'Inactive'} />
            <Card.Content>
                <Card.Header title={data.name} highlight={highlightedName} cardType={cardType} subtype={data.vendorType} />

                <Body>
                    <DeepLink.Parent>
                        <DeepLink.Item path={`/maintenance/vendors/${data.contactID}?tab=work-orders`} value={'Work Orders'} />
                        <DeepLink.Xrays xrays={xray} highlights={highlights} />
                    </DeepLink.Parent>

                    {(data.email || data.phone) && (
                        <ObjectLink.Parent columns={2}>
                            <ObjectLink.Title value="Email" />
                            <ObjectLink.Title value="Phone" />

                            <ObjectLink.Item
                                path={`/maintenance/vendors/${data.contactID}?tab=messages&composeEmail=true`}
                                value={data.email ? data.email : '--'}
                                highlight={highlightedEmail}
                            />
                            <ObjectLink.Item value={data.phone ? `${formatPhoneNumber(data.phone)}` : '--'} highlight={highlightedPhone} forceHighlight />
                        </ObjectLink.Parent>
                    )}
                </Body>
            </Card.Content>
        </Card.Parent>
    );
}
