import { LOGIN } from 'Lib/constants/app/actionTypes';

const localStorage = () => next => action => {
    if (action.type === LOGIN && !action.error) {
        window.localStorage.setItem('jwt', action.payload.data);
    }

    next(action);
};

export default localStorage;
