// @ts-check

import { useContext } from 'react';
import { Icon, InputAdornment, TextField } from '@mui/material';

import { NotificationsContext } from '../context';

export default function Search() {
    const { dispatch } = useContext(NotificationsContext);

    return (
        <TextField
            id="outlined-search"
            placeholder="Search Notifications"
            sx={{ pb: 2 }}
            margin="dense"
            type="search"
            autoComplete="false"
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Icon>search</Icon>
                    </InputAdornment>
                )
            }}
            onChange={e => {
                if (e.target.value) {
                    dispatch({ type: 'SET_DETAIL_VIEW', payload: 'all' });
                }
                dispatch({ type: 'SET_SEARCH_QUERY', payload: e.target.value });
            }}
        />
    );
}
