// @ts-check
import { useHistory } from 'react-router-dom';

// Custom hook to get, set, and remove URL parameters
export default function useParam(param = '', defaultValue = '') {
    const history = useHistory();
    const query = () => new URLSearchParams(history.location.search);

    const value = query().get(param) || defaultValue;

    const setValue = (value, state, action = 'replace') => {
        const newQuery = query();

        newQuery.set(param, value);
        history[action]({
            search: newQuery.toString(),
            ...(state && { state })
        });
    };

    const removeValue = (state, action = 'replace') => {
        const newQuery = query();

        newQuery.delete(param);
        history[action]({
            search: newQuery.toString(),
            ...(state && { state })
        });
    };

    // check if param is set
    const isSet = query().has(param) && value !== defaultValue;

    return { value, setValue, removeValue, isSet };
}

// // Example usage:

// For multiple uses, create custom hooks so the parameter is fixed for each.
export function useGSFilter() {
    return useParam('_gsFilter');
}

export function useGSSearch() {
    return useParam('_gsSearch');
}

function Example() {
    const filter = useGSFilter();
    const search = useGSSearch();

    return (
        <>
            <button onClick={() => filter.setValue('all')}>Set filter</button>
            <button onClick={() => filter.removeValue()}>Remove filter</button>
            {filter.isSet && <div>{filter.value}</div>}

            <button onClick={() => search.setValue('search')}>Set search</button>
            <button onClick={() => search.removeValue()}>Remove search</button>
            <div>{search.value}</div>
        </>
    );
}
